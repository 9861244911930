import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import counterReducer from './counterReducer';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import profileReducer from './profileReducer';
import sweepStakeReducer from './sweepStakeReducer'
import tournamentReducer from './tournamentReducer'
import gameReducer from './gameReducer'
import usersReducer from './usersReducer';
import paymentReducer from './paymentReducer'

const Reducers = (history) => combineReducers({
	router : connectRouter(history),
	counter: counterReducer,
	auth: authReducer,
	profile: profileReducer,
	users: usersReducer,
	error: errorReducer,
	sweepstake: sweepStakeReducer,
	tournament: tournamentReducer,
	game: gameReducer,
	payment : paymentReducer

});
export default Reducers