import React, { Component } from "react";
import { connect } from "react-redux";

class highscoreModal extends Component {
  render() {
    return (
      <div>
        {/* POPup Highscores */}
        <div
          id="highscores"
          style={{ display: "none" }}
          className="popup-style popup-style__pt popup-style__w600"
        >
          <p><button type='button' data-fancybox-close className="fancybox-close-small fancybox-button">
         <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24"><path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path></svg>
         </button></p>
          <div className="popup-box">
            <div className="popup-top">
              <div className="popup-top__img popup-top__img--picture">
                <img
                  src={
                    this.props.highscorerProfile.imageUrl
                      ? this.props.highscorerProfile.imageUrl
                      : "assets/images/games/defaultProfile.jpg"
                  }
                  alt=""
                />
              </div>
            </div>
            <div className="popup-content">
              <h2 className="popup-nickname">
                {this.props.highscorerProfile
                  ? this.props.highscorerProfile.name
                  : "User"}
              </h2>
              <h3 className="title-results">Highscores</h3>
              <div className="box-highscores box-highscores__scroll">
                {this.props.highscores &&
                  this.props.highscores.map((element, index) => {
                    return (
                      <div key={index} className="game-result">
                        <div className="game-result__img">
                          <img src={element.gameId.icons[0].imageURL} alt="" />
                        </div>
                        <div className="game-result__info">
                          <h3>{element.gameId.name}</h3>
                          <p>{element.gameScore}</p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({});
const mapStateToProps = (state) => ({
  user: state.auth ? state.auth.user : null,
  highscores: state.game.userHighscores ? state.game.userHighscores : [],
  highscorerProfile: state.game.highscorer ? state.game.highscorer : {},
});
export default connect(mapStateToProps, mapDispatchToProps)(highscoreModal);
