// import { Modal } from 'bootstrap';
import React, { Component } from "react";
import { connect } from "react-redux";
// import Slider from "react-slick";
// Import Swiper React components
// import Swiper from 'swiper';
import $ from "jquery";
import { getHighscores } from "../../actions";
import { Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
// import {Navigation} from 'swiper
import SwiperCore, { Navigation } from "swiper";
SwiperCore.use([Navigation]);
class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.navigationPrevRef = React.createRef();
    this.navigationNextRef = React.createRef();
    this.navigation2PrevRef = React.createRef();
    this.navigation2NextRef = React.createRef();
  }
  componentDidUpdate() {
    // debugger
    if (this.props.monthlySweepPositions.length > 0) {
      // debugger
    }
  }
  componentDidMount() {
    $("#modal-play").on("click", this.getGamePathForTournament);


    $('#live-tournament').on('click', '.userHighscoresClick', (e) => { this.setThisSweepUser(e) })
    // debugger
    // const swiperInstance = new Swiper(carouselContainer, {
    //     loop: true,
    //     pagination: {
    //         el: carouselPagination,
    //         type: "custom",
    //         renderCustom: renderCarouselPaginationBullets
    //     },
    //     navigation: {
    //         nextEl: carouselNextButton,
    //         prevEl: carouselPrevButton
    //     }
    // });
  }
  setThisSweepUser = (e) => {
    // debugger
    const userId = e.target.dataset.id
    this.props.getHighscoresDispatch({ userId: userId })
    // this.setState({ userHighscore: user })
  }
  modalPrizes = (el, pos) => {
    if (el !== undefined && el !== 0 && el !== null) {
      // debugger
      if (pos == '51th-100th' || pos == '101th-200th' || pos == '201th-500th' || pos == '500th-1000th') {
        return (
          <SwiperSlide>
            <div className="slider-place__col">
              <div className="slider-place__item">
                <div className="slider-place__t" style={{ fontSize: '8px' }}>{pos}</div>
                <div className="slider-place__img">
                  <img src="images/icons/cash-icon.png" alt="" />
                </div>
                <div className="slider-place__money">
                  <span>{el}</span>
                </div>
              </div>
            </div>
          </SwiperSlide>
        );
      }
      else {
        return (
          <SwiperSlide>
            <div className="slider-place__col">
              <div className="slider-place__item">
                <div className="slider-place__t">{pos}</div>
                <div className="slider-place__img">
                  <img src="images/icons/cash-icon.png" alt="" />
                </div>
                <div className="slider-place__money">
                  <span>{el}</span>
                </div>
              </div>
            </div>
          </SwiperSlide>
        );
      }
    }
  };
  getGamePathForTournament = () => {
    const {
      props: { games },
    } = this;
    const game = games.filter((el) => el.name === this.props.state.gameName);
    // debugger

    let name = this.props.state.gameName;
    let trn_id = this.props.state.tournamentId;
    name = name.replace(/ /g, "_").toLocaleLowerCase();

    // window.location= `tournament?gamename=${name}`;
    this.props.history.push(`tournament?gamename=${name}&g_id=${game._id}&t_id=${trn_id}`);
    window.myFunct2();
  };

  // Swiper.use([Navigation]);
  render() {
    return (
      <div>
        {/* <main>
          <ul className="list-popups">
            <li>
              <a href="#" data-fancybox data-src="#live-tournament">
                live tournament
              </a>
            </li>
          </ul>
        </main> */}

        {/* <!--popup live tournament--> */}
        <div
          id="live-tournament"
          style={{ display: "none" }}
          className="popup-style popup-style__text-center popup-style__pt popup-style__w600"
        >
          <p><button type='button' data-fancybox-close className="fancybox-close-small fancybox-button">
            <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24"><path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path></svg>
          </button></p>
          <div className="popup-box">
            <div className="popup-top">
              <div className="popup-top__img popup-top__img--picture">
                <img src={this.props.tournamentPayload.gameImg} alt="" />
              </div>
            </div>
            <div className="popup-content popup-content__pr live-tournament">
              <div className="live-icon">
                <img src="images/icons/live-icon.png" alt="" />
              </div>
              <h2 className="popup-title popup-title__f45">LIVE TOURNAMENT</h2>
              <div className="popup-timer popup-timer__mb25">
                <h5>Ends in:</h5>
                <div className="timer">
                  <div className="timer-box">
                    <div className="timer-num days">
                      <span>0</span>
                      <span>0</span>
                    </div>
                    <p>Days</p>
                  </div>
                  <div className="timer-box">
                    <div className="timer-num hours">
                      <span>0</span>
                      <span>0</span>
                    </div>
                    <p>Hours</p>
                  </div>
                  <div className="timer-box">
                    <div className="timer-num minutes">
                      <span>0</span>
                      <span>0</span>
                    </div>
                    <p>Minutes</p>
                  </div>
                  <div className="timer-box">
                    <div className="seconds">
                    </div>
                  </div>
                </div>
              </div>
              <h3 className="popup-subtitle">$PLY REWARD UP TO 100th PLACE </h3>
              <Swiper
                slidesPerView={4}
                navigation={{
                  prevEl: this.navigation2PrevRef.current,
                  nextEl: this.navigation2NextRef.current,
                }}
              // modules={[Navigation]}
              >
                <div className="swiper-wrapper">
                  {this.modalPrizes(this.props.state.currentTournament && this.props.state.currentTournament.firstPrize, `1st`)}
                  {this.modalPrizes(this.props.state.currentTournament && this.props.state.currentTournament.secondPrize, "2nd")}
                  {this.modalPrizes(this.props.state.currentTournament && this.props.state.currentTournament.thirdPrize, "3rd")}
                  {this.modalPrizes(this.props.state.currentTournament && this.props.state.currentTournament.fourthPrize, "4th-10th")}
                  {this.modalPrizes(this.props.state.currentTournament && this.props.state.currentTournament.fifthPrize, "11th-20th")}
                  {this.modalPrizes(this.props.state.currentTournament && this.props.state.currentTournament.sixthPrize, "21th-50th")}
                  {this.modalPrizes(this.props.state.currentTournament && this.props.state.currentTournament.seventhPrize, "51th-100th")}
                  {this.modalPrizes(this.props.state.currentTournament && this.props.state.currentTournament.eighthPrize, "101th-200th")}
                  {this.modalPrizes(this.props.state.currentTournament && this.props.state.currentTournament.ninthPrize, "201th-500th")}
                  {this.modalPrizes(this.props.state.currentTournament && this.props.state.currentTournament.tenthPrize, "500th-1000th")}
                </div>

                <div className='disable' ref={this.navigation2PrevRef}>
                  <img
                    className="slider-arrow slider-arrow__prev"
                    src="images/icons/slider-arrow-right.png"
                    alt=""
                  />
                </div>
                <div className='enable' ref={this.navigation2NextRef}>
                  <img
                    className="slider-arrow slider-arrow__next"
                    src="images/icons/slider-arrow-right.png"
                    alt=""
                  />
                </div>
              </Swiper>
              <div>
                {this.props.state.you_scored && this.props.state.trn_modal ? (
                  <p className="tournament-scored" style={{ marginTop: '7px' }}>
                    You scored{" "}
                    <span className="green">{this.props.state.you_scored}pt</span>
                  </p>
                ) : (
                  ""
                )}
              </div>
              <Swiper
                slidesPerView={4}
                navigation={{
                  prevEl: this.navigationPrevRef.current,
                  nextEl: this.navigationNextRef.current,
                }}
                // modules={[Navigation]}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log("slide change")}
              >
                <div className="style-slider ">
                  {/* {Object.keys(this.props.you).length !== 0 ?  */}
                  <SwiperSlide>
                    <div className="slider-users__col">
                      <div className="user-item user-item__my">
                        <div className="user-item__photo">
                          <img
                            src={
                              this.props.you.imageUrl
                                ? this.props.you.imageUrl
                                : "assets/images/games/defaultProfile.jpg"
                            }
                            // className="userHighscoresClick"
                            // data-id={this.props.you.userId}                          
                            alt=""
                          />
                          <div className="user-item__label">
                            {this.props.you.pos}p
                          </div>
                        </div>
                        <h3 className="user-item__name">You</h3>
                        <div className="user-item__points">
                          {this.props.you.score ? this.props.you.score : '//'}pts
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  {/* : ''
                  } */}

                  {this.props.positions &&
                    this.props.positions.map((el, index) => {
                      // debugger
                      if (this.props.user._id !== el.userId) {
                        return (
                          <SwiperSlide>
                            <div key={index} className="slider-users__col">
                              <div className="user-item">
                                <div className="user-item__photo">
                                  <img
                                    src={
                                      el.imageUrl
                                        ? el.imageUrl
                                        : "assets/images/games/defaultProfile.jpg"
                                    }
                                    className='userHighscoresClick'
                                    data-id={el.userId}
                                    alt=""
                                  />
                                  <div className="user-item__label">
                                    {el ? el.position : ""}p
                                  </div>
                                </div>
                                <h3 className="user-item__name">
                                  {el ? el.name : ""}
                                </h3>
                                <div className="user-item__points">
                                  {el ? el.userScore : ""}pts
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      }
                    })}
                </div>
                <div className='disable' ref={this.navigationPrevRef}>
                  <img
                    className="slider-arrow slider-arrow__prev"
                    src="images/icons/slider-arrow-right.png"
                    alt=""
                  />
                </div>
                <div className='enable1' ref={this.navigationNextRef}>
                  <img
                    className="slider-arrow slider-arrow__next"
                    src="images/icons/slider-arrow-right.png"
                    alt=""
                  />
                </div>
                <br />
              </Swiper>
              {this.props.state.trn_modal ? (
                <a
                  id="modal-play2"
                  className="btn btn__blue btn__icon--r btn-play-again"
                >
                  PLAY AGAIN <i className="icon-play"></i>
                </a>
              ) : (
                <a
                  // id="modal-play"
                  className="btn btn__blue btn__icon--r btn-play"
                >
                  Play <i className="icon-play"></i>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  getHighscoresDispatch: (userId) => {
    dispatch(getHighscores(userId))
  },
})
const mapStateToProps = (state) => ({
  modalTournament: state.tournament.tournamentModal
    ? state.tournament.tournamentModal
    : {},
  sweepstake: state.sweepstake.sweepstakes
    ? state.sweepstake.sweepstakes
    : [],
  tournamentPayload: state.tournament.tournamentPayload
    ? state.tournament.tournamentPayload
    : {},
  games: state.sweepstake.games ? state.sweepstake.games : [],
  tournaments: state.sweepstake.tournaments ? state.sweepstake.tournaments : [],
  user: state.auth ? state.auth.user : null,
  auth: state.auth,
  loading: state.profile ? state.profile.loading : true,
  userPositions: state.sweepstake.tournamentUserPos
    ? state.sweepstake.tournamentUserPos
    : [],
  positions: state.tournament.userPositions
    ? state.tournament.userPositions
    : [],
  sweepPositions: state.sweepstake.sweepstakeWinners
    ? state.sweepstake.sweepstakeWinners
    : [],
  you: state.tournament.yourPosition ? state.tournament.yourPosition : {},
  highscores: state.game.userHighscores ? state.game.userHighscores : [],
  monthlySweepPositions: state.sweepstake.monthlySweepPositions
    ? state.sweepstake.monthlySweepPositions
    : [],
  monthlytournamentpositions: state.tournament.monthlytournamentpositions
    ? state.tournament.monthlytournamentpositions
    : [],
  currentUserHighscores: state.auth ? state.auth.currentUserHighscores : [],
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
