import React, { Component } from "react";
import Header from "../../common/header/header";
import Footer from "../../common/footer/footer";
import {
  getSweepStakeWinner,
  getUserSweepstakePosition,
  sweepStake,
} from "../../actions/sweepStake";
import { connect } from "react-redux";
import moment from "moment";
import { getHighscores } from "../../actions";
import { element } from "prop-types";
import $ from "jquery";
import HighscoreModal from "../modals/highscoreModal";
class Page2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playerProfile: {},
      userHighscores: {},
    };
  }

  componentDidMount() {
    // $(window).on('popstate', function(event) {
    //   window.location.href = '/winner'
    //  });
    //   function goBack() {
    //    return window.location('/')
    // }
    this.props.sweepStakeDispatch();

    const params = new URLSearchParams(window.location.search);
    const sweepId = params.get("s_id");

    const payload = {
      sweepId,
    };

    this.props.getSweepStakeWinner(payload);
    this.props.getUserSweepstakePosition(payload);
  }

  setThisUser = (e, el) => {
    // e.preventDefault()
    // alert("hello")
    // alert("hello")
    // console.log("this is the obj", el)
    this.props.getHighscoresDispatch({ userId: el.userId });
    // debugger
    this.setState({ playerProfile: el });
  };

  render() {
    const params = new URLSearchParams(window.location.search);
    const sweepId = params.get("s_id");

    const {
      props: {
        user,
        positions,
        you,
        sweepstakes,
        tournamentPayload,
        highscores,
      },
    } = this;

    const sweepstake = sweepstakes.filter((el) => el._id === sweepId);
    let sweep = "";
    sweepstake.map((el) => {
      sweep = el;
    });
    const filterYouFromPositions = (arr1, arr2) => {
      let res = [];
      if (arr1.length > 0 && arr2.length > 0) {
        res = arr1.filter((el) => {
          return !arr2.find((element) => {
            return element.userPosition === el.userPosition;
          });
        });
        return res;
      } else {
        return positions;
      }
    };
    const newPostions = filterYouFromPositions(positions, you);
    // debugger
    // console.log("new positions",newPostions)

    const {
      state: { playerProfile },
      props: { },
    } = this;

    return (
      <div>
        {/* <Header /> */}
        <HighscoreModal />
        <main className="block-table tab-wrap">
          <section className=" tab-wrap">
            <div className="table-wrap table-wrap__w830">
              <div className="table-info">
                <h2>
                  SWEEPSTAKE <span>{sweep.name}</span>
                </h2>
                <h3>
                  $PLY PRIZE POOL: <span>{sweep.totalJackpotAmount}</span>
                </h3>
                <p>
                  Sweepstake End Date:{" "}
                  {moment(sweep.finishDate).format("DD MMM 'YY")}
                </p>
              </div>
              <div className="table winner-table">
                <div className="t-head">
                  <div className="t-user">Username</div>
                  <div className="t-prize">Prize</div>
                  <div className="t-cash">$PLY Prize</div>
                </div>
                {you
                  ? 
                    you.winning !== 0 &&
                      <>
                        {you && you.userPosition ? (
                          <>
                            <div onClick={(e) => this.setThisUser(e, you)} className="t-row my-position">
                              <div className="t-user">
                                <a className="table-user">
                                  <img
                                    src={
                                      you.userImg || you.imageUrl
                                        ? you.userImg || you.imageUrl
                                        : "assets/images/games/defaultProfile.jpg"
                                    }

                                    // data-fancybox={
                                    //   "yourHighscores" + you.userPosition
                                    // }
                                    // data-src="#highscoreSweep"
                                    alt=""
                                  />
                                  You
                                </a>
                              </div>
                              <div className="t-prize" style={{display: "flex", justifyContent:"space-between"}}>
                                {you.userPosition}°
                                {you.userPosition && <span className="winner-mark">winner</span> }
                              </div>
                              <div className="t-cash">
                                <div className="my-cash">
                                  $PLY {you.winning}{" "}
                                  <img src="images/icons/cash-icon.png" alt="" />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                  : ""}
              </div>
            </div>
          </section>
          <section className="tab-wrap scroll_sweep_tournaments">
            <div className="table-wrap table-wrap__w830">
              <div className="table winner-table">
                {/* <div className="t-row my-position">
                  <div className="t-user">
                    <a className="table-user" href="#">
                      <img src="assets/images/games/defaultProfile.jpg" alt="" />
                            You
                        </a>
                  </div>
                  <div className="t-prize">16°</div>
                  <div className="t-cash">
                    <div className="my-cash">$4 <img src="images/icons/cash-icon.png" alt="" /></div>
                  </div>
                </div> */}
                {newPostions &&
                  newPostions.map((element, index) => {
                    if (element.winning !== 0) {
                      // debugger
                      if (element.userId !== user._id) {
                        return (
                          <>
                            <div key={index} className="t-row" onClick={(e) =>
                              this.setThisUser(e, element)
                            }>
                              <div className="t-user" >
                                <a className="table-user">
                                  <img
                                    src={
                                      element.userImg || element.imageUrl
                                        ? element.userImg || element.imageUrl
                                        : "assets/images/games/defaultProfile.jpg"
                                    }

                                    // data-fancybox={"highscores" + index}
                                    // data-src="#highscoreSweep"
                                    alt=""
                                  />
                                  {element.userName}
                                </a>
                              </div>
                              <div className="t-prize">
                                {element.userPosition}°
                              </div>
                              <div className="t-cash color-green">
                                $PLY {Math.round(element.userMoney || element.winning)}
                              </div>
                            </div>
                          </>
                        );
                      }
                    }
                  })}
              </div>

              {/* <!--popup highscores--> */}

              <div
                id="highscoreSweep"
                style={{ display: "none" }}
                className="popup-style popup-style__pt popup-style__w600"
              >
                <div className="popup-box">
                  <div className="popup-top">
                    <div className="popup-top__img popup-top__img--picture">
                      <img
                        src={
                          playerProfile.imageUrl
                            ? playerProfile.imageUrl
                            : "assets/images/games/defaultProfile.jpg"
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="popup-content">
                    <h2 className="popup-nickname">{playerProfile.userName}</h2>
                    <h3 className="title-results">Highscores</h3>
                    <div className="box-highscores box-highscores__scroll">
                      {highscores &&
                        highscores.map((element, index) => {
                          return (
                            <div key={index} className="game-result">
                              <div className="game-result__img">
                                <img
                                  src={element.gameId.icons[0].imageURL}
                                  alt=""
                                />
                              </div>
                              <div className="game-result__info">
                                <h3>{element.gameId.name}</h3>
                                <p>{element.gameScore}</p>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* <Footer /> */}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getSweepStakeWinner: (payload) => {
    dispatch(getSweepStakeWinner(payload));
  },
  getUserSweepstakePosition: (payload) => {
    dispatch(getUserSweepstakePosition(payload));
  },
  sweepStakeDispatch: () => {
    dispatch(sweepStake());
  },
  getHighscoresDispatch: (userId) => {
    dispatch(getHighscores(userId));
  },
});

const mapStateToProps = (state) => ({
  positions: state.sweepstake.sweepstakeWinners
    ? state.sweepstake.sweepstakeWinners
    : [],
  you: state.sweepstake.yourSweepPostion
    ? state.sweepstake.yourSweepPostion
    : false,
  user: state.auth ? state.auth.user : null,
  sweepstakes: state.sweepstake.sweepstakes
    ? state.sweepstake.sweepstakes
    : [],
  highscores: state.game.userHighscores ? state.game.userHighscores : [],
});

export default connect(mapStateToProps, mapDispatchToProps)(Page2);
