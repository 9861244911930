/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link as Link1, withRouter } from "react-router-dom";
import $ from 'jquery';
import { getGames } from '../../actions/sweepStake'
import { getCurrentUser } from '../../actions/authActions'
import { sendScoreUpdate, getGameCoin, updateUserCoins, getUserCoins, doubleUserCoins } from '../../actions/games';
import { coinsAnimation } from '../../helpers/animations'
let vPause = false;
let aiptag = window.aiptag || { cmd: [] };

class Game3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gameName: "",
      gamePause: false,
      soundOn: true,
      score: 0,
      gameOver: false,
      turns: 0,
      adtype: '',
      routeChange: false,
      path: false
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.otherTournamentEnd !== prevProps.otherTournamentEnd) {
      this.pauseFunc();
      window.openModale('#tournament-win')
    }
  }

  componentDidMount() {
    const { props: { location: { search } } } = this;
    this.props.fetchGamesDispatch()
    this.props.fetchCurrentUser()
    this.props.history.block(targetLocation => {
      // debugger
      if (targetLocation.hash === '#account') {
        this.setState({ path: targetLocation.hash })
      }
      if (this.state.routeChange === false) {
        // debugger
        if (targetLocation.hash === '#oops') {
          this.setState({ routeChange: true })
          return this.state.routeChange
        }
        this.setState({
          gamePause: true,
        });
        document.getElementById("myIframe").contentWindow.sound(false);
        document.getElementById("myIframe").contentWindow.pause(true);
        window.openModale('#confirm-exit');
      }
      // debugger
      this.setState({ path: targetLocation.pathname + targetLocation.hash })
      return this.state.routeChange;
    });

    const params = new URLSearchParams(window.location.search)
    $('#pause-modal-close').click(this.handlePauseClose);
    $('.closethismodal').on('click', () => { window.myFunct() })
    $('#play-again-redirect').on('click', () => { window.location = '/?playagain' })
    $('#play-again-redirect2').on('click', () => {
      this.updateUserCoins();
      const target = document.querySelector('#normal-game-coin');
      coinsAnimation(target, this.props.user?.coins, this.props.coins, "NORMAL_GAME");
    })
    $('#yesConfirm').on('click', () => { this.setState({ routeChange: true }) })
    $('#resume-game01').on('click', () => {
      this.setState({
        gamePause: false,
      });
      document.getElementById("myIframe").contentWindow.sound(true);
      document.getElementById("myIframe").contentWindow.pause(false);
    })

    if (search && search !== '') {
      this.setState({
        gameName: params.get('gamename')
      });
    }

    aiptag.cmd.player = aiptag.cmd.player || [];
    aiptag.cmp = {
      show: true,
      position: "centered",  //centered, bottom
      button: true,
      buttonText: "Privacy settings",
      buttonPosition: "bottom-left" //bottom-left, bottom-right, top-left, top-right
    }
    aiptag.cmd.player.push(() => {
      aiptag.adplayer = new window.aipPlayer({
        AD_WIDTH: 960,
        AD_HEIGHT: 540,
        AD_DISPLAY: 'fullscreen', //default, fullscreen, center, fill
        LOADING_TEXT: 'loading advertisement',
        PREROLL_ELEM: function () { return document.getElementById('preroll') },
        AIP_COMPLETE: (e) => {
          if (this.state.adtype === 'redirect-double') {
            const target = document.querySelector('#normal-game-coin')
            let coins = this.props.coins * 2
            if (coins > 0) {
              coinsAnimation(target, this.props.user?.coins, coins, "NORMAL_GAME")
            }
          }
          if (this.state.adtype === 'continue') {
            window.myFunct()
            document.getElementById("myIframe").contentWindow.adComplete();
            document.getElementById("myIframe").contentWindow.sound(true);
          }
        },
        AIP_REMOVE: function () { }
      });
    });
  }

  pauseFunc = () => {
    if (this.state.gamePause === false) {
      this.setState({
        gamePause: true,
      });
      vPause = !vPause;
      document.getElementById('myIframe').contentWindow.postMessage('pause-' + vPause, '*');
      window.openModaleNoCLose("#pause-game")
      document.getElementById("myIframe").contentWindow.pause(true);
      document.getElementById("myIframe").contentWindow.sound(false);
    }
  }

  resumeGame = () => {
    this.setState({ gamePause: false, });
    document.getElementById("myIframe").contentWindow.pause(false);
    document.getElementById("myIframe").contentWindow.sound(true);
    window.hideClose()
  }

  handlePauseClose = event => {
    event.preventDefault();
    console.log('handlePauseClose')
    this.resumeGame();
  }

  handleSound = (bool) => {
    console.log('handleSound bool', bool)
    this.setState({
      soundOn: bool,
    });
    document.getElementById("myIframe").contentWindow.sound(bool);
  }

  loadIframe = (e) => {
    e.preventDefault()
    console.log('CALLED loadIframe')
    setTimeout(function () {
      console.log('callGameStart')
    }, 5000)

    if (window.addEventListener) {
      window.addEventListener("message", (e) => {
        if (e.data.event_type === 'GameEnd') {
          document.getElementById("myIframe")?.contentWindow.sound(false)
          window.openModaleNoCLose("#reward")
        }
        if (e.data.event_type === 'GameOver') {
          document.getElementById("myIframe")?.contentWindow.sound(false);
          if (localStorage.jwtToken && this.state.score > 0) {
            window.openModale('#reward')
          } else if (localStorage.jwtToken && this.state.score === 0) {
            window.location = '/?playagain'
          }
          else {
            window.myFunct2();
            this.props.history.push('/#oops')
          }
        } else if (e.data.event_type === 'Score') {
          if (localStorage.jwtToken) {
            const params = new URLSearchParams(window.location.search)
            this.setState({ score: e.data.event_data })
            const payload = {
              gameScore: e.data.event_data,
              gameName: this.state.gameName,
              gameId: params.get('g_id')
            }
            this.props.sendCurrentScore(payload)
            const coinPayload = { gameScore: e.data.event_data, gameId: params.get('g_id') }
            this.props.getGameCoin(coinPayload)
          } else {
            this.setState({ score: e.data.event_data })
            const coinPayload = {
              gameScore: e.data.event_data,
              gameName: this.state.gameName
            }
            this.props.getGameCoin(coinPayload)
          }
        }
      }, false);
    } else {
      window.attachEvent("onmessage", (e) => { this.setState({ score: 0 }) });
    }
  }

  updateUserCoins = (history) => {
    const { props: { games, coins } } = this;
    const payload = { coins: coins }
    this.props.updateUserCoins(payload)
    const str = {
      gameName: this.state.gameName,
      games,
    }
    const enc = window.btoa(JSON.stringify(str));
    localStorage.setItem('tempNormalGameObject', enc)
  }

  exitGame = () => {
    this.props.history.push('/')
  }

  render() {
    const {
      state: { gameName, gamePause, soundOn },
      props: { games, user, coins }
    } = this;
    const self = this;
    const gameObj = games.find(obj => obj.name.replace(/ /g, "_").toLocaleLowerCase() === gameName)
    const gameIcon = gameObj ? gameObj.icons[0].imageURL : ''

    return (
      <div>
        <div id="preroll"></div>
        <div className="game-header">
          <button onClick={this.exitGame} className="game-btn game-btn__close">
            <i className="icon-clear"></i>
          </button>
          {gamePause && <p className="game-score text-center">PAUSED</p>}
          {!gamePause && <p className="game-score text-center">SCORE:<span>{this.state.score && this.state.score !== 0 ? this.state.score : '0'}pt</span></p>}
          {<button id="game-pause-btn" onClick={this.pauseFunc} className='game-btn game-btn__pause'>
            <i className={`${gamePause ? 'icon-play' : 'icon-pause'}`}></i>
          </button>}
          {soundOn && <div onClick={() => this.handleSound(false)} className="game-btn game-btn__volume">
            <i className="icon-volume"></i>
          </div>}
          {!soundOn && <div onClick={() => this.handleSound(true)} className="game-btn game-btn__volume">
            <i className="icon-volume muted"></i>
          </div>}
        </div>
        <div className="game-content">
          {console.log(gameName)}
          <iframe frameBorder="0" className="game-iframe" height="600" width="600" src={process.env.PUBLIC_URL + `/assets/games/${gameName}/index.html`} onLoad={e => this.loadIframe(e)} id="myIframe"></iframe>
        </div>

        <div id="pause-game" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__pt popup-style__w600">
          <div className="popup-box">
            <div className="popup-top">
              <div className="popup-top__img popup-top__img--picture">
                <img src={gameIcon} alt="" />
              </div>
            </div>
            <div className="popup-content current-info">
              <p className="current-score">Current Score: <span>{this.state.score && this.state.score !== 0 ? this.state.score : '0'}pt</span> </p>
              <br />
              <a id="pause-modal-close" onClick={(e) => self.handlePauseClose(e)} className="btn btn__blue btn-continue closethismodal">CONTINUE</a>
            </div>
          </div>
        </div>

        {/*popup-reward*/}
        <div id="reward" style={{ display: 'none' }} className="popup-style popup-style__text-center popup-style__pt popup-style__w600">
          <div className="popup-box">
            <div className="popup-top">
              <div className="popup-top__img popup-top__img--picture">
                <img src={gameIcon} alt="" />
              </div>
            </div>
            <div className="popup-content">
              <div className="current-info">
                <p className="reward-info">REWARD: {Math.round(this.state.score * gameObj?.coins)} <img id='normal-game-coin' src="images/icons/coin-no-shadow-icon.png" alt="" /></p>
              </div>
              <p className="popup-thank mt20"><a id='play-again-redirect2'>CLAIM</a></p>
            </div>
          </div>
        </div>
        {/*popup-reward*/}

        <div id="confirm-exit" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__w600">
          <div className="popup-box">
            <div className="popup-content">
              <h2 className="popup-title popup-title__f45">QUIT GAME NOW?</h2>
              <br />
              <h3 className="popup-title popup-title__f22">IF YOU LEAVE THE GAME NOW<br /> YOU WILL LOSE ALL YOUR CURRENT PROGRESS</h3>
              <br />
              <Link1 to={this.state.path}>
                <a id='yesConfirm' className="btn btn__blue btn__icon--r btn-history">EXIT NOW</a>
              </Link1>
              <br />
              <br />
              <p className="popup-thank" id='resume-game01'><a data-fancybox-close >BACK TO THE GAME</a></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  fetchGamesDispatch: () => {
    dispatch(getGames());
  },
  fetchCurrentUser: () => {
    dispatch(getCurrentUser());
  },
  sendCurrentScore: (payload) => {
    dispatch(sendScoreUpdate(payload));
  },
  getGameCoin: (payload) => {
    dispatch(getGameCoin(payload));
  },
  updateUserCoins: (payload) => {
    dispatch(updateUserCoins(payload));
  },
  getUserCoins: () => {
    dispatch(getUserCoins());
  },
  doubleUserCoins: (payload) => {
    dispatch(doubleUserCoins(payload));
  },
});

const mapStateToProps = state => ({
  games: state.sweepstake.games ? state.sweepstake.games : [],
  user: state.auth ? state.auth.user : null,
  coins: state.game ? state.game.coins : 0,
  userCoins: state.game ? state.game.userCoins : 0,
  sweepstake: state.sweepstake.sweepstakes ? state.sweepstake.sweepstakes : [],
  highscores: state.game.userHighscores ? state.game.userHighscores : [],
  otherTournamentEnd: state.tournament.otherTournamentEnded ? state.tournament.otherTournamentEnded : false
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Game3));
