import { PAYMENT_REQUEST_SUCCESS,SET_USER_TRANSACTION, SET_CURRENT_USER } from "./types";
import axios from 'axios'
import jwtDecode from "jwt-decode";
let backendServerURL = process.env.REACT_APP_URL;

export const requestPaymentRequest = (payload) => dispatch => {
    const options = {
        token: localStorage.jwtToken,
        'Content-Type' : 'application/json'
    }

    axios.post(
        `${backendServerURL}payments/requestPayment`,
        payload,
        options
    )
    .then(res => {
        // debugger
        if(res.data.success){

            const { user } = res.data
            dispatch(setCurrentUser(user))
            dispatch({ type: PAYMENT_REQUEST_SUCCESS, payload: true})
            window.openModale('#back-profile')
        }
        // dispatch({ type: YOUR_SWEEP_POSITION, payload: currentUserPosition})
        
    })
    .catch(err => {
        console.log('SWEEPSTAKE WINNERS API ERR', err)
    })
}
export const setCurrentUser = (user) => ({
    type: SET_CURRENT_USER,
    payload: user,
  });

  export const getTransactionHistory = () => dispatch =>{
    // debugger
    const token = jwtDecode(localStorage.jwtToken,'somesupersupersecretsecret')
    // debugger
    const options = {
      token : localStorage.jwtToken,
      'Content-Type': 'application/json',
    };
    axios
    .post(
      `${backendServerURL}payments/getPaymentsHistory`,{userId : token.userId},
      options
    )
    .then(res => {
      // debugger
      dispatch({ type: SET_USER_TRANSACTION, payload: res.data.transactions })
    })
    .catch(err => {
      console.log('getPayments ERR', err)
    })
  } 

  export const cancelTransaction = () => dispatch => {
    const token = jwtDecode(localStorage.jwtToken,'somesupersupersecretsecret')
    const options = {
        token : localStorage.jwtToken,
        'Content-Type': 'application/json',
      };
    axios.delete( `${backendServerURL}payments/cancelPayment`,
    options).then(res => {
        // debugger
        if(res.status === 202){

          dispatch({ type: SET_CURRENT_USER, payload: res.data.user })
          window.openModale('#cancel-withdraw-modal')
        }
      })
      .catch(err => {
        console.log('getPayments ERR', err)
      })

  }