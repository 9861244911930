import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history'
import {composeWithDevTools} from 'redux-devtools-extension/logOnlyInProduction';
import { routerMiddleware } from 'connected-react-router'
import Reducers from './reducers';
export const history = createBrowserHistory()
export default function configureStore(initialState={}) {
 return createStore(
   Reducers(history),
   initialState,
   composeWithDevTools(applyMiddleware(thunk, routerMiddleware(history)))
  
 );
}

