import React, { Component } from "react";
import { Link as Link1 } from "react-router-dom";
import { connect } from "react-redux";
import { getTournaments } from "../../actions/sweepStake";
import { getAllSweepStake } from "../../actions/sweepStake";
import moment from "moment";
import $ from "jquery";

class Page1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tournamentsToShow: 10,
      sweepStakeToShow: 10,
    };
  }

  componentDidMount() {
    this.props.getAllSweepStakeDispatch();
    $('.tab-menu li').click(function () {
      $(this).addClass('active').siblings().removeClass('active').closest('.tab-wrap').find('.tab-content').hide().eq($(this).index()).fadeIn();
    });
  }

  getDateFormat = (date) => {
    return moment(date).format("DD MMM 'YY");
  };

  render() {
    let {
      props: { allTournaments, sweepstakes, tournamentEnded, sweepstakeEnded, user },
    } = this;
    const tournamentsArray = allTournaments.filter((el) => moment(el.finishDate).isBefore(moment()));
    const sortedTournaments = tournamentsArray.sort((a, b) => moment(b.finishDate) - moment(a.finishDate));
    const filteredSweeps = sweepstakes.filter((el) => moment(el.finishDate).isBefore(moment()));
    const sortedSweeps = filteredSweeps.sort((a, b) => moment(b.finishDate) - moment(a.finishDate));

    return (
      <div>
        <main className="block-table tab-wrap">
          <section>
            {/* <div className="table-wrap table-wrap__w990"> */}
            <ul className="tab-menu table-menu table-wrap table-wrap__w990">
              <li className="active">
              {sweepstakeEnded?.userPositions && <img className="notify-icon" src="images/icons/notify.png" alt="notify" />}
                <span>SWEEPSTAKES</span>
              </li>
              <li>
                {tournamentEnded?.userPosition && <img className="notify-icon" src="images/icons/notify.png" alt="notify" />}
                <span>TOURNAMENTS</span>
              </li>
            </ul>
            {/* </div> */}

            <div className="tab-content">
              <div className="table-wrap table-wrap__w990">
                <div className="table winner-table">
                  <div className="t-head">
                    <div className="t-contest">Contest</div>
                    <div className="t-date">End Date</div>
                    <div className="t-cash">$PLY Prize Pool</div>
                  </div>
                </div>
              </div>

              <section className="tab-wrap scroll_sweep_tournaments">
                <div className="table-wrap table-wrap__w990">
                  {sortedSweeps &&
                    sortedSweeps.map((el, index) => {
                      const foundUser = el.currentUsers.find(obj => obj.userId === user._id);
                      return (
                        <Link1 key={index} to={`sweepstake?s_id=${el._id}`}>
                          <div className="t-row">
                            <div className="t-contest">
                              {foundUser && !foundUser?.seen && <img className="notify-icon" src="images/icons/notify.png" alt="notify" />}
                              {el.name}
                              </div>
                            <div className="t-date">
                              {this.getDateFormat(el.finishDate)}
                            </div>
                            <div className="t-cash color-green" style={{ display: "flex", justifyContent:"center" }}>
                              {foundUser && <img className="trophy-icon" src="images/icons/trophy.png" alt="trophy" />}
                              <span>$PLY {el.totalJackpotAmount}</span>
                            </div>
                          </div>
                        </Link1>
                      );
                    })}
                </div>
              </section>
            </div>

            <div className="tab-content">
              <div className="table-wrap table-wrap__w990">
                <div className="table winner-table">
                  <div className="t-head">
                    <div className="t-contest">game</div>
                    <div className="t-date">Date</div>
                    <div className="t-cash">Jackpot</div>
                  </div>
                </div>
              </div>
              <section className="tab-wrap scroll_sweep_tournaments">
                <div className="table-wrap table-wrap__w990">
                  {sortedTournaments &&
                    sortedTournaments.map((tournamentObj, index) => {
                      const foundUser = tournamentObj.currentUsers.find(obj => obj.userId === user._id);
                      return (
                        <Link1 key={index} to={`tournamentWinners?t_id=${tournamentObj._id}`}>
                          <div className="t-row">
                            <div className="t-contest" style={{ display: "flex" }}>
                              {foundUser && !foundUser?.seen && <img className="notify-icon" src="images/icons/notify.png" alt="notify" />}
                              <div className="t-game" >
                                <img src={tournamentObj.game[0] ? tournamentObj.game[0].icons[0].imageURL : ""} alt="" />
                              </div>
                            </div>
                            <div className="t-date">
                              {this.getDateFormat(tournamentObj.finishDate)}
                            </div>
                            <div className="t-cash color-green" style={{ display: "flex", justifyContent:"center" }}>
                              {foundUser &&<img className="trophy-icon" src="images/icons/trophy.png" alt="trophy" />}
                              <span>$PLY {tournamentObj.totalJackpotAmount}</span>
                            </div>
                          </div>
                        </Link1>
                      );
                    })}
                </div>
              </section>
            </div>
          </section>
        </main>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  getAllSweepStakeDispatch: () => {
    dispatch(getAllSweepStake());
  },
  fetchTournamentsDispatch: () => {
    dispatch(getTournaments());
  },
});
const mapStateToProps = (state) => ({
  user: state.auth ? state.auth.user : null,
  allTournaments: state.sweepstake.allTournaments ? state.sweepstake.allTournaments : [],
  sweepstakes: state.sweepstake.allSweepstakes ? state.sweepstake.allSweepstakes : [],
  tournamentEnded: state.tournament.tournamentEnded ? state.tournament.tournamentEnded : false,
  sweepstakeEnded: state.sweepstake.sweepstakeEnded ? state.sweepstake.sweepstakeEnded : false,
});
export default connect(mapStateToProps, mapDispatchToProps)(Page1);
