import React, { Component } from "react";

export default class subscriptionResponse extends Component {
  render() {
    {
      /* <!--popup withdraw amount--> */
    }
    return (
      <div
        id="back-profile"
        style={{ display: "none" }}
        className="popup-style popup-style__text-center popup-style__w600"
      >
        <p>
          <button
            type="button"
            data-fancybox-close
            className="fancybox-close-small fancybox-button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1"
              viewBox="0 0 24 24"
            >
              <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path>
            </svg>
          </button>
        </p>
        <div className="popup-box">
          <div className="popup-content">
            <div className="popup-text">
              <h2>congratulations</h2>
              <p>YOU’VE SUBSCRIBED TO OUR NEWSLETTER</p>
            </div>
            <p className="congratulations-subtitle">
              Be sure to always check for new updates.
              <br /> When we release the platform in a new country we send out
              official invitations to our users.
              <br /> We will now redirect you to our company's website
            </p>
            <a
              href="https://playtoo.io/"
              className="btn btn__blue btn__icon--r "
            >
              OKAY <i className="icon-checkmark"></i>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
