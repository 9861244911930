import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ScrollToTop from '../../helpers/scrollToTop'

export default class Footer extends Component {
    render() {
        return (
            <div id="footer">
                <footer>
                    <div className="container">
                        <div className="footer-row">
                            <div className="footer-l">
                                <a href="/" className="footer-logo">
                                    <img src="images/logo.png" alt="" />
                                </a>
                                {/* <div className="soc">
                                    <a href="https://twitter.com/playtoogames"><i className="icon-twitter"></i></a>
                                    <a href="#"><i className="icon-youtube"></i></a>
                                    <a href="https://www.facebook.com/playtoogames"><i className="icon-facebook"></i></a>
                                    <a href="https://www.instagram.com/playtooio/"><i className="icon-instagram"></i></a>
                                    <a href="https://www.linkedin.com/company/playtoogames"><i className="icon-linkedin"></i></a>
                                </div> */}
                            </div>
                            <div className="footer-widgets">
                                <ul className="footer-menu">
                                    <li><a id="play-redirect-sweep2">Sweepstake</a></li>
                                </ul>
                                <ul className="footer-menu">
                                    <li><a id="play-redirect-tour2">Tournaments</a></li>
                                </ul>
                                <ul className="footer-menu">
                                    <li><Link to="/winner">Winners</Link></li>
                                </ul>
                                <ul className="footer-menu">
                                    <li><a id='how-to-win2' onClick={() => window.openModaleNoCLose("#cash-prizes")}>How to win</a></li>
                                </ul>
                                <ul className="footer-menu">
                                    <li><a href="https://playtoo.io/" target="_blank">Corporate</a></li>
                                </ul>
                            </div>
                            <div className="copyright">Playtoo Inc. 2021 Copyright</div>
                        </div>
                    </div>
                </footer>

            </div>
        )
    }
}
