import React, { Component } from "react";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { requestPaymentRequest } from "../../actions/paymentActions";
class WithdrawAmount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "",
      paypalAddress: "",
      error: false,
      error2: false,
    };
  }
  componentDidMount() {
    $(".withdraw-input").on("change", (e) => this.handleMoney(e));
    $('#send_req').on('click', this.handleRequest)
  }
  handleRequest = (e) => {
    e.preventDefault()
    const {
      props: { requestPaypalPaymentDispatch },
      state: { amount, error, error2, paypalAddress }
    } = this;
    if (!error && !error2 && amount && paypalAddress) {
      const obj = {
        paypalAddress, amount
      }

      requestPaypalPaymentDispatch(obj)
      window.myFunct()
    }
  }

  validateField(fieldName, value) {
    const {
      props: { user },
    } = this;
    let emailmatch = false;
    let error2 = this.state.error2;
    let amountVal = false;
    let error = this.state.error;
    switch (fieldName) {
      case 'paypalAddress':
        emailmatch = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        error2 = emailmatch ? false : '* Email is invalid';
        break;
      case 'amount':
        amountVal = Number(value) <= Number(user.money)
        error = amountVal ? false : "* The amount you entered is higher than your balance";
        break;
      default:
        break;
    }
    this.setState({
      error2,
      error,
    });
  }

  handleMoney = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
    }, this.validateField(name, value));
  };

  render() {
    const {
      state: { error, error2 },
      props: { user },
    } = this;
    
    return (
      <div
        id="withdraw-amount"
        style={{ display: "none" }}
        className="popup-style popup-style__text-center popup-style__w600"
      >
        <p>
          <button
            type="button"
            data-fancybox-close
            className="fancybox-close-small fancybox-button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1"
              viewBox="0 0 24 24"
            >
              <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path>
            </svg>
          </button>
        </p>
        <div className="popup-box">
          <div className="popup-content">
            <div className="style-form">
              <form action="">
                <div className="form-pay">
                  <div className="withdraw-img line-effect">
                    <img src="images/icons/money-icon.png" alt="" />
                  </div>
                </div>
                <h2>withdraw amount</h2>
                <div className="field field-icon">
                  <input
                    className="withdraw-input"
                    type="number"
                    name="amount"
                  />
                  <p style={{ color: '#7a8595' }}>{error ? error : ""}</p>
                </div>
                <p className="form-balance">
                  Your balance:{"$PLY " + (user.money ? user.money : 0)}
                </p>
                <div className="field field-icon">
                  <i className="icon-mail-envelope-closed"></i>
                  <input
                    className="withdraw-input"
                    type="email"
                    name="paypalAddress"
                    placeholder="Payment email"
                  />
                  <p style={{ color: '#7a8595' }}>{error2 ? error2 : ""}</p>
                </div>
                <button className="btn btn__blue btn__icon--r" id="send_req" type="submit">
                  send request
                  <i className="icon-arrow-right"></i>
                </button>
              </form>
            </div>
            <p className="popup-bottom__link">
              *Payment fees might be deducted according to your payment method
              **You will receive the payment in maximum 7 days
            </p>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  requestPaypalPaymentDispatch: (obj) => {
    dispatch(requestPaymentRequest(obj));
  },
});
const mapStateToProps = state => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WithdrawAmount));
