import axios from 'axios'

import { ADD_SCORE, DOUBLE_COINS, GET_COIN, SET_USER_MONEY, SET_HIGHSCORER_PROFILE, GET_USER_COIN, UPDATE_USER_COIN, DOUBLE_USER_COIN, ADD_TOURNAMENT_SCORE, SET_USER_HIGHSCORES, SET_WINNINGS } from './types';
import { setProfileLoading, clearProfileLoading } from "./profileActions";

let backendServerURL = process.env.REACT_APP_URL;

export const sendScoreUpdate = (payload) => dispatch => {
    const options = {
        Authorization: localStorage.jwtToken,
        'Content-Type': 'application/json',
    };

    axios
        .post(
            `${backendServerURL}games/addScore`,
            payload,
            options
        )
        .then(res => {
            const { message, newScore } = res.data
            dispatch({ type: ADD_SCORE, payload: newScore })

            console.log(message)
        })
        .catch(err => console.log('Add Score Error..', err))

}

export const sendTournamentScoreUpdate = (payload) => dispatch => {
    const options = {
        Authorization: localStorage.jwtToken,
        'Content-Type': 'application/json',
    };

    axios
        .post(
            `${backendServerURL}games/addTournamentScore`,
            payload,
            options
        )
        .then(res => {
            const { message, newScore } = res.data
            dispatch({ type: ADD_TOURNAMENT_SCORE, payload: newScore })

            console.log(message)
        })
        .catch(err => console.log('Add Score Error..', err))

}

export const getGameCoin = (payload) => dispatch => {

    axios
        .post(
            `${backendServerURL}games/getGameCoin`,
            payload,
        )
        .then(res => {
            const { coins } = res.data
            dispatch({ type: GET_COIN, payload: coins })


        })
        .catch(err => console.log('Get Coins Error..', err))

}

export const getUserCoins = (payload) => dispatch => {
    const options = {
        Authorization: localStorage.jwtToken,
        'Content-Type': 'application/json',
    };

    axios
        .get(
            `${backendServerURL}games/getUserCoins`,
            options,
        )
        .then(res => {
            const { coins } = res.data
            dispatch({ type: GET_USER_COIN, payload: coins })


        })
        .catch(err => console.log('Get Coins Error..', err))

}

export const updateUserCoins = (payload) => dispatch => {
    const options = {
        Authorization: localStorage.jwtToken,
        'Content-Type': 'application/json',
    };

    axios
        .post(
            `${backendServerURL}games/updateUserCoins`,
            payload,
            options,
        )
        .then(res => {
            const { coins, money } = res.data
            dispatch({ type: UPDATE_USER_COIN, payload: coins })
            if (money) {
                dispatch({ type: SET_USER_MONEY, payload: money })
            }
        })
        .catch(err => console.log('Get Coins Error..', err))

}

export const doubleUserCoins = (payload) => dispatch => {
    const options = {
        Authorization: localStorage.jwtToken,
        'Content-Type': 'application/json',
    };
    // debugger
    axios
        .post(
            `${backendServerURL}games/doubleUserCoins`,
            payload,
            options,
        )
        .then(res => {
            const { coins } = res.data
            // debugger
            dispatch({ type: DOUBLE_USER_COIN, payload: coins })
            // dispatch({
            //     type: DOUBLE_COINS, payload: coins
            // })

        })
        .catch(err => console.log('Get Coins Error..', err))

}
export const getHighscores = (payload) => dispatch => {
    dispatch(setProfileLoading());
    axios
        .post(
            `${backendServerURL}games/getHighscores`,
            payload,

        )
        .then(res => {
            // debugger
            const userHighscores = res.data.highscoresArray
            const highscorer = res.data.newUserObj
            dispatch({ type: SET_USER_HIGHSCORES, payload: userHighscores })

            dispatch({ type: SET_HIGHSCORER_PROFILE, payload: highscorer })
            window.openModale("#highscores");

        })
        .catch(err => console.log('Get Coins Error..', err))
        .finally(() => dispatch(clearProfileLoading()));

}

export const getWinnings = (payload) => dispatch => {
    dispatch(setProfileLoading());
    // debugger
    axios
        .post(
            `${backendServerURL}admin/getUserWinnings`,
            payload,
        )
        .then(res => {
            // debugger
            const winnings = res.data.winnings
            dispatch({ type: SET_WINNINGS, payload: winnings })
            window.openModale('#mywinnings')
        })
        .catch(err => console.log('Get Coins Error..', err))
        .finally(() => dispatch(clearProfileLoading()))

}