import $ from 'jquery';
import { CountUp } from 'countup.js';

export const moneyAnimation = (target, winning, myMoney, type, coins) => {
  // debugger
  var cart = document.querySelector(".money-add-point");
  $('.sticky-header').css({
    "z-index": '100000'
  })
  const totalMoney = Number(winning) + Number(myMoney)

  const options = {
    startVal: myMoney,
    decimalPlaces: 2,
    duration: 3,
  };
  const coinsOptions = {
    startVal: coins,
    decimalPlaces: 2,
    duration: 3,
  };
  const moneyPoint = document.querySelector('#header-money')
  const coinsPoint = document.querySelector('#coins')
  setTimeout(() => {
    let demo = new CountUp(moneyPoint, totalMoney, options);
    if (!demo.error) {
      demo.start();
    } else {
      console.error(demo.error);
    }
    if(type === "SWEEP_MONEY"){
      let coinsDemo = new CountUp(coinsPoint, 0, coinsOptions);
      if (!demo.error) {
        coinsDemo.start();
      } else {
        console.error(coinsDemo.error);
      }
    }

  }, 1000)

  let interval = setInterval(() => {
    var butn = $(target);
    // debugger
    let coin = document.createElement('div');
    coin.className = 'money-add'
    document.body.appendChild(coin)

    $(coin).css({ "left": $(butn).offset().left, "top": $(butn).offset().top - 12 }).animate({ "top": $(cart).offset().top, "left": $(cart).offset().left }, 1000, function () { coin.remove(); });
    setTimeout(() => {
      let coin2 = document.createElement('div');
      coin2.className = 'money-add'
      document.body.appendChild(coin2)
      $(coin2).css({
        "left": $(butn).offset().left,
        "top": $(butn).offset().top - 12
      })
        .animate({
          "top": $(cart).offset().top,
          "left": $(cart).offset().left - 30
        }
          , 950, function () {
            coin2.remove();
          });
    }, 100)
    setTimeout(() => {
      let coin3 = document.createElement('div');
      coin3.className = 'money-add'
      document.body.appendChild(coin3)
      $(coin3).css({
        "left": $(butn).offset().left,
        "top": $(butn).offset().top - 12
      })
        .animate({
          "top": $(cart).offset().top,
          "left": $(cart).offset().left + 30
        }
          , 900, function () {
            coin3.remove();
          });
    }, 200)

  }, 200)
  setTimeout(() => {
    clearInterval(interval)
    setTimeout(() => {
      $('.sticky-header').css({
        "z-index": '1000'
      })
      if (type === "TOURNAMENT_MONEY" || type === "SWEEP_MONEY") {
        window.myFunct()
      }

    }, 1200)
  }, 3000)
}

export const coinsAnimation = (target, coins, gameCoins, type, history) => {
  // debugger
  const totalCoins = Number(gameCoins) + Number(coins)
  $('.sticky-header').css({
    "z-index": '100000'
  })
  const options = {
    startVal: coins,
    //   decimalPlaces: 2,
    duration: 3,
  };
  var cart = document.querySelector(".coins-add-point");
  const coinsPoint = document.querySelector('#coins')
  setTimeout(() => {

    let demo = new CountUp(coinsPoint, totalCoins, options);
    if (!demo.error) {
      demo.start();
    } else {
      console.error(demo.error);
    }
  }, 1000)

  let interval = setInterval(() => {
    var butn = $(target);
    // debugger
    let coin = document.createElement('div');
    coin.className = 'coin-add'
    document.body.appendChild(coin)

    $(coin).css({
      "left": $(butn).offset().left,
      "top": $(butn).offset().top - 12
    })
      .animate({
        "top": $(cart).offset().top,
        "left": $(cart).offset().left
      }
        , 1000, function () {
          coin.remove();
        });
    setTimeout(() => {
      let coin2 = document.createElement('div');
      coin2.className = 'coin-add'
      document.body.appendChild(coin2)
      $(coin2).css({
        "left": $(butn).offset().left,
        "top": $(butn).offset().top - 12
      })
        .animate({
          "top": $(cart).offset().top,
          "left": $(cart).offset().left - 30
        }
          , 950, function () {
            coin2.remove();
          });
    }, 100)
    setTimeout(() => {
      let coin3 = document.createElement('div');
      coin3.className = 'coin-add'
      document.body.appendChild(coin3)
      $(coin3).css({
        "left": $(butn).offset().left,
        "top": $(butn).offset().top - 12
      })
        .animate({
          "top": $(cart).offset().top,
          "left": $(cart).offset().left + 30
        }
          , 900, function () {
            coin3.remove();

          });
    }, 200)

  }, 200)
  setTimeout(() => {
    clearInterval(interval)
    setTimeout(() => {
      $('.sticky-header').css({
        "z-index": '1000'
      })
      if (type === "NORMAL_GAME") {
        window.location = '/?playagain'
      }
      if (type === "TOURNAMENT") {
        window.myFunct()
      }
      if (type === "TOURNAMENT_DOUBLE") {
        window.myFunct()
        history.push("/?live-tournament-modal");
      }
      if (type === "SWEEP_END") {
        window.myFunct()
        if (history.pathname === '/gameplay' || history.pathname === '/tournament') {
          // debugger
          document.getElementById("myIframe").contentWindow.sound(true);
          document.getElementById("myIframe").contentWindow.pause(false);
        }
      }
      if (type === "TOUR_END") {
        window.myFunct()
        if (history.pathname === '/gameplay' || history.pathname === '/tournament') {
          document.getElementById("myIframe").contentWindow.sound(true);
          document.getElementById("myIframe").contentWindow.pause(false);
        }
      }
      if (type === "TOUR_DOUBLE") {
        window.myFunct()

        // debugger
        if (window.location.pathname === '/gameplay' || window.location.pathname === '/tournament') {
          document.getElementById("myIframe").contentWindow.sound(true);
          document.getElementById("myIframe").contentWindow.pause(false);
        }
        // if(history.pathname === '/gameplay'|| history.pathname === '/tournament' ){
        //   document.getElementById("myIframe").contentWindow.sound(true);
        //   document.getElementById("myIframe").contentWindow.pause(false);
        //   }
      }
      // return true
    }, 1200)

  }, 2000)
}
// export default animationPlay