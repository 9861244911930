import React, { Component } from "react";
import $ from "jquery";
import { subscribeToNewsletter } from "../../actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
class countryNotAvailable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
  }
  componentDidMount() {
    $("#email_newsletter").on("change", this.handleChange);
    $("#valid-country").on("click", "#sub-submit", this.handleSubmit);
  }

  handleChange = (e) => {
    // debugger
    this.setState({
      email: e.target.value,
    });
    // debugger
  };
  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.state.email);
    const email = this.state.email;
    this.props.subscribeToNewsletterDispatch(email);
    // debugger
  };
  render() {
    {
      /* <!--popup platform in country--> */
    }
    return (
      <div
        id="valid-country"
        style={{ display: "none" }}
        className="popup-style popup-style__text-center popup-style__pt popup-style__w600"
      >
        <p>
          <button
            type="button"
            data-fancybox-close
            className="fancybox-close-small fancybox-button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1"
              viewBox="0 0 24 24"
            >
              <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path>
            </svg>
          </button>
        </p>
        <div className="popup-box">
          <div className="popup-top">
            <div className="popup-top__img popup-top__img--logo">
              <img src="images/logo.png" alt="" />
            </div>
          </div>
          <div className="popup-content">
            <h2 className="popup-title popup-title__f22">
              Our platform is not currently available in your country
            </h2>
            <p className="congratulations-subtitle">
              To know when we will release our platform in your country feel
              free to subscribe to our newsletter
            </p>
            <div className="style-form register-form">
              <form>
                <div className="field field-icon">
                  <i className="icon-mail-envelope-closed"></i>
                  <input
                    type="email"
                    id="email_newsletter"
                    placeholder="Email"
                  />
                </div>
                <button
                  className="btn btn__blue btn__icon--r"
                  id="sub-submit"
                  type="submit"
                >
                  submit
                  <i className="icon-arrow-right"></i>
                </button>
              </form>
            </div>
            <p className="popup-thank">
              Already registered?
              <br /> <a href="#">LOG IN HERE</a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  profile: state.profile,
  error: state.error.loginErr ? state.error.loginErr : "",
});
const mapDispatchToProps = (dispatch) => ({
  subscribeToNewsletterDispatch: (email) => {
    dispatch(subscribeToNewsletter(email));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(countryNotAvailable));
