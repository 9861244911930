import React, { Component } from 'react';
import $ from 'jquery'
export default class MoneyBack extends Component {
    componentDidMount(){
        $('#back-to-profile-cancel').on('click',()=> {
            window.myFunct()
            // window.openModale('#popup-account')
        })
    }
  render() {
    return <div>
        <div id="cancel-withdraw-modal" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__w600">
        <p>
          <button
            type="button"
            data-fancybox-close
            className="fancybox-close-small fancybox-button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1"
              viewBox="0 0 24 24"
            >
              <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path>
            </svg>
          </button>
        </p>
          <div className="popup-box">
            <div className="popup-content">
              <div className="popup-text">
                <h2>YOUR MONEY ARE BACK</h2>
                <br/>
                <p>YOU’VE SUCCESSFULLY CANCELED  <br/> YOUR WITHDRAWAL REQUEST</p>
              </div>
              <br/>
              <p className="congratulations-subtitle">Your money has been successfully sent back to your wallet and are immediately availble for you to use</p>
              <a id="back-to-profile-cancel" className="btn btn__blue btn__icon--r btn-back">BACK TO PROFILE <i className="icon-arrow-left"></i></a>
            </div>
          </div>
        </div>
    </div>;
  }
}
