import React, { Component } from "react";
import PropTypes from 'prop-types';
// import Spinner from './../common/Spinner'
import { Link, withRouter } from 'react-router-dom';
// import Header from './../common/header/header';
import { connect } from 'react-redux';
import { resetUserPassword } from '../../actions/authActions'

import $ from "jquery"

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            passwordValid: false,
        };
    }
    componentDidMount() {
        $('#reset-submit').click(this.onSubmit)
        $('#resetpassword').change(this.onChange)

    }
    onSubmit = e => {
        e.preventDefault();
        const token = this.props.location.search;
        // debugger
        if (token.includes('token')) {
            let url1 = token
            const finaltoken = url1.split("token=").pop()
            // console.log(finaltoken)
            // debugger
            this.props.resetUserPassword(this.state.password, finaltoken, this.props.history);

        }
    }

    onChange = e => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value },
            () => { this.validateField(name, value) })
    }

    validateField(fieldName, value) {
        let passwordlength = '';
        let passwordValid = this.state.passwordValid;
        switch (fieldName) {
            case 'password':
                passwordlength = value.length >= 6;
                passwordValid = passwordlength ? '' : ' Password is too short. must be 6 characters long';
                break;
            default:
                break;
        }
        this.setState({
            passwordValid,
        });
    }

    render() {
        // const { loading } = this.props.profile;
        // console.log(this.props.error)
        // if (loading) {
        //     return <Spinner />;
        // }
        return (
            <div>
                {/* <!--popup form email confirmed--> */}
                <div id="email-confirmed"
                    style={{ display: "none" }}
                    data-fancybox="reset-password" className="popup-style popup-style__text-center popup-style__w600">
                    <div className="popup-box">
                        <div className="popup-content">
                            <h2 className="popup-title popup-title__f45">EMAIL CONFIRMED</h2>
                            <div className="style-form register-form">
                                <form action="">
                                    <div className="field field-icon">
                                        <i className="icon-lock"></i>
                                        <input name="password" type="password" value={this.state.password} placeholder="Choose a new password" id="resetpassword" onChange={(e) => this.setState({ password: e.target.value })} />
                                        <span className="toggle-password icon-eye"></span>
                                        {this.props.error.newPassword ? <span style={{ color: "red" }}>{this.props.error.newPassword}</span> : ""}
                                        {this.props.error.emailNotFound ? <span style={{ color: "red" }}>{this.props.error.emailNotFound}</span> : ""}
                                    </div>
                                    <button className="btn btn__blue btn__icon--r" id="reset-submit" type="submit">
                                        confirm
                                        <i className="icon-arrow-right"></i>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Header facebook="ForgotPassword with facebook" google="ForgotPassword with google"/>
                <form>
                    <h3>Reset Password</h3>

                    <div className="form-group">
                        <label>New Password</label>
                        <input type="password" name="password" className="form-control" placeholder="Enter password" value={this.state.password} onChange={this.onChange} required="required" />
                        {this.props.error.password ? <span style={{ color: "red" }}>{this.props.error.password}</span> : ""}
                        {this.props.error.emailNotFound ? <span style={{ color: "red" }}>{this.props.error.emailNotFound}</span> : ""}
                        {this.state.passwordValid ? <span style={{ color: "red" }}>{this.state.passwordValid}</span> : ""}
                    </div>

                    <button type="submit" className="btn btn-primary btn-block" onClick={this.onSubmit}>Submit</button>
                </form> */}
            </div>
        );
    }
}
ResetPassword.propTypes = {
    resetUserPassword: PropTypes.func.isRequired,
    error: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    profile: state.profile,
    error: state.error
});

export default connect(mapStateToProps, { resetUserPassword })(withRouter(ResetPassword));