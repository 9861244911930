import axios from 'axios';

import {ADD_T_POSITIONS,ADD_NOTIFICATIONS,DOUBLE_T_COINS, DELETE_NOTIFICATIONS,CREATE_TOURNAMENTS_SEEN , GET_THIS_POSITION_ONLY, GET_USER_TOURNAMENTS, OTHER_TOURNAMENT_ENDED } from './types';
import { SET_MODAL_TOURNAMENT,TOURNAMENTS, TOURNAENT_POSITIONS, YOUR_POSITION, GAME_IMG, GET_MONTHLY_TOURNAMENT_WINNERS } from './types';
import { setProfileLoading, clearProfileLoading } from "./profileActions";

let backendServerURL = process.env.REACT_APP_URL;

export const tournaments = (payload) => dispatch => {
    console.log('Tournaments Status Payload', payload)


    const options = {
        token: localStorage.jwtToken,
        'Content-Type': 'application/json',
    };

    axios
        .get(
            `${backendServerURL}admin/Tournaments`,
            payload,
            options
        )
        .then(res => {
            console.log('TOURNAMENTS API RESPONSE', res);
            dispatch({ type: TOURNAMENTS, payload: res })
        })
        .catch(err => {
            console.log(' TOURNAMENTS API ERR', err)

        })

};

export const getTournamentPositions = (payload) => dispatch => {
    dispatch(setProfileLoading());
    const options = {
        token: localStorage.jwtToken,
        'Content-Type' : 'application/json'
    }

    axios.post(
        `${backendServerURL}admin/getTournamentPositions`,
        payload,
        options
    )
    .then(res => {
        const { users, currentUserPosition, tournamentPayload } = res.data
        
        console.log('TOURNAMENT POSITIONS API RESPONSE', users);
        
        dispatch({ type: TOURNAENT_POSITIONS, payload: users})
        dispatch({ type: YOUR_POSITION, payload: currentUserPosition})
        dispatch({ type: GAME_IMG, payload: tournamentPayload})
    })
    .catch(err => {
        console.log('TOURNAMENT POSITION API ERR', err)
    })
    .finally(() => dispatch(clearProfileLoading()));
}

export const getthisTournamentPosition = (payload) => dispatch => {
    const options = {
        token: localStorage.jwtToken,
        'Content-Type' : 'application/json'
    }

    axios.post(
        `${backendServerURL}admin/getTournamentPositions`,
        payload,
        options
    )
    .then(res => {
        const { currentUserPosition} = res.data
        
        dispatch({ type: GET_THIS_POSITION_ONLY, payload: currentUserPosition})
 
    })
    .catch(err => {
        console.log('TOURNAMENT POSITION API ERR', err)
    })
}

export const getMonthlyTournamentWinners = () => dispatch =>{
    axios.get(   backendServerURL + 'admin/getMonthlyTournamentWinners')
    .then(res=>{
        // debugger
        dispatch({type: GET_MONTHLY_TOURNAMENT_WINNERS, payload:res.data.data})
        // res.data
    }).catch(err=>{
        console.log(err)
    })
}
export const getUserTournaments = () => dispatch =>{
    const options = {
        token: localStorage.jwtToken,
        'Content-Type': 'application/json',
    };
    axios.get( backendServerURL + 'admin/getUserTournaments',options)
    .then(res=>{
        // debugger
        dispatch({type: GET_USER_TOURNAMENTS, payload:res.data.data})
        // res.data
    }).catch(err=>{
        console.log(err)
    })
}


export const createTournamentSeen = (payload) => dispatch =>{
    const options = {
        token: localStorage.jwtToken,
        'Content-Type': 'application/json',
    };
    axios.post( backendServerURL + 'admin/seenTournament',
    payload,
    options)
    .then(res=>{
        console.log("tournament seen created")
        // debugger
        // res.data
    }).catch(err=>{
        console.log(err)
    })
}

export const updateTournamentSeen = (payload) => dispatch =>{
    const options = {
        token: localStorage.jwtToken,
        'Content-Type': 'application/json',
    };
    axios.post( backendServerURL + 'admin/updateSeenTournament',
    payload,
    options)
    .then(res=>{
        console.log("tournament seen updated")
        // debugger
        // res.data
    }).catch(err=>{
        console.log(err)
    })
}


export const getTournamentSeen = (payload) => dispatch =>{
    const options = {
        token: localStorage.jwtToken,
        'Content-Type': 'application/json',
    };

    axios.post( backendServerURL + 'admin/getUserSeenTournament',
    payload,
    options)
    .then(res=>{
        dispatch({type: CREATE_TOURNAMENTS_SEEN, payload:res.data.data})
        // debugger
        // res.data
    }).catch(err=>{
        console.log(err)
    })
}
export const getNotification = (payload) => dispatch =>{
    const options = {
        Authorization: localStorage.jwtToken,
        'Content-Type': 'application/json',
    };

    axios.post( backendServerURL + 'admin/getNotified',
    payload,
    options)
    .then(res=>{
        // debugger
        const notific = res.data.notification ? res.data.notification : null
        if(notific){

            dispatch({type: ADD_NOTIFICATIONS, payload:notific})
        }

        // res.data
    }).catch(err=>{
        console.log(err)
    })
}
export const deleteNotification = (payload) => dispatch =>{
    const options = {
        Authorization: localStorage.jwtToken,
        'Content-Type': 'application/json',
    };
    // debugger
    axios.delete( backendServerURL + 'admin/getNotified',{headers : options ,data : payload})
    .then(res=>{
        // debugger
        const notific = res.data.notificationId ? res.data.notificationId : null
        if(notific){

            dispatch({type: DELETE_NOTIFICATIONS, payload:notific})
        }

        // res.data
    }).catch(err=>{
        console.log(err)
    })
}

export const setOtherTournamentEnded = (payload) =>{
    return { type : OTHER_TOURNAMENT_ENDED, payload: payload };
}

export const setModalTournament = (payload) =>{
    return { type : SET_MODAL_TOURNAMENT, payload: payload };
}

export const tournamentPosSocket = payload =>{
    return {type : ADD_T_POSITIONS, payload : payload}
}
export const doubleTournamentCoins = payload =>{
    return {type : DOUBLE_T_COINS, payload : payload}
}
