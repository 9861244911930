import React, { Component } from 'react';
import $ from 'jquery'
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { getTransactionHistory } from '../../actions/paymentActions';
import moment from 'moment';

 class withdrawHistory extends Component {
  componentDidMount(){
    const{
      props : {
        getTransactionHistoryDispatch,
        user
      }
    } = this;
    // const userId = user._id
    // getTransactionHistoryDispatch({userId})

//     $('#withdraw-money').on('click',"#withdraw-money-btn",()=>{
//       // debugger
//       window.myFunct()
//       window.openModale('#withdraw-amount')
//     })
    $('#close-to-profile2').on('click',()=> {
      window.myFunct()
      window.openModale('#popup-account')
  })
  }
  render() {
    const {
        
        props: {
          user,
          transactions
        },
      } = this;
  
      {/* <!--popup withdraw history--> */}
    return (
            <div id="withdraw-history" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__w600">
              <p>
          <button
            type="button"
            // data-fancybox-close
            id='close-to-profile2'
            className="fancybox-close-small fancybox-button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1"
              viewBox="0 0 24 24"
            >
              <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path>
            </svg>
          </button>
        </p>
            <div className="popup-box">
              <div className="popup-content">
                <h2 className="popup-title popup-title__f45">wallet history</h2>
                <div className="withdraw-history box-highscores__scroll">
                  <table>
                    <tbody>
                      <tr>
                        <th>Date</th>
                        <th>AMOUNT</th>
                        <th>Description</th>
                      </tr>
                    {
                      transactions.map((el,index) => {
                        // debugger
                        return( 
                          <tr key={index}>
                            <td>{moment(el.createdAt).format('DD MMM YYYY')}</td>
                            {el.winning ? <td style={{color : '#5cb85c'}}>+{'$PLY ' + el.winning}</td> : el.amountReq ? <td style={{ color: '#d9534f' }} >-{'$PLY ' + el.amountReq}</td> : <td></td>}
                            <td>{el.type === 'SWEEPSTAKE' ? 'Won a Sweepstake' : el.type === "TOURNAMENT" ? 'Won a Tournament' : "Withdraw Funds"}</td>
                      </tr>
                        )
                      })
                    }
                      {/* <tr>
                        <td>01 Jan 21</td>
                        <td>$3.68</td>
                        <td>won a sweepStake</td>
                      </tr>
                      <tr>
                        <td>31 Dec 20</td>
                        <td>$2.01</td>
                      </tr>
                      <tr>
                        <td>21 Nov 20</td>
                        <td>$7.33</td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
                <a data-fancybox-close className="btn btn__blue btn__icon--r btn-history">okay <i className="icon-checkmark"></i></a>
              </div>
            </div>
          </div>
    );
  }
}
const mapDispatchToProps = (dispatch) =>({
 getTransactionHistoryDispatch: (obj) => {
    dispatch(getTransactionHistory(obj));
  },
})
const mapStateToProps = (state) =>({
  user : state.auth.user ? state.auth.user : {},
  transactions : state.payment.userTransactions ? state.payment.userTransactions : [] 

})
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(withdrawHistory));