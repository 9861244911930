import React, { Component } from 'react';
import $ from 'jquery'
export default class WithdrawalSuccess extends Component {
    componentDidMount(){
        $('#back-to-profile').on('click',()=> {
            window.myFunct()
            window.openModale('#popup-account')
        })
    }
  render() {
    return <div>
        <div id="back-profile" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__w600">
        <p>
          <button
            type="button"
            data-fancybox-close
            className="fancybox-close-small fancybox-button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1"
              viewBox="0 0 24 24"
            >
              <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path>
            </svg>
          </button>
        </p>
          <div className="popup-box">
            <div className="popup-content">
              <div className="popup-text">
                <h2>congratulations</h2>
                <p>YOU’VE SENT A WITHDRAWAL REQUEST</p>
              </div>
              <p className="congratulations-subtitle">Payment fees might be deducted according to your payment
                method You will receive the payment in maximum 7 days
                You will see the amount in “pending” until the delivery
                process will be complete</p>
              <a id="back-to-profile" className="btn btn__blue btn__icon--r btn-back">BACK TO PROFILE <i className="icon-arrow-left"></i></a>
            </div>
          </div>
        </div>
    </div>;
  }
}
