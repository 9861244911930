
import React, { Component } from 'react'
import { connect } from 'react-redux';

import $ from 'jquery';
import { getGames } from '../../actions/sweepStake'
import { getCurrentUser } from '../../actions/authActions'
import { sendScoreUpdate, getGameCoin, updateUserCoins, getUserCoins, doubleUserCoins } from '../../actions/games';
import Header from '../../common/header/header'
import moment from 'moment';

let vPause = false;
let aiptag = window.aiptag || { cmd: [] };

class GameTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gamePause: false,
      soundOn: true,
      score: 0,
      gameOver: false,
      turns: 0
    };



  }

  componentDidMount() {

    const {
      props: {
        user,
        userId,
        location: {
          search,
        },
      }
    } = this;
    this.props.fetchGamesDispatch()
    this.props.fetchCurrentUser()
  }

  loadIframe = (e) => {
    e.preventDefault()
    const {
      state: {
        gameOver,
        gameName
      },
      props: {
        user
      }
    } = this;

    console.log('CALLED loadIframe')
    // setTimeout(callGameStart, 10000);
    setTimeout(function () {
      console.log('callGameStart')
      //document.getElementById("myIframe").contentWindow.gameStart(true, '200')
      // { user && user._id && localStorage.jwtToken && document.getElementById("myIframe").contentWindow.gameStart(true, user._id) }

    }, 8000)

    if (window.addEventListener) {
      window.addEventListener("message", (e) => {
        if (e.data.event_type === 'GameOver') {
          // window.hideCloseKeep()
          // if ((this.state.turns == 0 || this.state.turns < 5) && localStorage.jwtToken) {
          //   window.hideCloseKeep()
          //   // window.openModalKeep()

          // } else if (this.state.turns >= 5 && localStorage.jwtToken) {
          //   // window.openModalDouble()
          //   window.hideCloseKeep()//added this to remove ad limit

          // } 
          // else {
          //   window.hideCloseKeep()
          // }

        } else if (e.data.event_type === 'Score') {
          // if (localStorage.jwtToken) {
          //   const params = new URLSearchParams(window.location.search)
          //   this.setState({ score: e.data.event_data })

          //   const payload = {
          //     gameScore: this.state.score,
          //     gameName: this.state.gameName,
          //     gameId: params.get('g_id')
          //   }

          //   this.props.sendCurrentScore(payload)

          //   const coinPayload = {
          //     gameScore: this.state.score,
          //     gameId: params.get('g_id')
          //   }

          //   this.props.getGameCoin(coinPayload)
          // } else {
          //   this.setState({ score: e.data.event_data })

          //   const coinPayload = {
          //     gameScore: this.state.score,
          //     gameName: this.state.gameName
          //   }

          //   this.props.getGameCoin(coinPayload)
          // }


        }




      }, false);

    }

    else {
      window.attachEvent("onmessage", (e) => {

        this.setState({ score: 0 })

      }
      );
    }
  }
  

  render() {
    const {
      state: {
        gamePause,
        soundOn,
      },
      props: {
        games,
        sweepstake,
        location: {
          search,
        },
      }
    } = this;
    const self = this;
    console.log('gameName', search)
    const gameName = search.split("=").pop()

    return (

      <div>

        <Header />
        {/*<a style={{ display: "none" }} href="" data-fancybox="pause-game-modal" data-src="#pause-game"></a>*/}

          <iframe frameBorder="0" className="game-iframe" height="600" width="600" src={`https://playtoobucket.s3.ap-south-1.amazonaws.com/public_games/games/${gameName}/index.html`} id="myIframe" ></iframe>

        

      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  fetchGamesDispatch: () => {
    dispatch(getGames());
  },
  fetchCurrentUser: () => {
    dispatch(getCurrentUser());
  },
  sendCurrentScore: (payload) => {
    dispatch(sendScoreUpdate(payload));
  },
  getGameCoin: (payload) => {
    dispatch(getGameCoin(payload));
  },
  updateUserCoins: (payload) => {
    dispatch(updateUserCoins(payload));
  },
  getUserCoins: () => {
    dispatch(getUserCoins());
  },
  doubleUserCoins: (payload) => {
    dispatch(doubleUserCoins(payload));
  },


});

const mapStateToProps = state => ({
  games: state.sweepstake.games ? state.sweepstake.games : [],
  user: state.auth ? state.auth.user : null,
  coins: state.game ? state.game.coins : 0,
  userCoins: state.game ? state.game.userCoins : 0,
  sweepstake: state.sweepstake.sweepstakes ? state.sweepstake.sweepstakes.data.sweepstakes : [],
  highscores: state.game.userHighscores ? state.game.userHighscores : [],


});

export default connect(mapStateToProps, mapDispatchToProps)(GameTest);
