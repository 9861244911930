import { SWEEP_STAKE, ALL_SWEEP_STAKE, SET_GAMES, SET_TOURNAMENTS, SET_ALL_TOURNAMENTS, SET_NEXT_TOURNAMENT, SET_NEXT_SWEEPSTAKE, SET_TOURNAMENTS_USER_POS, YOUR_SWEEP_POSITION, SWEEPSTAKE_POSITIONS ,ADD_S_POSITIONS,UPDATE_TOURNAMENTS_IO, GET_MONTHLY_SWEEP_WINNERS} from '../actions/types';

let initialState = {
    tournamentUserPos: [],
    sweepstakeEnded : false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SWEEP_STAKE:
            return {
                ...state,
                sweepstakes: action.payload
			};
        case ALL_SWEEP_STAKE:
            return {
                ...state,
                allSweepstakes: action.payload
			};
        case SET_GAMES:
            return {
                
                ...state,
                games: action.payload
                
                
            };
        case SET_TOURNAMENTS:
            return {
                ...state,
                tournaments: action.payload
            };
        case SET_ALL_TOURNAMENTS:
            return {
                ...state,
                allTournaments: action.payload
            };
        case UPDATE_TOURNAMENTS_IO:
            // debugger
            let newArray = []
            if(Array.isArray(action.payload) === true){

                newArray = action.payload
            }else{

                newArray = [...state.tournaments]
                const index = state.tournaments.findIndex(el => el._id === action.payload._id)
                // debugger
                if(index >= 0){
                    newArray[index] = action.payload
                }else{
                    newArray = [...state.tournaments,action.payload]
                }
            }

            return{
                ...state,
                tournaments : newArray
            };
        
        case SET_NEXT_TOURNAMENT:
            return {
                ...state,
                nextTournamentDate: action.payload
            };

        case SET_NEXT_SWEEPSTAKE:
            return {
                ...state,
                nextSweepstakeDate: action.payload
            };
        
        case SET_TOURNAMENTS_USER_POS:
            return {
                ...state,
                tournamentUserPos: action.payload
            };

        case SWEEPSTAKE_POSITIONS:
            return {
                ...state,
                sweepstakeWinners: action.payload
            };
        case YOUR_SWEEP_POSITION:
            
            return {
                ...state,
                yourSweepPostion: action.payload
            };
        case GET_MONTHLY_SWEEP_WINNERS:
            return {
                ...state,
                monthlySweepPositions: action.payload
            };
        case ADD_S_POSITIONS:
            return{
                ...state,
                sweepstakeEnded : action.payload
            }      
        default:
            return state;
    }
}
