export const INCREMENT = 'INCREMENT';

export const DECREMENT = 'DECREMENT';

export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const PROFILE_LOADED = 'PROFILE_LOADED';
export const SET_USERS = 'SET_USERS';
export const SHOW_NOTICE = 'SHOW_NOTICE';
export const SWEEP_STAKE = 'SWEEP_STAKE'
export const ALL_SWEEP_STAKE = 'ALL_SWEEP_STAKE'
export const TOURNAMENTS = 'TOURNAMENTS'
export const TOURNAENT_POSITIONS = 'TOURNAENT_POSITIONS'
export const YOUR_POSITION = 'YOUR_POSITION'
export const SWEEPSTAKE_POSITIONS = 'SWEEPSTAKE_POSITIONS'
export const YOUR_SWEEP_POSITION = 'YOUR_SWEEP_POSITION'
export const GAME_IMG = 'GAME_IMG'
export const SET_GAMES = 'SET_GAMES';
export const SET_TOURNAMENTS = 'SET_TOURNAMENTS';
export const SET_ALL_TOURNAMENTS = 'SET_ALL_TOURNAMENTS';
export const SET_NEXT_TOURNAMENT = 'SET_NEXT_TOURNAMENT';
export const SET_NEXT_SWEEPSTAKE = 'SET_NEXT_SWEEPSTAKE';
export const SET_TOURNAMENTS_USER_POS = 'SET_TOURNAMENTS_USER_POS';
export const SET_PROFILE_IMG = 'SET_PROFILE_IMG';
export const SET_AUTH = 'SET_AUTH';
export const CHANGE_USER_NICKNAME = 'CHANGE_USER_NICKNAME';
export const ADD_SCORE = 'ADD_SCORE';
export const ADD_TOURNAMENT_SCORE = 'ADD_TOURNAMENT_SCORE';
export const GET_COIN = 'GET_COIN';
export const GET_USERS = 'GET_USERS';

export const GET_USER_COIN = 'GET_USER_COIN';
export const UPDATE_USER_COIN = 'UPDATE_USER_COIN';
export const DOUBLE_USER_COIN = 'DOUBLE_USER_COIN';
export const SET_USER_HIGHSCORES = 'SET_USER_HIGHSCORES';
export const SET_HIGHSCORER_PROFILE = 'SET_HIGHSCORER_PROFILE';
export const SET_WINNINGS = 'SET_WINNINGS';
export const GET_MONTHLY_SWEEP_WINNERS = 'GET_MONTHLY_SWEEP_WINNERS';
export const GET_MONTHLY_TOURNAMENT_WINNERS = 'GET_MONTHLY_TOURNAMENT_WINNERS';
export const GET_USER_TOURNAMENTS = 'GET_USER_TOURNAMENTS';
export const SET_USER_COINS = 'SET_USER_COINS';
export const OTHER_TOURNAMENT_ENDED = 'OTHER_TOURNAMENT_ENDED';
export const SET_USER_MONEY = 'SET_USER_MONEY'
export const GET_THIS_POSITION_ONLY = 'GET_THIS_POSITION_ONLY';
export const CREATE_TOURNAMENTS_SEEN = 'CREATE_TOURNAMENTS_SEEN';
export const SET_LOGIN_USER_HIGHSCORES = 'SET_LOGIN_USER_HIGHSCORES';
export const SET_MODAL_TOURNAMENT = 'SET_MODAL_TOURNAMENT';
export const UPDATE_TOURNAMENTS_IO = 'UPDATE_TOURNAMENTS_IO'
export const ADD_T_POSITIONS = 'ADD_T_POSITIONS'
export const ADD_S_POSITIONS = 'ADD_S_POSITIONS'
export const USER_T_SCORES_UPDATE_IO = 'USER_T_SCORES_UPDATE_IO'
export const DOUBLE_COINS = 'DOUBLE_COINS'
export const ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS'
export const DELETE_NOTIFICATIONS = 'DELETE_NOTIFICATIONS'
export const DOUBLE_T_COINS = 'DOUBLE_T_COINS'
export const PLATFORM_NOT_AVAILABLE = 'PLATFORM_NOT_AVAILABLE'
export const PAYMENT_REQUEST_SUCCESS = 'PAYMENT_REQUEST_SUCCESS'
export const SET_USER_TRANSACTION = 'SET_USER_TRANSACTION'



