import isEmpty from '../validation/is-empty';

import {SET_LOGIN_USER_HIGHSCORES,PLATFORM_NOT_AVAILABLE,ADD_NOTIFICATIONS,DELETE_NOTIFICATIONS,DOUBLE_COINS,USER_T_SCORES_UPDATE_IO, SET_CURRENT_USER,SET_USER_COINS,SET_USER_MONEY, SET_PROFILE_IMG, SHOW_NOTICE, SET_AUTH, CHANGE_USER_NICKNAME } from '../actions/types';

const initialState = {
	isAuthenticated: false,
	user: {},
	showNotice: false,
	alertMessage: null,
	countryAllowed : true,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case SET_CURRENT_USER:
			return {
				...state,
				// isAuthenticated: action.payload,
				user: action.payload
			};
		case SET_PROFILE_IMG:
			return {
				...state,
				// isAuthenticated: {
				// 	...state.isAuthenticated,
				// 	imageUrl: action.payload
				// },
				user: {
					...state.user,
					imageUrl: action.payload
				}
			};
		case DOUBLE_COINS:
			// debugger
			return{
				...state,
				user:{
					...state.user,
					coins : action.payload
				}
			};
		case ADD_NOTIFICATIONS:
			// debugger
			let newNotifications = state.user.notifications
			// debugger
			let notification = newNotifications.find(el => el.tournament === action.payload.tournament)
			if(notification === undefined){
			newNotifications.push(action.payload)
			}
			return{
				...state,
				user:{
					...state.user,
					notifications : newNotifications
				}
			};
		case DELETE_NOTIFICATIONS:
			let notifications = state.user.notifications
			// debugger
			let updatedNotifications = notifications.filter(el => el._id !== action.payload)
			return {
				...state,
				user : {
					...state.user,
					notifications : updatedNotifications
				}
			}



		case SET_USER_COINS:
			return {
				...state,
			user: {
				...state.user,
				coins : action.payload,
			}
					
				};
		case SET_USER_MONEY:
			return{
				...state,
				user:{
					...state.user,
					money : action.payload,
				}
			}
		case SHOW_NOTICE:
			return {
				...state,
				showNotice: action.payload,
				alertMessage: action.message,
			};
			case SET_LOGIN_USER_HIGHSCORES:
                // debugger
                return {
                ...state,
                currentUserHighscores: action.payload
                }
		case SET_AUTH:
			return {
				...state,
				isAuthenticated: action.payload
			};
		case USER_T_SCORES_UPDATE_IO:
			return {
				...state,
				user :{
					...state.user,
					tournamentScores : action.payload
				}
			}
		case PLATFORM_NOT_AVAILABLE:
			return {
				...state,
				countryAllowed : action.payload
			}

		default:
			return state;
	}
}