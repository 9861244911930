import { GET_USERS } from "../actions/types";

const initialState = { users: false };

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_USERS:
            return { users: action.payload }
        default:
            return state;
    }
}