import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import  configureStore,{ history } from './store';
import { Route, Switch } from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router'
import reportWebVitals from './reportWebVitals';
//import 'bootstrap/dist/css/bootstrap.css';

// import { PersistGate } from 'redux-persist/integration/react'
// import store from './store'
// import persistor from './store'
const store = configureStore()
ReactDOM.render(
  // <React.StrictMode>
    <Provider store={store}>
    <ConnectedRouter history={history}>
      <>
      <Switch>
        <Route path="/" component={App} />
      </Switch>
      </>
    </ConnectedRouter>
    {/* <Provider store={store}> */}
      {/* <BrowserRouter > */}
        {/* <PersistGate persistor={persistor}>
          <App />
        </PersistGate> */}
        {/* <App /> */}
      {/* </BrowserRouter> */}
    </Provider>
  //  </React.StrictMode> 
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
