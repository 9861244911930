import React, { Component } from "react";
import { connect } from "react-redux";

export class liveTournament extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user_score: [],
      tournament: [],
      yourScore: false,
    };
  }
  componentDidUpdate(prevProps) {
    const {
      props: { modalTournament, tournaments, positions, youPositions, user },
    } = this;
    if (
      (prevProps.user !== this.props.user && Object.keys(user).length > 0) ||
      (Object.keys(modalTournament).length > 0 &&
      prevProps.modalTournament !== this.props.modalTournament) ||
      (prevProps.tournaments !== this.props.tournaments &&
        this.props.tournaments.length > 0)
        ) {
          //  debugger
          window.liveModalUnslicker('#live-tournament-again2')
          
          this.findTournament(modalTournament);
          const userS = this.getYourPosition(modalTournament);
          this.setState({
            user_score: userS,
          });
          
          //  debugger
    
    }
  }
  getTournamentImg = (tour) => {
    // debugger
    let image = tour.game
      ? tour.game[0].icons[0].imageURL
      : "assets/images/playtoo.png";
    return image;
  };
  getYourPosition = (tour) => {
    // debugger
    const user = this.props.youPositions.find(
      (el) => el.tournamentId === tour._id
    );
    return user;
  };
  findTournament = (tour) => {
    let tournament = this.props.tournaments;
    // debugger
    tournament = tournament.find((el) => el._id === tour._id);
    if (tournament !== undefined) {
      this.setState({
        tournament: tournament,
        yourScore: tour.you_scored,
      },()=>{

        window.liveModalSlicker('#live-tournament-again2')
      });
    }
  };

  render() {
    const {
      props: { modalTournament, user },
      state: { user_score, tournament },
    } = this;

    const prizes = [
      tournament.firstPrize,
      tournament.secondPrize,
      tournament.thirdPrize,
      tournament.fourthPrize,
      tournament.fifthPrize,
      tournament.sixthPrize,
      tournament.seventhPrize,
      tournament.eighthPrize,
      tournament.ninthPrize,
      tournament.tenthPrize,
    ];
    const positions = tournament.topScorers ? tournament.topScorers : [];
    // const yourScore = this.getYourPosition(modalTournament && modalTournament)

    return (
      <div
        id="live-tournament-again2"
        style={{ display: "none" }}
        className="popup-style popup-style__text-center popup-style__pt popup-style__w600"
      >
        <p>
          <button
            type="button"
            data-fancybox-close
            className="fancybox-close-small fancybox-button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1"
              viewBox="0 0 24 24"
            >
              <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path>
            </svg>
          </button>
        </p>
        <div className="popup-box">
          <div className="popup-top">
            <div className="popup-top__img popup-top__img--picture">
              <img src={this.getTournamentImg(tournament)} alt="" />
            </div>
          </div>
          <div className="popup-content popup-content__pr live-tournament">
            <div className="live-icon">
              <img src="images/icons/live-icon.png" alt="" />
            </div>
            <h2 className="popup-title popup-title__f45">LIVE TOURNAMENT</h2>
            <div className="popup-timer popup-timer__mb25">
              <h5>Ends in:</h5>
              <div className="timer">
                <div className="timer-box">
                  <div className="timer-num days">
                    <span>0</span>
                    <span>0</span>
                  </div>
                  <p>Days</p>
                </div>
                <div className="timer-box">
                  <div className="timer-num hours">
                    <span>0</span>
                    <span>0</span>
                  </div>
                  <p>Hours</p>
                </div>
                <div className="timer-box">
                  <div className="timer-num minutes">
                    <span>0</span>
                    <span>0</span>
                  </div>
                  <p>Minutes</p>
                </div>
                <div className="timer-box">
                  <div className="seconds"></div>
                </div>
              </div>
            </div>
            <h3 className="popup-subtitle">$PLY REWARD UP TO 100th PLACE </h3>
            <div className="slider-place style-slider">
              {prizes.map((el, index) => {
                if (el !== undefined && el > 0) {
                  let pos = index + 1;
                  const prize =
                    pos === 1
                      ? "1st"
                      : pos === 2
                      ? "2nd"
                      : pos === 3
                      ? "3rd"
                      : pos === 4
                      ? "4th-10th"
                      : pos === 5
                      ? "11th-20th"
                      : pos === 6
                      ? "21th-50th"
                      : pos === 7
                      ? "51th-100th"
                      : pos === 8
                      ? "101th-200th"
                      : pos === 9
                      ? "201th-500th"
                      : pos === 10
                      ? "501th-1000th"
                      : "";

                  return (
                    <div className="slider-place__col">
                      <div className="slider-place__item">
                        <div className="slider-place__t">{prize}</div>
                        <div className="slider-place__img">
                          <img src="images/icons/cash-icon.png" alt="" />
                        </div>
                        <div className="slider-place__money">
                          <span>{el}</span>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
            {this.state.yourScore >= 0 ? (
              <p className="tournament-scored">
                You scored{" "}<span className="green">{this.state.yourScore}pt</span>
              </p>
            ) : ''}
            <div className="slider-users style-slider">
              <div className="slider-users__col">
                <div className="user-item user-item__my">
                  <div className="user-item__photo">
                    <img
                      src={
                        user.imageUrl
                          ? user.imageUrl
                          : "assets/images/games/defaultProfile.jpg"
                      }
                      alt=""
                    />
                    <div className="user-item__label">
                      {user_score ? user_score.position : 0}p
                    </div>
                  </div>
                  <h3 className="user-item__name">You</h3>
                  <div className="user-item__points">
                    {user_score ? user_score.gameScore : "//"}pts
                  </div>
                </div>
              </div>
              {/* {console.log("tournament and pos",tournament, positions)} */}
              {positions.map((el, index) => {
                // debugger
                if ((el.user && el.user._id) !== (user && user._id )) {
                  return (
                    <div key={index} className="slider-users__col">
                      <div className="user-item">
                        <div className="user-item__photo">
                          <img
                            src={
                              (el.user && el.user.imageUrl)
                                ? el.user.imageUrl
                                : "assets/images/games/defaultProfile.jpg"
                            }
                            alt=""
                          />
                          <div className="user-item__label">{el.position}p</div>
                        </div>
                        <h3 className="user-item__name">
                          {el.user ? el.user.name : "User"}
                        </h3>
                        <div className="user-item__points">
                          {el.gameScore}pts
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
            {this.state.yourScore >= 0 ? (
              <a
                id="modal-play2"
                data-fancybox-close
                className="btn btn__blue btn__icon--r btn-play-again"
              >
                PLAY AGAIN <i className="icon-play"></i>
              </a>
            ) : (
              <a
                id="modal-play"
                // data-fancybox-close
                className="btn btn__blue btn__icon--r btn-play"
              >
                Play <i className="icon-play"></i>
              </a>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({});
const mapStateToProps = (state) => ({
  user: state.auth ? state.auth.user : null,
  modalTournament: state.tournament.tournamentModal
    ? state.tournament.tournamentModal
    : {},
  youPositions: state.auth.user.tournamentScores
    ? state.auth.user.tournamentScores
    : [],
  // positions : state.tournament.tournamentModal ? state.tournament.tournamentModal.topScorers : [],
  tournaments: state.sweepstake.tournaments ? state.sweepstake.tournaments : [],
  // you: state.tournament.yourPosition ? state.tournament.yourPosition : {},
});
export default connect(mapStateToProps, mapDispatchToProps)(liveTournament);
