import { ADD_SCORE,SET_LOGIN_USER_HIGHSCORES,SET_HIGHSCORER_PROFILE ,GET_COIN, GET_USER_COIN, UPDATE_USER_COIN, DOUBLE_USER_COIN, ADD_TOURNAMENT_SCORE, SET_USER_HIGHSCORES, SET_WINNINGS } from "../actions/types";

const initialState = {
    score: {},
    coins: 0,
    userCoins: 0,
    tounamentScore: 0,
    userHighscores: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_SCORE:
            return {
                ...state,
                score: action.payload
            }

        case GET_COIN:
            return {
                ...state,
                coins: action.payload
            }

        case GET_USER_COIN:
            return {
                ...state,
                userCoins: action.payload
            }

        case UPDATE_USER_COIN:
            return {
                ...state,
                userCoins: action.payload
            }
        case DOUBLE_USER_COIN:
            return {
                ...state,
                userCoins: action.payload
            }

        case ADD_TOURNAMENT_SCORE:
            return {
                ...state,
                tournamentScore: action.payload
            }
        case SET_USER_HIGHSCORES:
            return {
                ...state,
                userHighscores: action.payload
            }
        case SET_HIGHSCORER_PROFILE:
                return {
                    ...state,
                    highscorer : action.payload
                }

        case SET_WINNINGS:
            return {
                ...state,
                winnings: action.payload
            }
        default:
            return state
    }
}