/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import $ from 'jquery'
export default class WithdrawMoney extends Component {
  componentDidMount() {
    $('#withdraw-money').on('click', "#withdraw-money-btn", () => {
      // debugger
      window.myFunct()
      window.openModale('#withdraw-amount')
    })
    $('#close-to-profile').on('click', () => {
      window.myFunct()
      window.openModale('#popup-account')
    })
  }

  render() {
    const { props: { user } } = this;

    return (
      <>
        <div id="withdraw-money" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__w600">
          <p>
            <button
              type="button"
              id='close-to-profile'
              className="fancybox-close-small fancybox-button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1"
                viewBox="0 0 24 24"
              >
                <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path>
              </svg>
            </button>
          </p>
          <div className="popup-box">
            <div className="popup-content withdraw-money">
              <div className="withdraw-img line-effect">
                <img src="images/icons/money-icon.png" alt="" />
              </div>
              <h2>withdraw money</h2>
              <h3>Reach $PLY 2 to withdraw money</h3>
              <div className="withdraw-balance">
                <h5>My Balance</h5>
                <p>$PLY {user.money}</p>
              </div>
              {user.money > 2 ?
                <a id="withdraw-money-btn" className="btn btn__gradient btn-withdraw">WITHDRAW</a>
                :
                <a data-fancybox-close className="btn btn__gray">NOT ENOUGH MONEY</a>
              }
            </div>
          </div>
        </div>
      </>
    );
  }
}
