import {
  ADD_T_POSITIONS,
  TOURNAMENTS,
  DOUBLE_T_COINS,
  GET_THIS_POSITION_ONLY,
  TOURNAENT_POSITIONS,
  YOUR_POSITION,
  GAME_IMG,
  GET_MONTHLY_TOURNAMENT_WINNERS,
  GET_USER_TOURNAMENTS,
  OTHER_TOURNAMENT_ENDED,
  CREATE_TOURNAMENTS_SEEN,
  SET_MODAL_TOURNAMENT,
} from "../actions/types";

let initialState = {
  userPositions: [],
  yourPosition: {},
  playingTposition: false,
  tournamentPayload: {},
  tournamentEnded: null,
  otherTournamentEnded: false,
  doubleCoins: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOURNAMENTS:
      return {
        ...state,
      };
    case TOURNAENT_POSITIONS:
      return {
        ...state,
        userPositions: action.payload,
      };

    case YOUR_POSITION:
      return {
        ...state,
        yourPosition: action.payload,
      };
    case GET_THIS_POSITION_ONLY:
      return {
        ...state,
        playingTposition: action.payload,
      };
    case GAME_IMG:
      return {
        ...state,
        tournamentPayload: action.payload,
      };
    case GET_MONTHLY_TOURNAMENT_WINNERS:
      return {
        ...state,
        monthlytournamentpositions: action.payload,
      };
    case GET_USER_TOURNAMENTS:
      return {
        ...state,
        userTournaments: action.payload,
      };
    case OTHER_TOURNAMENT_ENDED:
      return {
        ...state,
        otherTournamentEnded: action.payload,
      };
    case CREATE_TOURNAMENTS_SEEN:
      return {
        ...state,
        tournamentSeen: action.payload,
      };
    case SET_MODAL_TOURNAMENT:
      return {
        ...state,
        tournamentModal: action.payload,
      };
    case ADD_T_POSITIONS:
      return {
        ...state,
        tournamentEnded: action.payload,
      };
    case DOUBLE_T_COINS:
      return {
        ...state,
        doubleCoins: action.payload,
      };
    default:
      return state;
  }
}
