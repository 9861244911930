/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-lone-blocks */
import React, { Component } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { getGames } from "../../actions/sweepStake";
import { getCurrentUser } from "../../actions/authActions";
import {
  sendScoreUpdate,
  getGameCoin,
  updateUserCoins,
  getUserCoins,
  doubleUserCoins,
  sendTournamentScoreUpdate,
} from "../../actions/games";
import moment from "moment-timezone";
import customHelper from "../../helpers/customHelper";
import {
  getTournamentPositions,
  getthisTournamentPosition,
} from "../../actions";
import { createTournamentSeen } from "../../actions/tournaments";
import { Link } from "react-router-dom";
import socket from '../../helpers/socket'
import { coinsAnimation } from "../../helpers/animations";

let vPause = false;
let aiptag = window.aiptag || { cmd: [] };

class Tournament extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gameName: "",
      gamePause: false,
      soundOn: true,
      score: 0,
      gameOver: false,
      finishDate: 0,
      gCapital: "",
      turns: 0,
      adtype: "",
      gameStartTime: false,
      path: "/",
      routeChange: false,
      bonusCoins: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.tournaments !== prevProps.tournaments && this.props.tournaments.length > 0) {
      const params = new URLSearchParams(window.location.search);
      const trn_id = params.get("t_id");
      if (trn_id) {
        const tournament = this.props.tournaments.find(el => el._id === trn_id)
        const current = moment(new Date())
        const tFinish = moment(tournament.finishDate)
        if (current.isAfter(tFinish) && !this.props.tournamentEnded) {
          this.props.history.push('/?', { redirec: true })
        }
      }
    }
  }

  componentDidMount() {
    const { props: { location: { search } } } = this;
    let currentTime = moment();
    const params = new URLSearchParams(window.location.search);
    this.props.fetchGamesDispatch();
    this.props.fetchCurrentUser();
    if (!localStorage.jwtToken) {
      window.openModaleNoCLose2("#lucky-coins");
    }
    this.props.history.block((targetLocation) => {
      if (targetLocation.hash === '#account') {
        this.setState({ path: targetLocation.hash })
      }
      if (targetLocation.state && targetLocation.state.redirec === true) {
        return true
      }
      if (targetLocation.search === "?live-tournament-modal") {
        this.setState({ routeChange: true });
        return this.state.routeChange;
      }
      if (targetLocation.query === "g_status") {
        this.setState({ routeChange: true });
        return this.state.routeChange;
      }
      if (this.state.routeChange === false) {
        this.setState({ gamePause: true });
        document.getElementById("myIframe").contentWindow.sound(false);
        document.getElementById("myIframe").contentWindow.pause(true);
        window.openModale("#confirm-exit");
      }
      this.setState({ path: targetLocation.pathname + targetLocation.hash });
      return this.state.routeChange;
    });
    $("#tournament-win2").on("click", "#double-itcoins", this.doubleBonusCoins);
    $("#closeModal2").click(this.closeModal);
    $("#pause-modal-close").click(this.handlePauseClose);
    $("#play-again").click(this.game_start);
    $("#reward").on('click', '#reward-btn', (e) => {
      this.updateUserCoins();
      const target = document.querySelector('#tournament-normal-coin')
      coinsAnimation(target, this.props.user?.coins, this.props.coins, "TOURNAMENT")
    });
    $("#livemodal-redirection").on("click", this.updateUserCoins);
    $("#watch-ad").click(this.watchRewardAd);
    $(".closethismodal").on("click", () => {
      window.myFunct();
    });
    $("#yesConfirm1").on("click", () => {
      this.setState({ routeChange: true });
    });
    $("#resume-game02").on("click", () => {
      this.setState({
        gamePause: false,
      });
      document.getElementById("myIframe").contentWindow.sound(true);
      document.getElementById("myIframe").contentWindow.pause(false);
    });
    if (search && search !== "") {
      this.setState({
        gameName: params.get("gamename"),
        gameStartTime: currentTime,
      });
    }

    aiptag.cmd.player = aiptag.cmd.player || [];
    aiptag.cmd.player.push(() => {
      aiptag.adplayer = new window.aipPlayer({
        AD_WIDTH: 960,
        AD_HEIGHT: 540,
        AD_DISPLAY: "fullscreen", //default, fullscreen, center, fill
        LOADING_TEXT: "loading advertisement",
        PREROLL_ELEM: function () {
          return document.getElementById("preroll");
        },
        AIP_COMPLETE: (evt) => {
          if (this.state.bonusCoins) {
            const payload = { coins: 40 };
            const target = document.querySelector('#double-itcoins')
            coinsAnimation(target, this.props.user?.coins, 80, "TOUR_DOUBLE", this.props.router)
            this.props.doubleUserCoins(payload);
            this.gameResumeFunct()
          }
          if (this.state.adtype === "redirect-double") {
            if (this.props.tournamentEnded === null) {
              const target = document.querySelector('#tournament-normal-coin')
              const totalCoins = this.props.coins * 2
              coinsAnimation(target, this.props.user?.coins, totalCoins, "TOURNAMENT_DOUBLE", this.props.history)
            } else {
              window.location = '/'
            }
          }
          if (this.state.adtype === "continue") {
            window.myFunct();
            document.getElementById("myIframe").contentWindow.adComplete();
            document.getElementById("myIframe").contentWindow.sound(true);
          }
        },
        AIP_REMOVE: function () { },
      });
    });
  }

  doubleBonusCoins = () => {
    this.setState({ bonusCoins: true })
    if (typeof aiptag.adplayer !== undefined) {
      aiptag.cmd.player.push(function () {
        aiptag.adplayer.startPreRoll();
      });
    } else {
      const payload = { coins: 40 };
      this.props.doubleUserCoins(payload);
    }
  }

  gameResumeFunct = () => {
    const tournamentId = this.props.your_Position ? this.props.your_Position.tournamentId : ''
    socket.emit("CLAIMED", { tournamentId: tournamentId })
    if (window.location.pathname === '/gameplay' || window.location.pathname === '/tournament') {
      document.getElementById("myIframe").contentWindow.sound(true);
      document.getElementById("myIframe").contentWindow.pause(false);
    }
  }

  getTournamentPos = () => {
    const { props: { user_t_positions } } = this;
    const params = new URLSearchParams(window.location.search);
    let trn_id = params.get("t_id");
    const tourId = trn_id
    const tourObj = user_t_positions.find(el => (el.tournamentId === tourId))
    if (tourObj) {
      let currPos = tourObj.position
      if (currPos > 0) {
        if (currPos === 1) {
          return currPos + "st";
        } else if (currPos === 2) {
          return currPos + "nd";
        } else if (currPos === 3) {
          return currPos + "rd";
        } else {
          return currPos + "th";
        }
      }
    } else {
      return "//";
    }
  }

  pauseFunc = () => {
    if (this.state.gamePause === false) {
      const url_string = window.location.href;
      const url = new URL(url_string);
      const t_id = url.searchParams.get("t_id");
      if (t_id) {
        this.props.getthisTournamentPositionDispatch({ tournamentId: t_id });
      }
      this.setState((prevState) => ({
        gamePause: !prevState.gamePause,
      }));
      vPause = !vPause;
      document.getElementById("myIframe").contentWindow.postMessage("pause-" + vPause, "*");
      window.openModaleNoCLose("#pause-game");
      document.getElementById("myIframe").contentWindow.pause(true);
      document.getElementById("myIframe").contentWindow.sound(false);
    }
  };

  resumeGame = () => {
    this.setState({ gamePause: false });
    document.getElementById("myIframe").contentWindow.pause(false);
    document.getElementById("myIframe").contentWindow.sound(true);
    window.hideClose();
  };

  gameEnd = () => {
    window.location = "/";
  };

  handlePauseClose = (event) => {
    event.preventDefault();
    this.resumeGame();
  };

  handleSound = (bool) => {
    this.setState({
      soundOn: bool,
    });
    document.getElementById("myIframe").contentWindow.sound(bool);
  };

  loadIframe = (e) => {
    e.preventDefault();
    const { props: { games } } = this;
    const params = new URLSearchParams(window.location.search);
    const currentGameId = params.get("g_id");

    const currentGame = games && games.filter((el) => el._id === currentGameId);
    let currGame = "";

    currentGame.forEach((el) => {
      currGame = el;
    });

    if (window.addEventListener) {
      window.addEventListener(
        "message",
        (e) => {
          if (e.data.event_type === "GameEnd") {
            document.getElementById("myIframe").contentWindow.sound(false);
            window.openModaleNoCLose("#reward");
          }

          let currentTime = moment();
          const timePlayed = moment.duration(currentTime.diff(this.state.gameStartTime)).asSeconds();
          if (e.data.event_type === "GameOver") {
            document.getElementById("myIframe").contentWindow.sound(false);
            const payload = {
              gameScore: this.state.score,
              gameName: this.state.gameName,
              gameId: params.get("g_id"),
              tournamentId: params.get("t_id"),
              userTcoins: Math.round(this.state.score * currGame.coins),
            };
            const tournament = { tournamentId: params.get("t_id") };
            this.props.sendTournamentCurrentScore(payload);
            this.props.createTournamentSeenDispatch(tournament);
            if (localStorage.jwtToken) {
              window.openModaleNoCLose('#reward')
            } else {
              window.location = "/#oops";
            }
          } else if (e.data.event_type === "Score") {
            if (localStorage.jwtToken) {
              const params = new URLSearchParams(window.location.search);
              this.setState({ score: e.data.event_data });
              const payload = {
                gameScore: this.state.score,
                gameName: this.state.gameName,
                gameId: params.get("g_id"),
                tournamentId: params.get("t_id"),
                userTcoins: Math.round(this.state.score * currGame.coins),
                timePlayed: timePlayed,
              };
              this.props.sendTournamentCurrentScore(payload);
              const coinPayload = { gameScore: this.state.score, gameId: params.get("g_id") };
              this.props.getGameCoin(coinPayload);
            } else {
              this.setState({ score: e.data.event_data });
              const coinPayload = { gameScore: this.state.score, gameId: params.get("g_id") };
              this.props.getGameCoin(coinPayload);
            }
          } else if (e.data.event_type === 'GameLoaded') {
            this.setState({ score: 0 });
            if (localStorage.jwtToken) {
              const payload = {
                gameScore: 0,
                gameName: this.state.gameName,
                gameId: params.get("g_id"),
                tournamentId: params.get("t_id"),
                userTcoins: 0,
                timePlayed: timePlayed,
              };
              this.props.sendTournamentCurrentScore(payload);
            }
          }
        },
        false
      );
    } else {
      window.attachEvent("onmessage", (e) => {
        this.setState({ score: 0 });
      });
    }
  };

  game_start = (e) => {
    e.preventDefault();
    const { props: { user } } = this;
    {
      user && user._id ?
        document.getElementById("myIframe").contentWindow.gameStart(true, user._id)
        : document.getElementById("myIframe").contentWindow.gameStart(true, "101");
    }
  };

  watchRewardAd = (e) => {
    e.preventDefault();
    if (typeof aiptag.adplayer !== undefined) {
      document.getElementById("myIframe").contentWindow.sound(false);
      aiptag.cmd.player.push(function () {
        aiptag.adplayer.startPreRoll();
      });
    }
    this.setState({
      turns: this.state.turns + 1,
      adtype: "continue",
    });
  };

  updateUserCoins = () => {
    const params = new URLSearchParams(window.location.search);
    const tournamentId = params.get("t_id");
    const tournamentEndedId = this.props.your_Position ? this.props.your_Position.tournamentId : ''
    const payload = { coins: this.props.coins };
    if (tournamentEndedId === tournamentId) {
      setTimeout(() => {
        this.props.updateUserCoins(payload);
        this.props.getUserCoins();
        return window.location = '/'
      }, 4000)
    }

    this.props.updateUserCoins(payload);
    this.props.getUserCoins();
    localStorage.setItem('tempGameImg', this.getGameImg(customHelper.toTitleCase(this.state.gameName)));
    this.setTempState(customHelper.toTitleCase(this.state.gameName));
    if (tournamentEndedId !== tournamentId) {
      setTimeout(() => {
        this.props.history.push("/?live-tournament-modal");
      }, 4000)
    }
  };

  getGameImg = (gameName) => {
    const { props: { games } } = this;
    const params = new URLSearchParams(window.location.search);
    const gameId = params.get("g_id");
    const gameObj = games.find((el) => el._id === gameId);
    return gameObj.icons[0].imageURL;
  };

  setTempState = (gameName) => {
    const { props: { games } } = this;
    const params = new URLSearchParams(window.location.search);
    let trn_id = params.get("t_id");
    let gameId = params.get("g_id");
    let findGame = games && games.filter((el) => el._id === gameId);

    let tournaments = [];
    findGame.forEach((el) => {
      el.tournaments.forEach((child) => {
        tournaments.push(child);
      });
    });
    let presenttournament = tournaments.filter((el) => el._id === trn_id);
    const str = {
      currentTournament: presenttournament[0],
      trn_endTime: presenttournament[0].finishDate,
      tournamentId: presenttournament[0]._id,
      gameName,
      games,
      gameId,
      gameScore: this.state.score,
    };
    const enc = window.btoa(JSON.stringify(str));
    localStorage.setItem("tempGameObject", enc);
  };

  exitGame = () => {
    this.props.history.push("/");
  };

  closeModal = () => {
    window.myFunct();
  };

  render() {
    const {
      state: { gameName, gamePause, soundOn },
      props: { games, coins },
    } = this;

    const params = new URLSearchParams(window.location.search);
    // debugger
    let trn_id = params.get("t_id");
    let gameId = params.get("g_id");
    let findGame = games && games.filter((el) => el._id === gameId);

    let tournaments = [];
    findGame.forEach((el) => {
      el.tournaments.forEach((child) => {
        tournaments.push(child);
      });
    });

    let presenttournament = tournaments.filter((el) => el._id === trn_id);

    let finishDate = 0;
    presenttournament.forEach((el) => { finishDate = el.finishDate; });

    const self = this;
    const gameObj = games.find((obj) => obj.name.replace(/ /g, "_").toLocaleLowerCase() === gameName);
    const gameIcon = gameObj ? gameObj.icons[0].imageURL : "";

    return (
      <div>
        <div id="preroll"></div>
        <div className="game-header">
          <button onClick={this.exitGame} className="game-btn game-btn__close">
            <i className="icon-clear"></i>
          </button>
          {gamePause ? (
            <>
              <p className="game-score text-center">PAUSED</p>
            </>
          ) : (
            <>
              <p className="game-score text-center">
                SCORE:{" "}<span>{this.state.score && this.state.score !== 0 ? this.state.score : "0"}pt</span>
              </p>
            </>
          )}
          <div className="popup-timer popup-timer__mb25">
            <h5 style={{ marginTop: "2vh" }}>Ends in:</h5>
            <div id="tournament_Game" className="timer mr_trn">
              {window.liveTmodalTimer(finishDate, "#tournament_Game")}
              <div className="timer-box">
                <div className="timer-num days">
                  <span>0</span>
                  <span>0</span>
                </div>
                <p>Days</p>
              </div>
              <div className="timer-box">
                <div className="timer-num hours">
                  <span>0</span>
                  <span>0</span>
                </div>
                <p>Hours</p>
              </div>
              <div className="timer-box">
                <div className="timer-num minutes">
                  <span>0</span>
                  <span>0</span>
                </div>
                <p>Minutes</p>
              </div>
              <div className="timer-box">
                <div className="seconds"></div>
              </div>
            </div>
          </div>
          {
            <button
              id="game-pause-btn"
              onClick={this.pauseFunc}
              className="game-btn game-btn__pause"
            >
              <i className={`${gamePause ? "icon-play" : "icon-pause"}`}></i>
            </button>
          }
          <br />
          {soundOn && (
            <div
              onClick={() => this.handleSound(false)}
              className="game-btn game-btn__volume"
            >
              <i className="icon-volume"></i>
            </div>
          )}
          {!soundOn && (
            <div
              onClick={() => this.handleSound(true)}
              className="game-btn game-btn__volume"
            >
              <i className="icon-volume muted"></i>
            </div>
          )}
        </div>
        <div className="game-content">
          <iframe
            frameBorder="0"
            className="game-iframe"
            height="600"
            width="600"
            src={process.env.PUBLIC_URL + `/assets/games/${gameName}/index.html`}
            id="myIframe"
            onLoad={(e) => this.loadIframe(e)}
          ></iframe>
        </div>

        <div
          id="pause-game"
          style={{ display: "none" }}
          className="popup-style popup-style__text-center popup-style__pt popup-style__w600"
        >
          <div className="popup-box">
            <div className="popup-top">
              <div className="popup-top__img popup-top__img--picture">
                <img src={gameIcon} alt="" />
              </div>
            </div>
            <div className="popup-content current-info">
              <p className="current-score">
                Current Score:{" "}<span>{this.state.score && this.state.score !== 0 ? this.state.score : "0"}pt</span>
              </p>
              {localStorage.jwtToken ? (
                <p className="current-position">
                  CURRENT POSITION: <span>{this.getTournamentPos()}</span>
                </p>
              ) : (
                <><br /><br /><br /></>
              )}
              <a
                id="pause-modal-close"
                onClick={(e) => self.handlePauseClose(e)}
                className="btn btn__blue btn-continue closethismodal"
              >
                CONTINUE
              </a>
            </div>
          </div>
        </div>

        {/*popup-reward*/}
        <div
          id="reward"
          style={{ display: "none" }}
          className="popup-style popup-style__text-center popup-style__pt popup-style__w600"
        >
          <div className="popup-box">
            <div className="popup-top">
              <div className="popup-top__img popup-top__img--picture">
                <img src={gameIcon} alt="" />
              </div>
            </div>
            <div className="popup-content">
              <div className="current-info">
                <p className="reward-info">
                  REWARD: {Math.round(this.state.score * findGame[0]?.coins)}{" "}
                  <img id="tournament-normal-coin" src="images/icons/coin-no-shadow-icon.png" alt="" />
                </p>
              </div>
              <p className="popup-thank mt20">
                <a id="reward-btn">CLAIM</a>
              </p>
            </div>
          </div>
        </div>
        {/*popup-reward*/}

        {/*luccky-coins*/}
        <div
          id="lucky-coins"
          style={{ display: "none" }}
          className="popup-style popup-style__text-center popup-style__pt popup-style__w600"
        >
          <div className="popup-box">
            <div className="popup-top">
              <div className="popup-top__img popup-top__img--picture">
                <img src={gameIcon} alt="" />
              </div>
            </div>
            <div className="popup-content ">
              <br />
              <button
                id="play-again"
                onClick={(e) => this.game_start(e)}
                className="btn btn__blue btn__icon--r btn-play"
              >
                PLAY <i className="icon-play"></i>
              </button>
              {/*<p className="highscore">Your Highscore: <span>3645pts</span> </p>*/}
            </div>
          </div>
        </div>
        {/*luccky-coins*/}

        {/*popup-lucky coins*/}
        <div
          id="confirm-exit"
          style={{ display: "none" }}
          className="popup-style popup-style__text-center popup-style__w600"
        >
          <div className="popup-box">
            <div className="popup-content">
              <h2 className="popup-title popup-title__f45">QUIT GAME NOW?</h2>
              <br />
              <h3 className="popup-title popup-title__f22">
                IF YOU LEAVE THE GAME NOW<br /> YOU WILL LOSE ALL YOUR CURRENT PROGRESS
              </h3>
              <br />
              <Link to={this.state.path}>
                <a
                  id="yesConfirm1"
                  className="btn btn__blue btn__icon--r btn-history"
                >
                  EXIT NOW
                </a>
              </Link>
              <br />
              <br />
              <p className="popup-thank" id="resume-game02">
                <a data-fancybox-close>BACK TO THE GAME</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchGamesDispatch: () => {
    dispatch(getGames());
  },
  fetchCurrentUser: () => {
    dispatch(getCurrentUser());
  },
  sendCurrentScore: (payload) => {
    dispatch(sendScoreUpdate(payload));
  },
  sendTournamentCurrentScore: (payload) => {
    dispatch(sendTournamentScoreUpdate(payload));
  },
  getGameCoin: (payload) => {
    dispatch(getGameCoin(payload));
  },
  updateUserCoins: (payload) => {
    dispatch(updateUserCoins(payload));
  },
  getUserCoins: () => {
    dispatch(getUserCoins());
  },
  doubleUserCoins: (payload) => {
    dispatch(doubleUserCoins(payload));
  },
  getTournamentPositions: (payload) => {
    dispatch(getTournamentPositions(payload));
  },
  getthisTournamentPositionDispatch: (payload) => {
    dispatch(getthisTournamentPosition(payload));
  },
  createTournamentSeenDispatch: (payload) => {
    dispatch(createTournamentSeen(payload));
  },
});

const mapStateToProps = (state) => ({
  // router : state.router.location ? state.router.location : [],
  tournaments: state.sweepstake.tournaments ? state.sweepstake.tournaments : [],
  user_t_positions: state.auth.user.tournamentScores ? state.auth.user.tournamentScores : [],
  games: state.sweepstake.games ? state.sweepstake.games : [],
  user: state.auth ? state.auth.user : null,
  coins: state.game ? state.game.coins : 0,
  userCoins: state.game ? state.game.userCoins : 0,
  tournamentEnded: state.tournament.tournamentEnded ? state.tournament.tournamentEnded : null,
  you_position: state.tournament.yourPosition ? state.tournament.yourPosition.pos : 0,
  your_t_position: state.tournament.playingTposition ? state.tournament.playingTposition.pos : 0,
  otherTournamentEnd: state.tournament.otherTournamentEnded ? state.tournament.otherTournamentEnded : false,
  your_Position: state.tournament.tournamentEnded?.userPosition ? state.tournament.tournamentEnded?.userPosition : {},
});

export default connect(mapStateToProps, mapDispatchToProps)(Tournament);
