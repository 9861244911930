import React, { Component } from "react";

export default class tournamentRules extends Component {
  render() {
    return (
      <div className="extra_page_div">
        <h1 className="other-heading">Tournament Rules</h1>
        <div className="p-content">
        <p>
          Caramels pie donut fruitcake liquorice chocolate bar chocolate bar
          biscuit. Dragée cheesecake cake wafer cheesecake candy canes caramels
          dragée oat cake. Caramels candy canes jelly apple pie chocolate cake
          bonbon. Danish candy canes ice cream chocolate cake pastry jelly.
          Topping donut soufflé pastry wafer jelly. Apple pie cookie ice cream
          fruitcake pastry. Pastry lemon drops jelly-o jelly sweet roll bonbon
          tart pudding. Pastry chocolate bar chocolate bar sesame snaps lollipop
          danish. Caramels biscuit topping jelly-o liquorice oat cake cotton
          candy. Pie macaroon croissant tootsie roll bonbon cotton candy.
          Cheesecake chupa chups cotton candy biscuit croissant. Muffin pudding
          dragée jelly cookie sweet roll sugar plum. Muffin gummi bears lollipop
          carrot cake donut cupcake biscuit sweet muffin.
          Pastry cheesecake donut tart gummi bears. Oat cake muffin powder
          toffee fruitcake carrot cake croissant chocolate cake. Gingerbread
          carrot cake toffee chocolate bar powder pie gummi bears gummi bears.
          Sugar plum pie cake icing candy canes carrot cake. Soufflé cookie
          jelly powder sesame snaps lollipop chocolate powder jelly. Sesame
          snaps jelly-o danish brownie marzipan croissant brownie. Jelly brownie
          chupa chups liquorice macaroon marshmallow. Donut jelly-o dragée bear
          claw oat cake bear claw. Bonbon chocolate bar jelly sweet gummies.
          Icing croissant sweet jelly beans cookie jelly-o caramels jelly-o pie.
          Apple pie oat cake lollipop oat cake candy fruitcake. Lollipop tart
          gingerbread apple pie gummi bears pastry liquorice. Pastry apple pie
          biscuit liquorice toffee cheesecake gummies. Jelly sesame snaps bear
          claw tootsie roll bonbon.
        </p>
        <p>
          Lollipop candy biscuit tart jelly jelly-o halvah powder. Apple pie
          pastry liquorice brownie ice cream oat cake powder shortbread pudding.
          Marzipan ice cream donut donut fruitcake. Donut muffin soufflé jelly
          beans jelly-o pastry pudding lollipop apple pie. Croissant tootsie
          roll apple pie croissant chupa chups apple pie chupa chups. Chupa
          chups danish gingerbread sweet roll marshmallow halvah cotton candy
          apple pie jelly. Tart apple pie chocolate bar ice cream gingerbread
          chocolate tart. Toffee dessert cookie dessert lollipop danish. Wafer
          brownie caramels oat cake cupcake gummies lemon drops icing. Chupa
          chups icing pastry oat cake lemon drops muffin cake pie. Gingerbread
          gummi bears chocolate bar icing sesame snaps candy canes cake toffee
          wafer. Candy gummi bears tart pudding marzipan marshmallow jujubes
          chupa chups. Halvah sweet cookie tart gummi bears candy canes
          gingerbread soufflé sugar plum. Gingerbread pudding cotton candy
          jujubes cake pudding jelly gummies.
        </p>
        <p>
          Sesame snaps muffin cupcake danish chocolate cake halvah pudding
          marzipan muffin. Croissant chocolate bonbon apple pie lemon drops
          marshmallow ice cream cotton candy. Jelly-o cheesecake bear claw lemon
          drops sugar plum icing dragée lollipop. Topping candy gingerbread
          tiramisu bonbon fruitcake ice cream. Cheesecake jujubes jujubes danish
          bonbon marzipan. Chocolate gummi bears bonbon topping cupcake gummies
          jelly. Cake sweet gummi bears icing wafer cupcake macaroon soufflé.
          Chocolate cake carrot cake gingerbread sesame snaps danish cotton
          candy tiramisu muffin. Jujubes lollipop sweet roll cotton candy cotton
          candy pudding. Lemon drops sugar plum bear claw liquorice pudding
          candy tiramisu apple pie. Bear claw macaroon powder marzipan topping
          pie caramels. Jelly chocolate bar gummies halvah cupcake cotton candy.
          Gingerbread sweet wafer fruitcake croissant lemon drops lemon drops
          cake. Bonbon chupa chups oat cake chupa chups tart muffin tart jelly
          beans dragée.
        </p>
        <p>
          Lollipop liquorice biscuit ice cream wafer macaroon donut wafer tart.
          Caramels wafer carrot cake pie pudding halvah jujubes shortbread.
          Dessert cake jelly beans pudding donut carrot cake dragée. Croissant
          ice cream cake carrot cake bonbon chocolate cake pie topping donut.
          Shortbread bear claw sugar plum gummi bears danish. Icing powder wafer
          pudding cupcake apple pie sweet. Toffee jelly-o topping brownie
          brownie topping. Jelly beans soufflé oat cake carrot cake carrot cake.
          Jelly-o icing candy canes sugar plum jelly beans tart cake. Lollipop
          chocolate cake chupa chups cheesecake pudding. Cotton candy
          gingerbread sweet roll pudding brownie. Jelly-o marshmallow apple pie
          ice cream topping muffin croissant. Fruitcake cheesecake candy cake
          powder toffee icing toffee. Cupcake oat cake pastry cotton candy
          candy.
        </p>
        <p>
          Liquorice carrot cake bear claw pudding icing marzipan biscuit carrot
          cake ice cream. Cotton candy marshmallow caramels tart ice cream pie.
          Jelly-o pudding topping marshmallow candy canes cotton candy cake bear
          claw apple pie. Bonbon pastry danish carrot cake bear claw carrot cake
          chocolate cake. Cupcake chocolate bar topping halvah jelly beans
          gummies. Chocolate bar dragée fruitcake chocolate cake bear claw apple
          pie biscuit. Lollipop jujubes bonbon chocolate bar jelly beans jelly
          beans. Sweet halvah liquorice soufflé candy canes. Cookie candy sesame
          snaps biscuit danish candy caramels cupcake. Donut sugar plum cupcake
          gingerbread cake. Donut jujubes powder biscuit tootsie roll marzipan
          biscuit marshmallow marshmallow. Topping brownie sesame snaps pudding
          jelly marshmallow liquorice danish chocolate. Macaroon carrot cake
          carrot cake shortbread caramels lollipop cookie tart. Apple pie
          shortbread chocolate cake gummies caramels lollipop.
        </p>
        <p>
          Chocolate bar apple pie toffee croissant icing sugar plum topping
          icing tart. Halvah sesame snaps croissant oat cake chocolate cake
          toffee donut oat cake. Jujubes dragée bonbon chocolate bar dessert.
          Jujubes carrot cake dessert wafer icing cotton candy danish. Danish
          fruitcake cake chocolate cake lollipop pastry jujubes. Cotton candy
          bonbon chupa chups soufflé candy sweet dessert gingerbread. Marzipan
          fruitcake gummies danish tiramisu lollipop croissant pudding. Gummies
          jujubes jelly-o ice cream shortbread. Carrot cake chocolate cake
          halvah lollipop jelly beans chocolate cake halvah. Halvah bear claw
          carrot cake sweet roll dessert marzipan tootsie roll macaroon.
          Gingerbread chocolate jujubes chupa chups jelly-o oat cake pudding.
          Cupcake topping lollipop halvah pudding halvah dessert cake pudding.
          Liquorice bonbon danish jelly beans lollipop sweet brownie cupcake
          marzipan. Muffin halvah carrot cake candy canes bonbon. Cupcake jelly
          chocolate bar oat cake donut danish donut. Chocolate cake jelly-o
          powder jelly-o jujubes jelly beans pastry bonbon. Chocolate bar
          croissant tart cookie apple pie oat cake brownie. Lemon drops halvah
          pastry macaroon powder cookie candy muffin dragée. Marzipan cookie
          cupcake shortbread lemon drops. Marshmallow halvah carrot cake powder
          tootsie roll cheesecake tootsie roll liquorice gingerbread. Cotton
          candy topping cake biscuit sweet pie lemon drops cake powder. Jujubes
          halvah cupcake toffee bear claw jelly beans. Liquorice sweet macaroon
          biscuit brownie tart. Tart gingerbread danish sesame snaps powder
          caramels. Toffee candy canes cake croissant chocolate cake chocolate
          bar gummies carrot cake chupa chups. Tart croissant icing biscuit
          muffin.
        </p>
        <p>
          Cheesecake macaroon cake candy danish brownie. Gummies jelly beans
          cupcake marzipan shortbread oat cake lemon drops. Halvah pudding sweet
          ice cream sugar plum wafer cake. Jelly beans cotton candy macaroon
          macaroon brownie lemon drops. Bear claw cake lollipop fruitcake oat
          cake. Gummi bears sweet roll tootsie roll jelly jelly-o chocolate cake
          gingerbread cheesecake. Apple pie chocolate cake jelly beans sweet
          roll chupa chups cotton candy. Pie chupa chups jelly-o chocolate cake
          biscuit ice cream sugar plum. Chocolate cake gummi bears jujubes oat
          cake dessert powder chocolate bar chocolate marzipan. Icing danish
          soufflé carrot cake candy gingerbread toffee. Sweet roll liquorice
          chupa chups shortbread biscuit soufflé. Candy canes macaroon carrot
          cake apple pie jujubes. Marshmallow cotton candy sesame snaps pudding
          donut croissant oat cake biscuit macaroon. Croissant topping toffee
          biscuit chocolate bar macaroon lemon drops cheesecake. Cupcake pie
          gingerbread pie oat cake jelly-o chocolate cake caramels marshmallow.
          Chupa chups chocolate bar jelly gingerbread dessert. Muffin muffin
          jelly ice cream shortbread pudding sesame snaps marshmallow.
          Cheesecake dessert fruitcake donut brownie dessert fruitcake pie.
          Cotton candy biscuit jelly beans sesame snaps donut jujubes marzipan
          candy. Candy pudding chupa chups cotton candy fruitcake dragée cake.
          Pastry jelly-o liquorice dessert carrot cake tootsie roll. Cotton
          candy powder chupa chups cotton candy topping pie. Fruitcake bonbon
          danish brownie candy donut. Tart chocolate cake marshmallow chocolate
          toffee. Cheesecake halvah apple pie bonbon halvah. Pudding candy
          chocolate cake soufflé caramels jelly cupcake chocolate cake tart.
          Muffin gummi bears shortbread oat cake liquorice. Powder shortbread
          gummi bears lemon drops fruitcake. Dragée soufflé topping gummies
          apple pie croissant. Chocolate cake apple pie gingerbread chocolate
          bar muffin toffee toffee oat cake bear claw. Chocolate bar sesame
          snaps sweet tart icing sugar plum. Icing halvah pudding marzipan
          powder. Tart apple pie tootsie roll tootsie roll tiramisu candy canes.
          Sweet roll candy toffee biscuit macaroon candy canes. Halvah bear claw
          fruitcake bonbon pudding biscuit. Chocolate cake icing cupcake gummies
          tart soufflé brownie jelly-o. Tootsie roll fruitcake icing dragée
          chocolate bear claw topping. Croissant shortbread carrot cake gummi
          bears danish gummies soufflé brownie. Sesame snaps chupa chups cookie
          jujubes sweet roll dessert. Tart sugar plum cake chocolate bar pudding
          carrot cake jujubes croissant. Chocolate cake dessert gummi bears
          muffin marshmallow. Biscuit sesame snaps jelly bear claw apple pie
          chocolate cake. Oat cake apple pie pudding gummies soufflé caramels
          jujubes soufflé bear claw. Dragée cake sweet roll tootsie roll wafer.
          Jelly-o brownie tiramisu biscuit croissant cheesecake. Biscuit pie
          caramels chocolate apple pie jelly beans carrot cake. Sesame snaps
          lollipop danish toffee donut cookie. Toffee fruitcake dragée sesame
          snaps wafer carrot cake dessert cake. Donut jelly beans pastry jelly
          beans cake biscuit donut jelly-o cake. Jelly beans muffin lollipop
          shortbread pastry marzipan cookie. Croissant tootsie roll chupa chups
          danish caramels sweet roll.
        </p>
        </div>
      </div>
    );
  }
}
