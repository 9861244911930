import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import {
  GET_ERRORS,
  SET_USER_COINS,
  SET_LOGIN_USER_HIGHSCORES,
  SET_CURRENT_USER,
  SET_PROFILE_IMG,
  SET_AUTH,
  GET_USERS,
  USER_T_SCORES_UPDATE_IO,
  PLATFORM_NOT_AVAILABLE
} from "./types";
import { reloadComponentWithSuccess } from "../common/lib";
import { setProfileLoading, clearProfileLoading } from "./profileActions";
import { updateUserCoins } from "./games";
import S3 from "react-aws-s3";
import { SET_USER_MONEY } from "./types";

let backendServerURL = process.env.REACT_APP_URL;
let countryModal = false

const config = {
  bucketName: 'playtoobucket',
  region: 'eu-west-2',
  accessKeyId: 'AKIATUOMMYVDRFJWACTE',
  secretAccessKey: '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',
};

// Login - Get User Token (Verified)
export const loginJwt = (userData, history) => (dispatch) => {
  dispatch(setProfileLoading());
  window.myFunct2();
  dispatch({ type: GET_ERRORS, payload: "" });
  axios
    .post(backendServerURL + "auth/login", userData)
    .then((res) => {
      // debugger
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);

      setAuthToken(token);
      // dispatch(setCurrentUser(user));
      window.location = "/";
      // history.push('/')
      // dispatch(isAuthenticated(true));
    })
    .catch((err) => {
      // debugger
      console.log("AUTH ERR", err);
      window.openModale("#login");
      // debugger
      // alert("ERR_CONNECTION_REFUSED")
      let loginErr = err.response ? err.response.data : { email: 'Invalid Email or Password' };
      dispatch({ type: GET_ERRORS, payload: { loginErr } });
    })
    .finally(() => dispatch(clearProfileLoading()));
};

//Forgot Password
export const forgotPassword = (userData, history) => (dispatch) => {
  dispatch(setProfileLoading());
  axios
    .post(backendServerURL + "auth/forgot-password", userData)
    .then((res) => {
      const { token } = res.data;

      localStorage.setItem("jwtToken", token);
      setAuthToken(token);
      const decoded = jwt_decode(token);
      dispatch(setCurrentUser(decoded));
      history.push("/sign-in");
    })
    .catch((err) => {
      dispatch({ type: GET_ERRORS, payload: err.response.data });
    })
    .finally(() => dispatch(clearProfileLoading()));
};

//Recover Password
export const sendPasswordRecoveryLink = (userData, history) => (dispatch) => {
  dispatch(setProfileLoading());
  axios
    .post(backendServerURL + "auth/recover-password-email", userData)
    .then((res) => {
      if (res.data.success) {
        reloadComponentWithSuccess();
      }
    })
    .catch((err) => {
      dispatch({ type: GET_ERRORS, payload: err.response.data });
    })
    .finally(() => dispatch(clearProfileLoading()));
};

//Reset Password
export const resetUserPassword =
  (inputData, verificationToken, history) => (dispatch) => {
    dispatch(setProfileLoading());

    axios
      .post(backendServerURL + "auth/reset-user-password", {
        token: verificationToken,
        passwordData: inputData,
      })
      .then((res) => {
        window.location = "/#login-modal";
      })
      .catch((err) =>
        dispatch({ type: GET_ERRORS, payload: err.response.data })
      )
      .finally(() => dispatch(clearProfileLoading()));
  };

// Login with facebook
export const loginWithFacebook = (userData, history) => (dispatch) => {
  dispatch(setProfileLoading());
  axios
    .post(backendServerURL + "auth/facebook", userData)
    .then((res) => {
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      setAuthToken(token);
      dispatch(setCurrentUser(res.data.user));
      if (res.data.created) {
        history.push("/");
        // window.location = '/'

        const payload = { coins: 200 };
        dispatch(updateUserCoins(payload));
        window.openModaleNoCLose("#congratulations");
        // dispatch(setUserCoins(200))
      } else {
        history.push("/");
        window.location.reload(true);
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: GET_ERRORS, payload: err.response.data });
    })
    .finally(() => dispatch(clearProfileLoading()));
};

// Login with google
export const loginWithGoogle = (userData, history) => (dispatch) => {
  dispatch(setProfileLoading());
  axios
    .post(backendServerURL + "auth/google", userData)
    .then((res) => {
      if (res.status === 200) {
        const { token } = res.data;
        localStorage.setItem("jwtToken", token);
        setAuthToken(token);
        dispatch(setCurrentUser(res.data.user));
        // debugger
        if (res.data.created) {
          history.push("/");
          // window.location = '/'

          const payload = { coins: 200 };
          dispatch(updateUserCoins(payload));
          window.openModaleNoCLose("#congratulations");
          // dispatch(setUserCoins(200))
        } else {
          history.push("/");
          window.location.reload(true);
        }
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: GET_ERRORS, payload: err });
    })
    .finally(() => dispatch(clearProfileLoading()));
};

// Register User (Verified)
export const registerUser = (userData, history) => (dispatch) => {
  dispatch(setProfileLoading());
  window.myFunct2();
  // debugger
  const { image, ...rest } = userData;
  axios
    .post(backendServerURL + "auth/signup", rest)
    .then((res) => {
      if ((res.status = 200)) {
        const { token } = res.data;
        // debugger
        localStorage.setItem("jwtToken", token);
        setAuthToken(token);
        // const decoded = jwt_decode(token);
        const { user } = res.data;
        dispatch(setCurrentUser(user));
        window.myFunct2();
        // <Redirect to='/dashboard' />
        const payload = { coins: 200 };
        dispatch(updateUserCoins(payload));

        window.openModaleNoCLose("#congratulations");

        if (image !== "") {
          dispatch(changeProfileImg(image));
          // history.push('/dashboard')
          // debugger
        }
        // <Redirect to ='/#congratulations' />
      } else {
        window.openModale("#register-form");
      }
    })
    .catch((err) => {
      console.log(err);
      // debugger
      let thisError = err.response.data.emailRegister;
      if (thisError === undefined) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
        // alert("Some fields are missing")
        window.openModale("#register-form");
      } else {
        // alert("This Email Already Exists")
        window.openModale("#register-form");
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      }
    })
    .finally(() => dispatch(clearProfileLoading()));
};

// Register User (Wallet)
export const registerWithWallet = (userData, history) => (dispatch) => {
  dispatch(setProfileLoading());
  window.myFunct2();
  // debugger
  const { image, ...rest } = userData;
  axios
    .post(backendServerURL + "auth/signupWithWallet", rest)
    .then((res) => {
      if ((res.status = 200)) {
        const { token } = res.data;
        // debugger
        localStorage.setItem("jwtToken", token);
        setAuthToken(token);
        // const decoded = jwt_decode(token);
        const { user } = res.data;
        dispatch(setCurrentUser(user));
        window.myFunct2();
        // <Redirect to='/dashboard' />
        const payload = { coins: 200 };
        dispatch(updateUserCoins(payload));
        window.openModaleNoCLose("#congratulations");
        if (image !== "") {
          dispatch(changeProfileImg(image));
        }
      } else {
        window.openModale("#register-soc");
      }
    })
    .catch((err) => {
      console.log(err)
      alert(err.response?.data.walletRegister)
      // debugger
      let thisError = err.response.data.walletRegister;
      if (thisError === undefined) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
        window.openModale("#register-soc");
      } else {
        window.openModale("#register-soc");
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      }
    })
    .finally(() => dispatch(clearProfileLoading()));
};

// Login - Get User Token (Wallet)
export const loginJwtWithWallet = (userData, history) => (dispatch) => {
  dispatch(setProfileLoading());
  window.myFunct2();
  dispatch({ type: GET_ERRORS, payload: "" });
  axios
    .post(backendServerURL + "auth/loginWithWallet", userData)
    .then((res) => {
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      setAuthToken(token);
      window.location = "/";
    })
    .catch((err) => {
      console.log(err)
      alert(err.response?.data.address)
      window.openModale("#login-modal");
      let loginErr = err.response ? err.response.data : { email: 'Invalid address' };
      dispatch({ type: GET_ERRORS, payload: { loginErr } });
    })
    .finally(() => dispatch(clearProfileLoading()));
};

//GET USer Information
export const getCurrentUser = () => (dispatch) => {
  const options = {
    Authorization: localStorage.jwtToken,
    "Content-Type": "application/json",
  };
  dispatch(setProfileLoading());
  axios
    .get(backendServerURL + "auth/getUserData", options)
    .then((res) => {
      const { user } = res.data;
      // debugger

      dispatch(setCurrentUser(user));
      dispatch(isAuthenticated(true));
    })
    .catch((err) => {
      if (err.response && err.response.data?.code === 1450) {
        // debugger
        dispatch({ type: PLATFORM_NOT_AVAILABLE, payload: false })
        if (countryModal === false) {

          countryModal = setTimeout(() => { window.openModale('#valid-country') }, 1000)
        }

      }
      // debugger
      // if (err.response.status === 500) {
      //     dispatch(logoutUser());
      // }
      console.log(" getCurrentUser RESPONSE ERR", err);
    })
    .finally(() => dispatch(clearProfileLoading()));
};

export const getUserData = () => (dispatch) => {
  const options = {
    Authorization: localStorage.jwtToken,
    "Content-Type": "application/json",
  };
  // dispatch(setProfileLoading());
  axios
    .get(backendServerURL + "auth/getUserData", options)
    .then((res) => {
      const { user } = res.data;
      // debugger

      dispatch(setCurrentUser(user));
      dispatch(isAuthenticated(true));
    })
    .catch((err) => {
      // debugger
      // if (err.response.status === 500) {
      //     dispatch(logoutUser());
      // }
      console.log(" getCurrentUser RESPONSE ERR", err);
    })
  // .finally(() => dispatch(clearProfileLoading()));

}

// CHANGE PROFILE IMAGE
export const changeProfileImg = (newFile) => (dispatch) => {
  const ReactS3Client = new S3(config);
  // const newFileName = 'Profile Picture';
  ReactS3Client.uploadFile(newFile)
    .then((data) => {
      const profileUrl = data.location;
      const token = localStorage.jwtToken;
      const { userId } = jwt_decode(token);
      // debugger
      const profile = {
        userId: userId,
        imageUrl: profileUrl,
      };
      // console.log("this is img url", profileUrl, user)
      axios
        .post(backendServerURL + "admin/updateUserImg", profile)
        .then((response) =>
          console.log("this is the response from update")
        )
        .catch((err) => console.log("this is the error from axios req", err));
      dispatch(setProfileImg(profileUrl));
    })
    .catch((err) => console.error(err));
};

// CHANGE PROFILE IMAGE END
// Profile Img State   ////
export const setProfileImg = (img) => ({
  type: SET_PROFILE_IMG,
  payload: img,
});
// Set logged in user (Verified)
export const setCurrentUser = (decoded) => ({
  type: SET_CURRENT_USER,
  payload: decoded,
});
export const isAuthenticated = (data) => ({
  type: SET_AUTH,
  payload: data,
});

// Log user out (Verified)
export const logoutUser = (history) => (dispatch) => {
  dispatch(setCurrentUser({}));
  localStorage.removeItem("jwtToken");
  setAuthToken(false);
  // debugger

  // newHistory.push('/');
  setTimeout(() => {
    window.location = "/";
  }, 200);
};

export const changeNickname = (userData) => (dispatch) => {
  // debugger;
  const options = {
    Authorization: localStorage.jwtToken,
    "Content-Type": "application/json",
  };
  dispatch(setProfileLoading());
  axios
    .post(backendServerURL + "admin/updateUserProfile", userData, options)
    .then((res) => {
      const { data } = res.data;
      dispatch(setCurrentUser(data));
      if (res.status === 200) {
        window.myFunct();
      }
    })
    .catch((err) => {
      dispatch({ type: GET_ERRORS, payload: err.response.data });
    })
    .finally(() => dispatch(clearProfileLoading()));
};

// Change Current Password
export const changePassword = (data) => (dispatch) => {
  const options = {
    Authorization: localStorage.jwtToken,
    "Content-Type": "application/json",
  };
  dispatch(setProfileLoading());
  axios
    .post(backendServerURL + "auth/change-user-password", data, options)
    .then((res) => {
      if (res.status === 200) {
        const data = res.data;
        // debugger
        dispatch(setCurrentUser(data));
        window.myFunct();
      }
    })
    .catch((err) => {
      console.log(err.response.data);
      dispatch({ type: GET_ERRORS, payload: err.response.data });
    })
    .finally(() => dispatch(clearProfileLoading()));
};
////// GET USERS API  /////////
export const getUsers = (data) => (dispatch) => {
  const options = {
    Authorization: localStorage.jwtToken,
    "Content-Type": "application/json",
  };
  axios
    .get(backendServerURL + "admin/getUsers", options)
    .then((res) => {
      // debugger
      dispatch({ type: GET_USERS, payload: res.data.users });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: GET_ERRORS, payload: err });
    });
  // .finally(() => dispatch(clearProfileLoading()));
};
export const getloginUserHighscores = () => (dispatch) => {
  // debugger
  const token = localStorage.jwtToken;
  const { userId } = jwt_decode(token);
  const payload = {
    userId: userId,
  };
  axios
    .post(`${backendServerURL}games/getHighscores`, payload)
    .then((res) => {
      // debugger
      const loginHighscores = res.data.highscoresArray;
      // debugger
      dispatch({ type: SET_LOGIN_USER_HIGHSCORES, payload: loginHighscores });

      // debugger
    })
    .catch((err) => console.log("Get Coins Error..", err));
};

export const setUserCoins = (data) => ({
  type: SET_USER_COINS,
  payload: data,
});
export const setUserMoney = (data) => ({
  type: SET_USER_MONEY,
  payload: data,
});
export const userTScoresUpdateIo = payload => {
  return { type: USER_T_SCORES_UPDATE_IO, payload: payload }
}


export const subscribeToNewsletter = (email) => (dispatch) => {
  // debugger;
  // const options = {
  //   Authorization: localStorage.jwtToken,
  //   "Content-Type": "application/json",
  // };
  dispatch(setProfileLoading());
  axios
    .post(backendServerURL + "countries/subscribe", { email })
    .then((res) => {
      // debugger
      // const { data } = res.data;

      if (res.status === (201 || 200)) {

        window.myFunct();
        window.openModale('#back-profile')
      }
    })
    .catch((err) => {
      console.log(err.response.data);
      // dispatch({ type: GET_ERRORS, payload: err.response.data });
    })
    .finally(() => dispatch(clearProfileLoading()));
};
