import axios from 'axios';
import { GET_MONTHLY_SWEEP_WINNERS , SWEEP_STAKE, ALL_SWEEP_STAKE, SET_GAMES, SET_TOURNAMENTS, SET_ALL_TOURNAMENTS ,UPDATE_TOURNAMENTS_IO, ADD_S_POSITIONS,SET_TOURNAMENTS_USER_POS, SET_NEXT_TOURNAMENT, SET_NEXT_SWEEPSTAKE, SWEEPSTAKE_POSITIONS, YOUR_SWEEP_POSITION } from './types';

// let backendServerURL = "https://playtooapi.herokuapp.com";
let backendServerURL = process.env.REACT_APP_URL;

export const sweepStake = (payload) => dispatch => {
    const options = {
        Authorization: localStorage.jwtToken,
        'Content-Type': 'application/json',
    };
    axios
        .get(
            `${backendServerURL}admin/getSweepStakes`,
            payload,
            options
        )
        .then(res => {
            console.log('SWEEP STAKE RESPONSE', res);
            dispatch({ type: SWEEP_STAKE, payload: res.data.sweepstakes })
        })
        .catch(err => {
            console.log(' SWEEPSTAKE RESPONSE ERR', err)
        })
};

export const getAllSweepStake = (payload) => dispatch => {
    const options = {
        Authorization: localStorage.jwtToken,
        'Content-Type': 'application/json',
    };
    axios
        .get(
            `${backendServerURL}admin/getSweepStakesAdmin`,
            payload,
            options
        )
        .then(res => {
            console.log('SWEEP STAKE RESPONSE', res);
            dispatch({ type: ALL_SWEEP_STAKE, payload: res.data.sweepstakes })
        })
        .catch(err => {
            console.log(' SWEEPSTAKE RESPONSE ERR', err)
        })
};


export const updateSweepIo = (arr) => ({type: SWEEP_STAKE, payload : arr})

export const updateGamesIo = (arr) => ({type: SET_GAMES, payload : arr})

export const getGames = (payload) => dispatch => {
    console.log('fetchGamesDispatch Status Payload', payload)


    const options = {
        Authorization: localStorage.jwtToken,
        'Content-Type': 'application/json',
    };

    axios
        .get(
            `${backendServerURL}games`,
            payload,
            options
        )
        .then(res => {
            // debugger
            console.log('fetchGamesDispatch RESPONSE', res);
            dispatch({ type: SET_GAMES, payload: res && res.data ? res.data.games : null })

        })
        .catch(err => {
            // debugger
            console.log(' SWEEPSTAKE RESPONSE ERR', err)

        })
};

export const getTournaments = (payload) => dispatch => {
    const options = {
        Authorization: localStorage.jwtToken,
        'Content-Type': 'application/json',
    };

    axios
        .get(
            `${backendServerURL}admin/getTournaments`,
            payload,
            options
        )
        .then(res => {
            // debugger
            console.log('getTournaments RESPONSE', res);
            dispatch({ type: SET_TOURNAMENTS, payload: res && res.data ? res.data.tournaments : null })
            dispatch({ type: SET_TOURNAMENTS_USER_POS, payload: res && res.data ? res.data.userPositions : null })
            dispatch({ type: SET_NEXT_SWEEPSTAKE, payload: res && res.data ? res.data.nextSweepstakeDate : null })
        })
        .catch(err => {
            // debugger
            console.log(' getTournaments RESPONSE ERR', err)

        })

};

export const getAllTournaments = (payload) => dispatch => {
    const options = {
        Authorization: localStorage.jwtToken,
        'Content-Type': 'application/json',
    };

    axios
        .get(
            `${backendServerURL}admin/getAllTournaments`,
            payload,
            options
        )
        .then(res => {
            // debugger
            console.log('getTournaments RESPONSE', res);
            dispatch({ type: SET_ALL_TOURNAMENTS, payload: res && res.data ? res.data.tournaments : null })
        })
        .catch(err => {
            // debugger
            console.log(' getTournaments RESPONSE ERR', err)

        })

};

export const getSweepStakeWinner = (payload) => dispatch => {
    // const options = {
    //     token: localStorage.jwtToken,
    //     'Content-Type' : 'application/json'
    // }

    axios.post(
        `${backendServerURL}admin/getSweepStakeWinners`,
        payload,
        // options
    )
    .then(res => {
        
        const { winners } = res.data
        
        console.log('SWEEPSTAKE WINNERS API RESPONSE', winners);
        
        dispatch({ type: SWEEPSTAKE_POSITIONS, payload: winners})
        // dispatch({ type: YOUR_SWEEP_POSITION, payload: currentUserPosition})
        
    })
    .catch(err => {
        console.log('SWEEPSTAKE WINNERS API ERR', err)
    })
}

export const getUserSweepstakePosition = (payload) => dispatch => {
    const options = {
        Authorization: localStorage.jwtToken,
        'Content-Type' : 'application/json'
    }

    axios.post(
        `${backendServerURL}admin/getUserSweepstakePosition`,
        payload,
        options
    )
    .then(res => {
        
        const { currentUser } = res.data
        
        console.log('SWEEPSTAKE POSITION API RESPONSE', currentUser);
        
        dispatch({ type: YOUR_SWEEP_POSITION, payload: currentUser})
        
        
    })
    .catch(err => {
        console.log('SWEEPSTAKE POSITION API ERR', err)
    })
}
export const getMonthlySweepStakeWinners = () => dispatch =>{
    axios.get(   backendServerURL + 'admin/getMonthlySweepStakeWinners')
    .then(res=>{
        // debugger
        dispatch({type: GET_MONTHLY_SWEEP_WINNERS, payload:res.data.data})
        // res.data
    }).catch(err=>{
        console.log(err)
    })
}
export const updateTournamentsIo = (arr) => ({type: UPDATE_TOURNAMENTS_IO, payload : arr})
export const sweepstakePosSocket = payload =>{
    return {type : ADD_S_POSITIONS, payload : payload}
}