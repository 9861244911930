/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getGames,
  updateSweepIo,
  getTournaments
} from "../../actions/sweepStake";
import {
  getTournamentPositions,
  setModalTournament,
  getNotification,
  deleteNotification
} from "../../actions/tournaments";
import { getHighscores, setUserCoins } from "../../actions";
import moment from "moment-timezone";
import $ from "jquery";
import dateHelper from "../../helpers/dateHelper";
import socket from "../../helpers/socket";
import jstz from "jstimezonedetect";
import ResetPassword from "../auth/resetPassword";
import Modal from "../modals/liveModal";
import { coinsAnimation } from '../../helpers/animations'
import LiveTournament from "../modals/liveTournament";
import { getCurrentUser } from '../../actions/authActions';
import OneSignal from 'react-onesignal';

const tz = jstz.determine().name();
const TIMEZONE = dateHelper.getTimeZone(tz);

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventDate: "",
      gameName: "",
      index: false,
      trn_endTime: 0,
      tournamentId: "",
      currentTournament: null,
      tempGames: [],
      userPos: "",
      gameObjId: null,
      unregisteredGameId: "",
      unregisteredGameName: "",
      presentGames: [],
      gamesToShow: 6,
      tournamentsToShow: 8,
      lastTournament: false,
      userHighscore: {},
      timer: 0,
      you_scored: false,
      live_t_modalgame: false,
      normalGameobj: false,
      onceLoad: false,
      thisGameHigh: 0,
      nextSweepstakeFinishDate: 0,
      sum: null,
    };
    this.timerInterval = null;
    this.tournamentTimerInterval = null;
  }
  componentDidUpdate(prevProps) {
    if (prevProps.games !== this.props.games && this.props.games.length > 0) {
      window.featureSlicker()
    }
    if (this.props.nextSweepstakeDate && !this.state.onceLoad) {
      this.startSweepstakeTimer(this.props.nextSweepstakeDate);
      this.setState({ onceLoad: true });
    }
  }

  componentDidMount() {
    this.props.fetchGamesDispatch();
    this.props.fetchTournamentsDispatch();
    const { props: { games } } = this;

    if (this.props.nextSweepstakeDate) {
      this.setState({ nextSweepstakeFinishDate: this.props.nextSweepstakeDate })
    }

    if (window.location.hash === '#account') {
      window.openModaleNoCLose("#popup-account")
    }

    this.setState({ presentGames: games });
    if (window.location.href.includes("#oops")) {
      window.openModale("#oops");
    }
    if (window.location.href.includes("#tournament-active")) {
      this.props.history.push("/");
      window.myFunct2();
    }

    if (localStorage.jwtToken && localStorage.tempNormalGameObject && window.location.href.includes("playagain")) {
      const st1 = JSON.stringify(localStorage.getItem("tempNormalGameObject"));

      const tempObj = JSON.parse(window.atob(JSON.parse(st1)));
      let name = tempObj.gameName;
      localStorage.removeItem("tempNormalGameObject");

      name = name.replace(/_/g, " ").toLocaleLowerCase();
      function titleCase(str) {
        var splitStr = str.toLowerCase().split(" ");
        for (var i = 0; i < splitStr.length; i++) {
          splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }

        return splitStr.join(" ");
      }
      // debugger
      name = titleCase(name);

      let thisgameobj = tempObj.games.find((obj) => obj.name === name);

      // debugger
      this.setState({ normalGameobj: thisgameobj });
      this.props.history.push("/");
      window.openModale("#lucky-coins");
      // }
    }

    if (window.location.href.includes("live-tournament-modal")) {
      this.props.history.push('/');

      if (localStorage.tempGameObject) {
        const st1 = JSON.stringify(localStorage.getItem("tempGameObject"));
        const tempObj = JSON.parse(window.atob(JSON.parse(st1)));
        const tempGame = tempObj.games.find((el) => el._id === tempObj.gameId);

        //  debugger
        localStorage.removeItem("tempGameObject");
        this.setState({
          currentTournament: tempObj.currentTournament,
          _id: tempObj.tournamentId,
          trn_endTime: tempObj.trn_endTime,
          gameName: tempObj.gameName,
          tempGames: tempObj.games,
          gameObjId: tempObj.gameId,
          you_scored: tempObj.gameScore,
          live_t_modalgame: tempGame,
          trn_modal: true,
          tournamentId: tempObj.tournamentId
        });
        const tournamentObj = {
          _id: tempObj.tournamentId,
          you_scored: tempObj.gameScore,

        }
        this.props.setModalTournamentDispatch(
          tournamentObj
        );
        // const payload = {
        //   tournamentId: tempObj.tournamentId,
        // };
        window.liveTmodalTimer(tempObj.trn_endTime, "#live-tournament-again2");
        // this.props.getTournamentPositions(payload);
      }
      setTimeout(() => {
        window.openSliderModale('#live-tournament-again2')
      }, 1500);
      // window.openModale("#live-tournament");
    }

    $("#modal-play").click(this.getGamePathForTournament);
    $("#modal-play2").click(this.getGamePathForTournament);

    $("#register-btn, #register-btn-oop, #register-btn-coin").on("click", () => {
      window.myFunct();
      window.openModale("#register-soc");
    });

    $("#play-as-guest, #play-as-guest3").click(() => {
      window.myFunct2();
      this.getGameUrlOffline();
    });

    $(".modal_closer").on("click", () => {
      window.myFunct();
      this.props.history.push("/");
    });
    $("#play-again").on("click", () => {
      this.props.history.push(this.getGameUrl(this.state.normalGameobj));
      window.myFunct2();
    });
    $("#claim_200").on("click", () => {
      setTimeout(() => {
        this.props.setUserCoinsDispatch(200);
        // window.myFunct2();
      }, 4000)
      const target = document.querySelector('#img-200')
      coinsAnimation(target, this.props.user?.coins, 200, "TOURNAMENT")

    });

    if (localStorage.jwtToken) {
      // this.props.isAuthenticatedDispatch()
      this.props.fetchCurrentUserDispatch()

    }

    socket.on('sweepstake-new-started', (data) => {
      if (data) {
        if (this.timerInterval) {
          clearInterval(this.timerInterval);
        }
        this.startSweepstakeTimer(data.nextSweepstakeDate);
        this.props.updateSweepIoDispatch(data.sweepStakes);
        this.setState({
          nextSweepstakeFinishDate: data.nextSweepstakeDate,
          sum: data.totalJackpotAmount ?? null
        })
        const sweepstakeSumElem = '#active-prize-sum';
        $(sweepstakeSumElem).html(`$PLY ${data.totalJackpotAmount ?? 0}<img src="images/icons/cash-icon.png" alt="" />`);
      }
    })

    socket.on('sweepUpdate', (data) => {
      const sweepstakeSumElem = '#active-prize-sum';
      if (data) {
        if (this.timerInterval) clearInterval(this.timerInterval);
        this.startSweepstakeTimer(data.nextSweepstakeDate);
        this.props.updateSweepIoDispatch(data.sweepStakes);
        this.setState({
          nextSweepstakeFinishDate: data.nextSweepstakeDate,
          sum: data.totalJackpotAmount ?? null
        })
        $(sweepstakeSumElem).html(`$PLY ${data.totalJackpotAmount ?? 0}<img src="images/icons/cash-icon.png" alt="" />`);
      }else{
        if (this.timerInterval) clearInterval(this.timerInterval);
        this.startSweepstakeTimer(null);
        this.setState({ sum: 0 });
        this.props.updateSweepIoDispatch([]);
        this.setState({ nextSweepstakeFinishDate: 0 })
        $(sweepstakeSumElem).html(`$PLY 0<img src="images/icons/cash-icon.png" alt="" />`);
      }
    })

    this.tournamentTimerInterval = setInterval(() => {
      this.setState((prevState) => {
        return { timer: prevState.timer + 1 };
      });
    }, 500);
  }

  componentWillUnmount() {
    if (this.timerInterval) clearInterval(this.timerInterval);
    if (this.tournamentTimerInterval) clearInterval(this.tournamentTimerInterval);
  }

  getNotificationStatus = (id) => {
    const {
      props: { notifications },
    } = this;
    // debugger
    const subscribed = notifications.find(el => el.tournament === id)
    if (subscribed) {
      return true
    } else {
      return false
    }
  }

  getNotified = (obj) => {
    if (localStorage.jwtToken) {
      OneSignal.showNativePrompt()
      const externalUserId = this.props.user ? this.props.user._id : null
      if (externalUserId) {
        OneSignal.setExternalUserId(externalUserId);
        const payload = { tournament: obj._id }
        this.props.getNotificationsDispatch(payload)
      }
    } else {
      const iD = obj.game[0]?._id
      const name = obj.game[0]?.name
      this.setState({ unregisteredGameId: iD, unregisteredGameName: name });
      window.openModale('#register-for-Notifies')
    }
  }

  deleteNotification = (id) => {
    const {
      props: { notifications },
    } = this;
    const subscribed = notifications.find(el => el.tournament === id)
    if (subscribed) {
      const payload = { notificationId: subscribed?._id }
      this.props.deleteNotificationDispatch(payload)
    }
  }

  handleAllWinners = (e) => {
    e.preventDefault();
    window.location.href = "winner";
  };

  getFeatureGameIcon = (obj) => {
    if (!obj) {
      return "";
    }
    if (!obj.icons) {
      return "";
    }
    // debugger
    return obj.icons[0] ? obj.icons[0].imageURL : "";
  };

  getGameName = (tournamentObj) => {
    const gameName = tournamentObj.gameName || tournamentObj.game[0].name;
    return gameName;
  };

  getTournamentStartTimeText = (tournamentObj) => {
    const current = moment().tz(TIMEZONE.value);
    const end = tournamentObj.startDate;
    var duration = moment.duration(current.diff(end));
    var days = duration.asDays();
    var hours = duration.hours();
    var mins = duration.minutes();
    var secs = duration.seconds();
    let resp = "";
    days = parseInt(Math.abs(days));
    mins = parseInt(Math.abs(mins));
    hours = parseInt(Math.abs(hours));
    secs = parseInt(Math.abs(secs));

    if (days > 0) {
      resp = `${days} d`;
    }
    if (hours > 0) {
      resp = `${resp} ${hours} h`;
    }
    if (mins > 0) {
      resp = `${resp} ${mins} m`;
    }
    if (secs > 0 && days <= 0) {
      resp = `${resp} ${secs} s`;
    }
    return resp;
  };

  getTournamentActiveClass = (tournament) => {
    const now = moment().tz(TIMEZONE.value);
    let tournamentClassName = "tournament-img";
    if (moment(tournament.startDate).tz(TIMEZONE.value) > now) {
      tournamentClassName = "tournament-img tournament-img__active";
    }
    return tournamentClassName;
  };

  getActiveTournaments = () => {
    const {
      props: { tournaments },
    } = this;
    const current = moment().tz(TIMEZONE.value);
    const newArr = tournaments.filter(function (obj) {
      if (
        (moment(obj.finishDate).tz(TIMEZONE.value).isSame(current) ||
          moment(obj.finishDate).tz(TIMEZONE.value) > current) &&
        (moment(obj.startDate).tz(TIMEZONE.value) < current ||
          moment(obj.startDate).tz(TIMEZONE.value).isSame(current))
      ) {
        return true;
      }
    });
    
    let tournamentSum = newArr.reduce(function (prev, cur) {
      return prev + cur.totalJackpotAmount;
    }, 0);

    const sweepstakeSumElem = '#tournamentSum';
    $(sweepstakeSumElem).html(`$PLY ${tournamentSum} <img src="images/icons/cash-icon.png" alt="" />`);
    return tournamentSum;
  };

  getTournamentEndTimeText = (tournament) => {
    const current = moment().tz(TIMEZONE.value);

    const end = tournament.finishDate;
    var duration = moment.duration(current.diff(end));

    var days = duration.asDays();
    var hours = duration.hours();

    var mins = duration.minutes();
    var secs = duration.seconds();
    let resp = "";

    days = parseInt(Math.abs(days));
    mins = parseInt(Math.abs(mins));
    hours = parseInt(Math.abs(hours));
    secs = parseInt(Math.abs(secs));

    if (days > 0) {
      resp = `${days} d `;
    }
    if (hours > 0) {
      resp = `${resp}${hours} h `;
    }
    if (mins > 0) {
      resp = `${resp}${mins} m `;
    }
    if (secs > 0 && days <= 0) {
      resp = `${resp} ${secs} s `;
    }

    resp = `${resp}left`;
    return resp;
  };

  getPercentageBar = (tournament) => {
    const current = moment().tz(TIMEZONE.value);
    const end = tournament.finishDate;
    const seconds = Math.abs(current.diff(end, "seconds"));
    let s1 = Math.abs(100 - Math.round(seconds / 3600));
    if (s1 > 100) {
      s1 = 100;
    }
    s1 = `${s1}%`;
    return s1;
  };

  getGamePathForTournament = () => {
    const { props: { games } } = this;
    const game = games.filter((el) => el.name === this.state.gameName)[0];
    let name = this.state.gameName;
    let trn_id = this.state.tournamentId;
    name = name.replace(/ /g, "_").toLocaleLowerCase();
    this.props.history.push(`tournament?gamename=${name}&g_id=${game._id}&t_id=${trn_id}`);
    window.myFunct2();
  };

  getGameUrl = (gameObj) => {
    let resp = "#";
    if (gameObj) {
      let name = gameObj.name.replace(/ /g, "_").toLocaleLowerCase();
      let gameId = gameObj._id;
      resp = `gameplay?gamename=${name}&g_id=${gameId.toString()}`;
    }
    return resp;
  };

  getGameUrlOffline = (Name, Id) => {
    let name = this.state.unregisteredGameName.replace(/ /g, "_").toLocaleLowerCase();
    let gameId = this.state.unregisteredGameId;
    this.props.history.push(`gameplay?gamename=${name}&g_id=${gameId}`);
  };

  startSweepstakeTimer = (nextSweepstakeDate) => {
    this.timerInterval = setInterval(() => {
      this.getBannerSweepTime(nextSweepstakeDate);
    }, 3000);
  }

  getBannerSweepTime = (nextSweepstakeDate) => {
    const nowDate = new Date();
    const elem = '#timer-sweepstake'
    const sweepstakeElem = '#timer-sweepstake-1'
    const sweepstakeElem2 = '#timer-sweepstake-2'
    const filteredSweepElem = '#filteredSweep-bon'
    if (nextSweepstakeDate) {
      const current = moment().tz(TIMEZONE.value);
      const end = new Date(nextSweepstakeDate);
      const result = end - nowDate;
      if (result <= 0) {
        clearInterval(this.timerInterval); // 타이머 멈추기
        $(elem).find(".days").html("<span>0</span><span>0</span>");
        $(elem).find(".hours").html("<span>0</span><span>0</span>");
        $(elem).find(".minutes").html("<span>0</span><span>0</span>");
        $(sweepstakeElem).find(".days").html("<span>0</span><span>0</span>");
        $(sweepstakeElem).find(".hours").html("<span>0</span><span>0</span>");
        $(sweepstakeElem).find(".minutes").html("<span>0</span><span>0</span>");
        $(sweepstakeElem2).find(".days").html("<span>0</span><span>0</span>");
        $(sweepstakeElem2).find(".hours").html("<span>0</span><span>0</span>");
        $(sweepstakeElem2).find(".minutes").html("<span>0</span><span>0</span>");
        $(filteredSweepElem).css('display', 'none');
        this.setState({ sum: 0 });
        const sweepstakeSumElem = '#active-prize-sum';
        $(sweepstakeSumElem).html(`$PLY 0<img src="images/icons/cash-icon.png" alt="" />`);
        return;
      }

      var duration = moment.duration(current.diff(end));
      var days = duration.asDays();
      var hours = duration.hours();
      var minutes = duration.minutes();
      days = parseInt(Math.abs(days));
      hours = parseInt(Math.abs(hours));
      minutes = parseInt(Math.abs(minutes));
      let min = minutes / 10;
      let h = hours / 10;
      let d = days / 10;
      if (days >= 10) days = days % 10;
      if (minutes >= 10) minutes = minutes % 10;
      if (hours >= 10) hours = hours % 10;
      $(elem).find(".days").html("<span>" + Math.trunc(d) + "</span><span>" + (days) + "</span>");
      $(elem).find(".hours").html("<span>" + Math.trunc(h) + "</span><span>" + (hours) + "</span>");
      $(elem).find(".minutes").html("<span>" + Math.trunc(min) + "</span><span>" + (minutes) + "</span>");
      $(sweepstakeElem).find(".days").html("<span>" + Math.trunc(d) + "</span><span>" + (days) + "</span>");
      $(sweepstakeElem).find(".hours").html("<span>" + Math.trunc(h) + "</span><span>" + (hours) + "</span>");
      $(sweepstakeElem).find(".minutes").html("<span>" + Math.trunc(min) + "</span><span>" + (minutes) + "</span>");
      $(sweepstakeElem2).find(".days").html("<span>" + Math.trunc(d) + "</span><span>" + (days) + "</span>");
      $(sweepstakeElem2).find(".hours").html("<span>" + Math.trunc(h) + "</span><span>" + (hours) + "</span>");
      $(sweepstakeElem2).find(".minutes").html("<span>" + Math.trunc(min) + "</span><span>" + (minutes) + "</span>");
      $(filteredSweepElem).css('display', 'block');
    } else {
      $(elem).find(".days").html("<span>0</span><span>0</span>");
      $(elem).find(".hours").html("<span>0</span><span>0</span>");
      $(elem).find(".minutes").html("<span>0</span><span>0</span>");
      $(sweepstakeElem).find(".days").html("<span>0</span><span>0</span>");
      $(sweepstakeElem).find(".hours").html("<span>0</span><span>0</span>");
      $(sweepstakeElem).find(".minutes").html("<span>0</span><span>0</span>");
      $(sweepstakeElem2).find(".days").html("<span>0</span><span>0</span>");
      $(sweepstakeElem2).find(".hours").html("<span>0</span><span>0</span>");
      $(sweepstakeElem2).find(".minutes").html("<span>0</span><span>0</span>");
      $(filteredSweepElem).css('display', 'none');
    }
  };

  getUserTournamentPosition = (tournamentId) => {
    const { props: { user_t_positions } } = this;
    const pos = user_t_positions && user_t_positions.filter((el) => el.tournamentId === tournamentId);
    let currPos = 0;
    pos.forEach((el) => { currPos = el.position });
    if (currPos > 0) {
      if (currPos === 1) {
        return "#" + currPos + "st";
      } else if (currPos === 2) {
        return "#" + currPos + "nd";
      } else if (currPos === 3) {
        return "#" + currPos + "rd";
      } else {
        return "#" + currPos + "th";
      }
    } else {
      return "//";
    }
  };

  setThisSweepUser = (e, user) => {
    this.props.getHighscoresDispatch({ userId: user.userId });
    this.setState({ userHighscore: user });
  };

  getGameHighScore = (name) => {
    if (name && name !== undefined) {
      const gameN = name.replace(/ /g, "_").toLocaleLowerCase();
      const currentHigh = this.props.currentUserHighscores;
      if (currentHigh !== undefined) {
        const thisGameHigh = currentHigh.find((el) => el.gameName === gameN);
        if (thisGameHigh !== undefined) {
          return thisGameHigh.gameScore;
        }
      }
    }
  };

  render() {
    const {
      props: { sweepstake, games, tournaments, monthlySweepPositions, monthlytournamentpositions },
      state: { sum }
    } = this;
    const self = this;
    const filteredGames = games.filter((el) => { return (el.featured === true && el.active === true) });

    const tournamentPositions = monthlytournamentpositions;
    const sweepStakePositions = monthlySweepPositions;
    let presentGamesToShow = games.filter(el => el.active === true);
    let tournamentSum = 0;
    let tournamentsArray = [];
    if (tournaments && tournaments.length > 0) {
      tournamentsArray = tournaments;
      tournamentSum = this.getActiveTournaments();
    }

    tournamentsArray = tournamentsArray.sort((a, b) => {
      const currentTime = moment()
      if (moment(a.startDate).isBefore(currentTime)) {
        return moment(a.finishDate) - moment(b.finishDate)
      } else {
        return moment(a.startDate) - moment(b.startDate)
      }
    }).slice(0, this.state.tournamentsToShow);
    sweepstake.sort((a, b) => a.finishDate > b.finishDate ? 1 : b.finishDate > a.finishDate ? -1 : 0);
    let curr = moment();
    let filteredsweepstake = sweepstake.filter((el) => moment(el.finishDate).isAfter(curr));
    let c = 0;
    let currentSweepTime = "";
    filteredsweepstake.forEach((el) => {
      c++;
      if (c <= 1) {
        currentSweepTime = el;
        return;
      }
    });
    var sum1 = currentSweepTime.totalJackpotAmount ?? 0

    return (
      <div>
        <ResetPassword />
        <main>
          <LiveTournament />
          <Modal state={this.state} />

          <section className="block-banner tab-wrap">
            <div className="container">
              <div className="tab-content tab-content__banner">
                <div className="row align-item-center">
                  <div className="col-lg-6 wow animate__animated animate__fadeInLeft">
                    <div className="banner-l">
                      <div className="banner-text">
                        <h3><i>Sweepstake</i></h3>
                        <h2>PRIZE POOL <i>$PLY</i> <span>{sum ?? sum1}</span></h2>
                      </div>
                      <div className="banner-time">
                        <h4>Draw in</h4>
                        <div id="timer-sweepstake" className="timer" data-date={moment(this.state.nextSweepstakeFinishDate)} >
                          <div className="timer-box">
                            <div className="timer-num days">
                              <span>0</span>
                              <span>0</span>
                            </div>
                            <p>Days</p>
                          </div>
                          <div className="timer-box">
                            <div className="timer-num hours">
                              <span>0</span>
                              <span>0</span>
                            </div>
                            <p>Hours</p>
                          </div>
                          <div className="timer-box">
                            <div className="timer-num minutes">
                              <span>0</span>
                              <span>0</span>
                            </div>
                            <p>Minutes</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="banner-winners">
                      <div className="winner-img winner-img__1 active wow animate__animated animate__fadeIn">
                        <img src="images/banner/paralax-icon-6.png" alt="" data-speed="-1" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-7.png" alt="" data-speed="-0.5" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-8.png" alt="" data-speed="-0.25" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-12.png" alt="" data-speed="-0.4" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-11.png" alt="" data-speed="-0.2" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-10.png" alt="" data-speed="-0.05" className="parallax-icon" />
                        <img src="images/banner/banner-img-3.png" alt="" />
                      </div>
                      <div className="winner-img wow animate__animated animate__fadeIn">
                        <img src="images/banner/paralax-icon-6.png" alt="" data-speed="1" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-7.png" alt="" data-speed="-0.5" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-8.png" alt="" data-speed="-0.25" className="parallax-icon" />
                        <img src="images/banner/banner-img-3.png" alt="" />
                      </div>
                      <div className="winner-img wow animate__animated animate__fadeIn">
                        <img src="images/banner/paralax-icon-6.png" alt="" data-speed="1" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-7.png" alt="" data-speed="-0.5" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-8.png" alt="" data-speed="-0.25" className="parallax-icon" />
                        <img src="images/banner/banner-img-3.png" alt="" />
                      </div>
                      <div className="winner-img wow animate__animated animate__fadeIn">
                        <img src="images/banner/paralax-icon-6.png" alt="" data-speed="1" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-7.png" alt="" data-speed="-0.5" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-8.png" alt="" data-speed="-0.25" className="parallax-icon" />
                        <img src="images/banner/banner-img-3.png" alt="" />
                      </div>
                      <div className="winner-img wow animate__animated animate__fadeIn">
                        <img src="images/banner/paralax-icon-6.png" alt="" data-speed="1" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-7.png" alt="" data-speed="-0.5" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-8.png" alt="" data-speed="-0.25" className="parallax-icon" />
                        <img src="images/banner/banner-img-3.png" alt="" />
                      </div>
                      <div className="banner-winners__r">
                        <div className="top-users">
                          {sweepStakePositions &&
                            sweepStakePositions.map((user, index) => {
                              var dot = ".";
                              var ind = index;
                              var wow_delay = dot + (ind += 2);
                              // debugger
                              return (
                                <div key={index} className="winner-user wow animate__animated animate__fadeInRight" data-wow-duration={wow_delay} >
                                  <div className="user-photo" onClick={(e) => this.setThisSweepUser(e, user)} >
                                    <img src={user.imageUrl ? user.imageUrl : "assets/images/games/defaultProfile.jpg"} alt="" />
                                  </div>
                                  <div className="user-info">
                                    <h4>{user.name}</h4>
                                    <p>$PLY {user.winning}</p>
                                  </div>
                                </div>
                              );
                            })}

                          <a href="#" onClick={(e) => this.handleAllWinners(e)} className="all-users wow animate__animated animate__fadeInRight" data-wow-duration="1.2" >
                            All
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-content tab-content__banner">
                <div className="row align-item-center">
                  <div className="col-lg-6  wow animate__animated animate__fadeInLeft">
                    <div className="banner-l">
                      <div className="banner-text">
                        <h3>
                          <i>Tournaments</i>
                        </h3>
                        <h2>
                          ACTIVE JACKPOT<br /><i>$PLY</i> <span>{tournamentSum}</span>
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="banner-winners">
                      <div className="winner-img winner-img__2 wow animate__animated animate__fadeIn">
                        <img src="images/banner/paralax-icon-1.png" alt="" className="parallax-icon" data-speed="-0.3" />
                        <img src="images/banner/paralax-icon-2.png" alt="" className="parallax-icon" data-speed="-0.2" />
                        <img src="images/banner/paralax-icon-3.png" alt="" className="parallax-icon" data-speed="-0.4" />
                        <img src="images/banner/paralax-icon-4.png" alt="" className="parallax-icon" data-speed="-0.6" />
                        <img src="images/banner/paralax-icon-5.png" alt="" className="parallax-icon" data-speed="-0.26" />
                        <img src="images/banner/paralax-icon-9.png" alt="" className="parallax-icon" data-speed="-0.05" />
                        <img src="images/banner/banner-img-4.png" alt="" />
                      </div>
                      <div className="winner-img winner-img__1 wow animate__animated animate__fadeIn">
                        <img src="images/banner/paralax-icon-6.png" alt="" data-speed="1" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-7.png" alt="" data-speed="-0.5" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-8.png" alt="" data-speed="-0.25" className="parallax-icon" />
                        <img src="images/banner/banner-img-3.png" alt="" />
                      </div>
                      <div className="winner-img winner-img__1 wow animate__animated animate__fadeIn">
                        <img src="images/banner/paralax-icon-6.png" alt="" data-speed="1" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-7.png" alt="" data-speed="-0.5" className="parallax-icon" />
                        <img src="images/banner/paralax-icon-8.png" alt="" data-speed="-0.25" className="parallax-icon" />
                        <img src="images/banner/banner-img-3.png" alt="" />
                      </div>
                      <div className="winner-img winner-img__2 wow animate__animated animate__fadeIn">
                        <img src="images/banner/paralax-icon-1.png" alt="" className="parallax-icon" data-speed="-0.3" />
                        <img src="images/banner/paralax-icon-2.png" alt="" className="parallax-icon" data-speed="-0.2" />
                        <img src="images/banner/paralax-icon-3.png" alt="" className="parallax-icon" data-speed="-0.4" />
                        <img src="images/banner/paralax-icon-4.png" alt="" className="parallax-icon" data-speed="-0.6" />
                        <img src="images/banner/paralax-icon-5.png" alt="" className="parallax-icon" data-speed="-0.26" />
                        <img src="images/banner/paralax-icon-9.png" alt="" className="parallax-icon" data-speed="-0.05" />
                        <img src="images/banner/banner-img-4.png" alt="" />
                      </div>
                      <div className="winner-img winner-img__2 wow animate__animated animate__fadeIn">
                        <img src="images/banner/paralax-icon-1.png" alt="" className="parallax-icon" data-speed="-0.3" />
                        <img src="images/banner/paralax-icon-2.png" alt="" className="parallax-icon" data-speed="-0.2" />
                        <img src="images/banner/paralax-icon-3.png" alt="" className="parallax-icon" data-speed="-0.4" />
                        <img src="images/banner/paralax-icon-4.png" alt="" className="parallax-icon" data-speed="-0.6" />
                        <img src="images/banner/paralax-icon-5.png" alt="" className="parallax-icon" data-speed="-0.26" />
                        <img src="images/banner/paralax-icon-9.png" alt="" className="parallax-icon" data-speed="-0.05" />
                        <img src="images/banner/banner-img-4.png" alt="" />
                      </div>
                      <div className="banner-winners__r">
                        <div className="top-users">
                          {tournamentPositions &&
                            tournamentPositions.map((user, index) => {
                              var dot = ".";
                              var ind = index;
                              var wow_delay = dot + (ind += 2);
                              return (
                                <div
                                  key={index}
                                  className="winner-user wow animate__animated animate__fadeInRight"
                                  data-wow-duration={wow_delay}
                                  onClick={(e) =>
                                    this.setThisSweepUser(e, user)
                                  }
                                >
                                  <div className="user-photo">
                                    <img src={user.imageUrl ? user.imageUrl : "assets/images/games/defaultProfile.jpg"} alt="" />
                                  </div>
                                  <div className="user-info">
                                    <h4>{user.name}</h4>
                                    <p>$PLY {user.money || user.winning}</p>
                                  </div>
                                </div>
                              );
                            })}
                          <a
                            href="#"
                            onClick={(e) => this.handleAllWinners(e)}
                            className="all-users wow animate__animated animate__fadeInRight"
                            data-wow-duration="1.2"
                          >
                            All
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ul className="tab-menu banner-menu">
                <li className="active">
                  <span>Sweepstake</span>
                </li>
                <li>
                  <span>Tournaments</span>
                </li>
              </ul>
            </div>
          </section>

          <section className="block-feature">
            <div className="container">
              <div className="block-title block-title__violet">
                <div className="block-title__l">
                  <h2>featured games</h2>
                  <p>
                    GET LUCKY COINS TO WIN THE SWEEPSTAKE{" "}
                    <span>$PLY PRIZE</span>
                  </p>
                </div>
                <div className="block-title__r">
                  <div className="block-title__r--top">
                    <h3>
                      <span className="line-animation">Earn more</span>
                    </h3>{" "}
                    <img
                      className="coin"
                      src="images/icons/coin-icon.png"
                      alt=""
                    />
                  </div>
                  <p>SCORE POINTS TO GET LUCKY COINS</p>
                </div>
              </div>

              <div className="feature-game__slider">
                {filteredGames &&
                  filteredGames.map((element, index) => {
                    var dot = ".";
                    var ind = index;
                    var wow_delay = dot + (ind += 2);
                    return (
                      <div
                        key={index}
                        className="feature-game__col wow animate__animated animate__fadeInUp"
                        data-wow-delay={wow_delay}
                      >
                        <div className="feature-game">
                          <span className="feature-game__num">{++index}</span>
                          {localStorage.jwtToken ? (
                            <a
                              onClick={(e) => {
                                this.getGameHighScore(element.name);
                                this.setState({ normalGameobj: element });
                                window.openModale("#lucky-coins");
                              }}
                              className="feature-game__img"
                            >
                              <img src={filteredGames && filteredGames.length > 0 ? this.getFeatureGameIcon(element) : ""} alt="" />
                            </a>
                          ) : (
                            <a
                              onClick={() => {
                                this.setState({
                                  unregisteredGameId: element._id,
                                  unregisteredGameName: element.name,
                                });
                                window.openModale("#register");
                              }}
                              className="feature-game__img"
                            >
                              <img src={filteredGames && filteredGames.length > 0 ? this.getFeatureGameIcon(element) : ""} alt="" />
                            </a>
                          )}
                          <h3 className="feature-game__name">
                            <a href="#">{filteredGames && filteredGames.length > 0 ? element.name : "Test"}</a>
                          </h3>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </section>

          <section className="block-tournaments" id="tournament-scroll">
            <div className="container">
              <div className="block-title block-title__green">
                <div className="block-title__l">
                  <h2>Tournaments</h2>
                  <p>Score the most points to win <span>$PLY</span></p>
                </div>
                <div className="block-title__r">
                  <div className="block-title__r--top">
                    <h3>Active Jackpot <i>$PLY</i> <span>{tournamentSum}</span></h3>
                    <img className="money" src="images/icons/money-icon.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="row">
                {self.state.timer &&
                  tournamentsArray &&
                  tournamentsArray.map((tournamentObj, index1) => {
                    if (
                      moment(tournamentObj.startDate).tz(TIMEZONE.value).isBefore() &&
                      moment(tournamentObj.finishDate).tz(TIMEZONE.value).isAfter()
                    ) {
                      const percentageBar = self.getPercentageBar(tournamentObj);
                      return (
                        <div key={index1} className="col-lg-3 col-md-4 col-sm-6 mb60" >
                          <div className="tournament wow animate__animated animate__slideInUp" data-wow-delay=".1s">
                            <div className="tournament-jackpot">
                              <h3>$PLY <span>{tournamentObj.totalJackpotAmount}</span></h3>
                              <p>Jackpot</p>
                              <img src="images/icons/money-icon.png" alt="" />
                            </div>
                            <div className={this.getTournamentActiveClass(tournamentObj)} >
                              <img src={self.getFeatureGameIcon(tournamentObj.game && tournamentObj.game[0])} alt="" />
                            </div>
                            <div className="tournament-progress">
                              <div className="progress-line" data-hours={self.getTournamentEndTimeText(tournamentObj)} style={{ width: percentageBar }} />
                            </div>
                            <h3 className="tournament-title tournament-title__uppercase">{self.getGameName(tournamentObj)}</h3>
                            <p className="tournament-position">
                              Your position:{" "}
                              <span>{localStorage.jwtToken ? this.getUserTournamentPosition(tournamentObj._id) : "//"}</span>
                            </p>
                            {localStorage.jwtToken ? (
                              <a
                                onClick={() => {
                                  this.setState({
                                    currentTournament: tournamentObj,
                                    gameName: tournamentObj.gameName || tournamentObj.game[0].name,
                                    trn_endTime: tournamentObj.finishDate,
                                    tournamentId: tournamentObj._id,
                                    trn_modal: false,
                                    live_t_modalgame: tournamentObj.game[0],
                                  });
                                  window.liveTmodalTimer(tournamentObj.finishDate, "#live-tournament-again2");
                                  this.props.setModalTournamentDispatch(tournamentObj);
                                  window.openSliderModale('#live-tournament-again2')
                                }}
                                className="btn btn__blue btn__icon--r"
                              >
                                <span>PLAY</span> <i className="icon-play"></i>
                              </a>
                            ) : (
                              <a
                                onClick={() => {
                                  this.setState({
                                    unregisteredGameId: tournamentObj.game[0]._id,
                                    unregisteredGameName: tournamentObj.game[0].name,
                                  });
                                  window.openModale("#register");
                                }}
                                className="btn btn__blue btn__icon--r"
                              >
                                <span>PLAY</span> <i className="icon-play"></i>
                              </a>
                            )}
                          </div>
                        </div>
                      );
                    } else if (
                      moment(tournamentObj.startDate).tz(TIMEZONE.value).isAfter()
                    ) {
                      return (
                        <div key={index1} className="col-lg-3 col-md-4 col-sm-6 mb60" >
                          <div className="tournament wow animate__animated animate__slideInUp" data-wow-delay=".6s" >
                            <div className="tournament-img tournament-img__active">
                              <img src={self.getFeatureGameIcon(tournamentObj.game && tournamentObj.game[0])} alt="" />
                            </div>
                            <div className="tournament-time">
                              Starts in{" "}
                              <span>
                                {self.getTournamentStartTimeText(tournamentObj)}
                              </span>
                            </div>

                            <h3 className="tournament-title tournament-title__standard">
                              {self.getGameName(tournamentObj)}
                            </h3>
                            <p className="tournament-position">
                              Your position: <span>//</span>
                            </p>

                            {this.getNotificationStatus(tournamentObj._id) ? <a style={{ backgroundColor: '#5cb85c' }} onClick={() => this.deleteNotification(tournamentObj?._id)} className="btn btn__gray btn__icon--r">
                              <span>Notification Active</span>
                              <i className="icon-bell-o"></i>
                            </a> : <a onClick={() => this.getNotified(tournamentObj)} className="btn btn__gray btn__icon--r">
                              <span>Get Notified</span>
                              <i className="icon-bell-o"></i>
                            </a>}
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>

              {this.state.tournamentsToShow >= 8 &&
                this.state.tournamentsToShow <= tournamentsArray.length ? (
                <div className="block-btn">
                  <a onClick={() => { this.setState({ tournamentsToShow: this.state.tournamentsToShow + 8 }); }} className="btn btn__gradient" >
                    Load More
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>
          </section>

          <section className="block-games" id="all-games">
            <div className="container">
              <div className="block-title block-title__violet">
                <div className="block-title__l">
                  <h2>All Games</h2>
                  <p>
                    GET LUCKY COINS TO WIN THE SWEEPSTAKE{" "}
                    <span>$PLY PRIZE</span>
                  </p>
                </div>
                <div className="block-title__r">
                  <div className="block-title__r--top">
                    <h3>
                      <span className="line-animation">Earn more</span>
                    </h3>{" "}
                    <img className="coin" src="images/icons/coin-icon.png" alt="" />
                  </div>
                  <p>SCORE POINTS TO GET LUCKY COINS</p>
                </div>
              </div>
              <div className="row">
                {presentGamesToShow &&
                  presentGamesToShow.map((gameObj, index) => {
                    const dot = ".";
                    var ind = index;
                    var wow_delay = dot + (ind += 2);
                    return (
                      <div key={index} className="col-lg-2 col-md-3 col-sm-4 col-xs-6 mb50" >
                        <div className="game wow animate__animated animate__slideInUp" data-wow-delay={wow_delay} >
                          {localStorage.jwtToken ? (
                            <a
                              onClick={(e) => {
                                this.getGameHighScore(gameObj.name);
                                this.setState({ normalGameobj: gameObj });
                                window.openModale("#lucky-coins");
                              }}
                              className="game-img"
                            >
                              <img src={gameObj.icons[0].imageURL} alt="" />
                            </a>
                          ) : (
                            <a
                              onClick={() => {
                                this.setState({
                                  unregisteredGameId: gameObj._id,
                                  unregisteredGameName: gameObj.name,
                                });
                                window.openModale("#register");
                              }}
                              className="game-img"
                            >
                              <img src={gameObj.icons[0].imageURL} alt="" />
                            </a>
                          )}
                          <h3 className="game-name">
                            <a>{gameObj.name}</a>
                          </h3>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          </section>

          <section className="block-advantage">
            <div className="container">
              <div className="advantage-row">
                <div className="advantage-img wow animate__animated animate__swing">
                  <img src="images/other/advantage-img.png" alt="" />
                </div>
                <div className="advantage-text">
                  <h2 className="wow animate__animated animate__slideInDown">
                    are you a game <span>developer?</span> <br /> Earn with playtoo!
                  </h2>
                  <a className="btn btn__gradient wow animate__animated animate__slideInUp" href="https://qrc7o8s3mqe.typeform.com/to/tQuhMjfp?typeform-source=playtoo.io" target="_blank" >
                    apply now!
                  </a>
                </div>
              </div>
            </div>
          </section>

          <div id="congratulations" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__w600" >
            <div className="popup-box">
              <div className="popup-content">
                <h2 className="popup-title popup-title__f45">
                  congratulations
                </h2>
                <p className="congratulations-subtitle">
                  Now you can start to earn Lucky Coins or compete In our
                  tournaments to win Real $PLY
                </p>
                <div className="congratulations-text">
                  <h3>GET AN HEAD START!</h3>
                  <p>WELCOME BONUS</p>
                </div>
                <div className="congratulations-cash">
                  200{" "}
                  <span className="line-effect">
                    <img id="img-200" src="images/icons/coin-big-icon.png" alt="" />
                  </span>
                </div>
                <a id="claim_200" className="btn btn__gradient btn-congratulations" >
                  claim
                </a>
              </div>
            </div>
          </div>

          {/* <!--popup oops--> */}
          <div
            id="oops"
            style={{ display: "none" }}
            className="popup-style popup-style__text-center popup-style__pt popup-style__w600"
          >
            <div className="popup-box">
              <div className="popup-top">
                <div className="popup-top__img popup-top__img--picture">
                  <img src="assets/images/playtoo.png" alt="" />
                </div>
              </div>
              <div className="popup-content">
                <div className="popup-text">
                  <h2>OOPS…</h2>
                  <h3>YOU FORGOT TO REGISTER:</h3>
                  <p>No rewards to collect</p>
                </div>
                <div className="popup-text">
                  <p>Earn Lucky Coins and win $PLY in the next game</p>
                </div>
                <a id="register-btn-oop" className="btn-register btn btn__gradient btn__icon--r" >
                  REGISTER NOW
                  <i className="icon-person_add_alt"></i>
                </a>
                <p className="popup-thank">
                  <a className="modal_closer" style={{ cursor: "pointer" }}>MAYBE NEXT TIME</a>
                </p>
              </div>
            </div>
          </div>
          {/* <!--popup oops--> */}

          {/* <!--popup register--> */}
          <div
            id="register"
            style={{ display: "none" }}
            className="popup-style popup-style__text-center popup-style__pt popup-style__w600"
          >
            <p><button type='button' data-fancybox-close className="fancybox-close-small fancybox-button">
              <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24"><path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path></svg>
            </button></p>
            <div className="popup-box">
              <div className="popup-top">
                <div className="popup-top__img popup-top__img--logo">
                  <img src="images/logo.png" alt="" />
                </div>
              </div>
              <div className="popup-content">
                <div className="popup-text">
                  <h2>READY TO MAKE MONEY?</h2>
                  <p>Play to earn lucky coins and $PLY</p>
                </div>
                <a id="register-btn" className="btn-register btn btn__gradient btn__icon--r" >
                  REGISTER NOW<i className="icon-person_add_alt"></i>
                </a>
                <p className="popup-welcome">
                  & claim your welcome bonus{" "}
                  <img src="images/icons/coin-no-shadow-icon.png" alt="" />
                </p>
                <p className="popup-thank">
                  No thanks, I just want to
                  <br /> <a id="play-as-guest" style={{ cursor: "pointer" }}>PLAY AS GUEST</a>
                </p>
              </div>
            </div>
          </div>
          {/* <!--popup register--> */}

          {/*popup-lucky coins*/}
          <div
            id="lucky-coins"
            style={{ display: "none" }}
            className="popup-style popup-style__text-center popup-style__pt popup-style__w600"
          >
            <p><button type='button' data-fancybox-close className="fancybox-close-small fancybox-button">
              <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24"><path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path></svg>
            </button></p>
            <div className="popup-box">
              <div className="popup-top">
                <div className="popup-top__img popup-top__img--picture">
                  <img src={this.state.normalGameobj ? this.state.normalGameobj.icons[0].imageURL : ""} alt="" />
                </div>
              </div>
              <div className="popup-content ">
                {localStorage.jwtToken ? (
                  <>
                    <h2 className="lucky-coins__title">
                      GET LUCKY COINS{" "}
                      <span className="line-effect">
                        <img src="images/icons/coin-big-icon.png" alt="" />
                      </span>
                    </h2>
                    <div className="popup-timer popup-timer__mb25">
                      <h5>NEXT DRAW:</h5>
                      <div id="timer-sweepstake-2" className="timer" data-date={moment(this.state.nextSweepstakeFinishDate)} >
                        <div className="timer-box">
                          <div className="timer-num days">
                            <span>0</span>
                            <span>0</span>
                          </div>
                          <p>Days</p>
                        </div>
                        <div className="timer-box">
                          <div className="timer-num hours">
                            <span>0</span>
                            <span>0</span>
                          </div>
                          <p>Hours</p>
                        </div>
                        <div className="timer-box">
                          <div className="timer-num minutes">
                            <span>0</span>
                            <span>0</span>
                          </div>
                          <p>Minutes</p>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <br />
                  </>
                )}
                <button id="play-again" className="btn btn__blue btn__icon--r btn-play" >
                  PLAY <i className="icon-play"></i>
                </button>
                {
                  <p className="highscore">
                    Your Highscore:{" "}
                    <span>
                      {(this.props.currentUserHighscores && this.getGameHighScore(this.state.normalGameobj && this.state.normalGameobj.name)) || "0"} pts
                    </span>
                  </p>
                }
              </div>
            </div>
          </div>
          {/*popup-lucky coins*/}

          <div
            id="register-for-Notifies"
            style={{ display: "none" }}
            className="popup-style popup-style__text-center popup-style__pt popup-style__w600"
          >
            <p><button type='button' data-fancybox-close className="fancybox-close-small fancybox-button">
              <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24"><path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path></svg>
            </button></p>
            <div className="popup-box">
              <div className="popup-top">
                <div className="popup-top__img popup-top__img--logo">
                  <img src="images/logo.png" alt="" />
                </div>
              </div>
              <div className="popup-content">
                <div className="popup-text">
                  <h2>Oops...</h2>
                  <p>You need to register to have access at notifies</p>
                </div>
                <div className="popup-text">
                  <h2>READY TO MAKE MONEY?</h2>
                  <p>Play to earn lucky coins and $PLY</p>
                </div>
                <a id="register-btn-coin" className="btn-register btn btn__gradient btn__icon--r" >
                  REGISTER NOW<i className="icon-person_add_alt"></i>
                </a>
                <p className="popup-welcome">
                  & claim your welcome bonus
                  <img src="images/icons/coin-no-shadow-icon.png" alt="" />
                </p>
                <p className="popup-thank">
                  No thanks, I just want to
                  <br /> <a id="play-as-guest3" style={{ cursor: "pointer" }}>PLAY AS GUEST</a>
                </p>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentUserDispatch: () => {
    dispatch(getCurrentUser());
  },
  fetchGamesDispatch: () => {
    dispatch(getGames());
  },
  fetchTournamentsDispatch: () => {
    dispatch(getTournaments());
  },
  getTournamentPositions: (payload) => {
    dispatch(getTournamentPositions(payload));
  },
  updateSweepIoDispatch: (arr) => {
    dispatch(updateSweepIo(arr));
  },
  getHighscoresDispatch: (userId) => {
    dispatch(getHighscores(userId));
  },
  setUserCoinsDispatch: (coins) => {
    dispatch(setUserCoins(coins));
  },
  setModalTournamentDispatch: (tobj) => {
    dispatch(setModalTournament(tobj));
  },
  getNotificationsDispatch: (obj) => {
    dispatch(getNotification(obj))
  },
  deleteNotificationDispatch: (obj) => {
    dispatch(deleteNotification(obj))
  }
});

const mapStateToProps = (state) => ({
  sweepstake: state.sweepstake.sweepstakes ? state.sweepstake.sweepstakes : [],
  games: state.sweepstake.games ? state.sweepstake.games : [],
  tournaments: state.sweepstake.tournaments ? state.sweepstake.tournaments : [],
  nextSweepstakeDate: state.sweepstake.nextSweepstakeDate ? state.sweepstake.nextSweepstakeDate : null,
  user: state.auth ? state.auth.user : null,
  auth: state.auth,
  loading: state.profile ? state.profile.loading : true,
  userPositions: state.sweepstake.tournamentUserPos ? state.sweepstake.tournamentUserPos : [],
  positions: state.tournament.userPositions ? state.tournament.userPositions : [],
  sweepPositions: state.sweepstake.sweepstakeWinners ? state.sweepstake.sweepstakeWinners : [],
  you: state.tournament.yourPosition ? state.tournament.yourPosition : {},
  highscores: state.game.userHighscores ? state.game.userHighscores : [],
  highscorerProfile: state.game.highscorer ? state.game.highscorer : {},
  monthlySweepPositions: state.sweepstake.monthlySweepPositions ? state.sweepstake.monthlySweepPositions : [],
  monthlytournamentpositions: state.tournament.monthlytournamentpositions ? state.tournament.monthlytournamentpositions : [],
  currentUserHighscores: state.auth ? state.auth.currentUserHighscores : [],
  user_t_positions: state.auth.user.tournamentScores ? state.auth.user.tournamentScores : [],
  modalTournament: state.tournament.tournamentModal ? state.tournament.tournamentModal : {},
  notifications: state.auth.user.notifications ? state.auth.user.notifications : [],
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
