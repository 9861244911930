/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
// import {Navigation} from 'swiper
import $ from "jquery";
import { doubleTournamentCoins, getHighscores, setUserMoney } from "../../actions";
import { doubleUserCoins } from "../../actions";
import socket from './../../helpers/socket'
// let aiptag = window.aiptag || { cmd: [] };
import { getUserData } from "../../actions";
import { coinsAnimation, moneyAnimation } from "../../helpers/animations";
export class tournamentEnded extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps) {
  }

  componentDidMount() {
    $('#tournament-win2').on('click', '.userHighscoresClick', (e) => { this.setThisSweepUser(e) })
    $('#tournament-win2').on('click', '#collect-no-money', () => { this.gameResumeFunct() })
    $('#resumer06').on('click', this.gameResumeFunct2)
  }

  setThisSweepUser = (e) => {
    // debugger
    const userId = e.target.dataset.id
    this.props.getHighscoresDispatch({ userId: userId })
  }

  gameResumeFunct2 = (e) => {
    const tournamentId = this.props.yourPosition ? this.props.yourPosition.tournamentId : ''
    socket.emit("CLAIMED", { tournamentId: tournamentId })
    const target = document.querySelector('#money-img')
    const totalMoney = Number(this.props.yourPosition.winning) + Number(this.props.user.money)
    setTimeout(() => {
      this.props.setUserMoneyDispatch(totalMoney)
    }, 4000)
    moneyAnimation(target, this.props.yourPosition.winning, this.props.user.money, "TOURNAMENT_MONEY")
  }

  gameResumeFunct = () => {
    const tournamentId = this.props.yourPosition ? this.props.yourPosition.tournamentId : ''
    socket.emit("CLAIMED", { tournamentId: tournamentId })
    // this.props.fetchCurrentUserDispatch()
    const target = document.querySelector('#tour-coin')
    coinsAnimation(target, this.props.user?.coins, 40, "TOUR_END", this.props.router)

  }

  render() {
    const {
      props: { tour_positions, yourPosition, user, tour_img },
    } = this;
    // console.log("props tour_positions",this.props.tour_positions)
    return (
      <div
        id="tournament-win2"
        style={{ display: "none" }}
        className="popup-style popup-style__text-center popup-style__pt popup-style__w600"
      >
        <div className="popup-box">
          <div className="popup-top">
            <div className="popup-top__img popup-top__img--picture">
              <img src={tour_img && tour_img.imageURL ? tour_img.imageURL : "assets/images/playtoo.png"} alt="" />
            </div>
          </div>
          <div className="popup-content end-tournament">
            <h2 className="popup-title popup-title__f45">TOURNAMENT ENDED</h2>
            <div className="popup-text">
              {yourPosition.winning === undefined ? (<p>TRY HARDER NEXT TIME!</p>) : ("")}
            </div>
            <div id="tEnd01" className="slider-users style-slider">
              <div className="slider-users__col">
                <div className="user-item user-item__my">
                  <div className="user-item__photo">
                    <img src={yourPosition.userId && yourPosition.userId.imageUrl ? yourPosition.userId.imageUrl : "assets/images/games/defaultProfile.jpg"} alt="" />
                    <div className="user-item__label">
                      {yourPosition.userPosition}p
                    </div>
                  </div>
                  <h3 className="user-item__name">You</h3>
                  <div className="user-item__points">{yourPosition.score}pts</div>
                </div>
              </div>
              {tour_positions &&
                tour_positions.map((el) => {
                  // debugger
                  if ((el.userId && el.userId._id) !== user._id) {
                    return (
                      <>
                        <div className="slider-users__col">
                          <div className="user-item">
                            <div className="user-item__photo">
                              <img
                                src={el.userId && el.userId.imageUrl ? el.userId.imageUrl : "assets/images/games/defaultProfile.jpg"}
                                alt=""
                                className="userHighscoresClick"
                                data-id={el.userId && el.userId._id}
                              />
                              <div className="user-item__label">
                                {el.userPosition}p
                              </div>
                            </div>
                            <h3 className="user-item__name">{el.name}</h3>
                            <div className="user-item__points">{el.score}pts</div>
                          </div>
                        </div>
                      </>
                    );
                  }
                })}
            </div>

            {yourPosition && yourPosition.winning === 0 ? (
              // ||
              // Object.keys(this.props.t_you).length < 0
              <>
                <div className="tournament-win">
                  <p className="tournament-scored">
                    ENTRY BONUS <span className="yellow">+40</span>
                  </p>
                  <span className="line-effect">
                    <img id="tour-coin" src="images/icons/coin-big-icon.png" alt="" />
                  </span>
                </div>
                <p className="popup-thank mt20">
                  <a id="collect-no-money" >CLAIM</a>
                </p>
              </>
            ) : (
              <>
                <br />
                <div className="tournament-win">
                  <br />
                  <p className="tournament-scored">
                    You WON:<span className="green">$PLY {yourPosition.winning ? yourPosition.winning : "0"}</span>
                  </p>
                  <span className="line-effect">
                    <img id='money-img' src="images/icons/cash-icon.png" alt="" />
                  </span>
                </div>
                <a id='resumer06' className="btn btn__gradient btn-collect" >COLLECT</a>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  doubleUserCoins: (payload) => {
    dispatch(doubleUserCoins(payload));
  },
  fetchCurrentUserDispatch: () => {
    dispatch(getUserData());
  },
  getHighscoresDispatch: (userId) => {
    dispatch(getHighscores(userId));
  },
  doubleTournamentCoinsDispatch: (bool) => {
    dispatch(doubleTournamentCoins(bool))
  },
  setUserMoneyDispatch: (number) => {
    dispatch(setUserMoney(number))
  }
});
const mapStateToProps = (state) => ({
  user: state.auth ? state.auth.user : null,
  tour_img: state.tournament.tournamentEnded?.tournamentImg ? state.tournament.tournamentEnded?.tournamentImg : {},
  tour_positions: state.tournament.tournamentEnded?.topWinners ? state.tournament.tournamentEnded?.topWinners : [],
  yourPosition: state.tournament.tournamentEnded?.userPosition? state.tournament.tournamentEnded?.userPosition: {},
  router: state.router.location ? state.router.location : []
});
export default connect(mapStateToProps, mapDispatchToProps)(tournamentEnded);
