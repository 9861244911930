import React, { Component } from "react";
import $ from "jquery";
let aiptag = window.aiptag || { cmd: [] };

class AdChecker extends Component {
  componentDidUpdate() {
    if (window.aiptag !== undefined) {
      // debugger
    }
  }
  componentDidMount() {
    // debugger
    aiptag.cmd.player = aiptag.cmd.player || [];
    // debugger
    //CMP tool settings
    aiptag.cmp = {
      show: true,
      position: "centered", //centered, bottom
      button: true,
      buttonText: "Privacy settings",
      buttonPosition: "bottom-left", //bottom-left, bottom-right, top-left, top-right
    };

    aiptag.cmd.player.push(function () {
      // debugger
      aiptag.adplayer = new window.aipPlayer({
        AD_WIDTH: 960,
        AD_HEIGHT: 540,
        AD_DISPLAY: "fullscreen", //default, fullscreen, center, fill
        LOADING_TEXT: "loading advertisement",
        PREROLL_ELEM: function () {
          return document.getElementById("preroll");
        },
        AIP_COMPLETE: function (evt) {
          console.log("AD COMPLETE");
          // ******************
          // ***** WARNING *****
          // *******************
          // Please do not remove the PREROLL_ELEM
          // from the page, it will be hidden automaticly.
          // If you do want to remove it use the AIP_REMOVE callback.
          // window.myFunct2()
          // alert("Preroll Ad Completed: " + evt);
          // document.getElementById("myIframe").contentWindow.adComplete();
          // document.getElementById("myIframe").contentWindow.sound(true);
          // self.hideShowOverlay("none", "game-over-section", "game-over-section-1");
        },
        AIP_REMOVE: function () {
          // Here it's save to remove the PREROLL_ELEM from the page if you want. But it's not recommend.
        },
      });
    });
    // debugger
  }

  watchRewardAd = (e) => {
    //e.preventDefault()
    // document.getElementById("myIframe").contentWindow.sound(false);
    console.log("watchRewardAd");
    if (typeof aiptag.adplayer !== undefined) {
      console.log("inside add");
      aiptag.cmd.player.push(function () {
        aiptag.adplayer.startPreRoll();
      });
    } else {
      //Adlib didnt load this could be due to an adblocker, timeout etc.
      //Please add your script here that starts the content, this usually is the same script as added in AIP_COMPLETE or AIP_REMOVE.
      // alert("Ad Could not be loaded, load your content here");
    }

    //document.getElementById("preroll").contentWindow.adComplete(true);
    // hideShowOverlay("none", "game-over-section", "game-over-section-1");
  };

  render() {
    return (
      <div>
        {/* <button onClick={(e) => this.watchRewardAd(e)}>WATCH AD</button> */}

        <div id="preroll"></div>
      </div>
    );
  }
}

export default AdChecker;
