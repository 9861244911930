import React, { Component } from 'react'
import { connect } from "react-redux";


 class ScrollToTop extends Component {

    componentDidUpdate(prevProps) {
        // debugger
        if (this.props.router.pathname !== prevProps.router.pathname) {
            // debugger
          window.scrollTo(0, 0)
        }
      }
    render() {
         return this.props.children
    }
}
const mapStateToProps = (state) => ({
    router : state.router.location ? state.router.location : [],
  });
  
  export default connect(mapStateToProps)(ScrollToTop);