/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import Footer from '../common/footer/footer'
export default class Popups extends Component {

  render() {
    return (
      <div>
        <header>
          <div className="header-row">
            <a href="#" className="header-logo"><img src="images/logo.png" alt="" /></a>
            <nav className="header-nav">
              <ul className="header-menu">
                <li><a href="#">Play</a></li>
                <li><a href="#">Winners</a></li>
                <li><a href="#">HOW TO WIN</a></li>
                <li><a href="#">Tournaments</a></li>
                <li><a href="#">Developers</a></li>
              </ul>
              <div className="header-btn">
                <a href="#" className="btn btn__blue">Download app</a>
              </div>
              <div className="header-account">
                <div className="account-money">
                  <img src="images/icons/coin-icon.png" alt="" />
                  15,000
                </div>
                <div className="account-money">
                  <img src="images/icons/money-icon.png" alt="" />
                  $27
                </div>
                <a href="#" className="header-user"><img src="images/photos/photo-9.jpg" alt="" /></a>
              </div>
            </nav>
            <div className="burger"><span></span></div>
          </div>
        </header>

        <main>
          <ul className="list-popups">
            <li><a href="#" data-fancybox data-src="#register">Register</a></li>
            <li><a href="#" data-fancybox data-src="#oops">Oops</a></li>
            <li><a href="#" data-fancybox data-src="#register-soc">register via soc</a></li>
            <li><a href="#" data-fancybox data-src="#register-form">register form</a></li>
            <li><a href="#" data-fancybox data-src="#login">login</a></li>
            <li><a href="#" data-fancybox data-src="#forgot-password">FORGOT PASSWORD</a></li>
            {/* <li><a href="#" data-fancybox data-src="#email-confirmed">email confirmed</a></li> */}
            <li><a href="#" data-fancybox data-src="#congratulations">CONGRATULATIONS</a></li>
            <li><a href="#" data-fancybox data-src="#valid-country">platform in country</a></li>
            <li><a href="#" data-fancybox data-src="#highscores">Highscores</a></li>
            <li><a href="#" data-fancybox data-src="#popup-account">popup account</a></li>
            <li><a href="#" data-fancybox data-src="#you-highscores">you Highscores</a></li>
            <li><a href="#" data-fancybox data-src="#withdraw-money">withdraw money</a></li>
            <li><a href="#" data-fancybox data-src="#withdraw-no-money">withdraw money (not money)</a></li>
            <li><a href="#" data-fancybox data-src="#withdraw-amount">withdraw amount</a></li>
            <li><a href="#" data-fancybox data-src="#back-profile">back profile</a></li>
            <li><a href="#" data-fancybox data-src="#new-nickname">new nickname</a></li>
            <li><a href="#" data-fancybox data-src="#withdraw-history">withdraw history</a></li>
            <li><a href="#" data-fancybox data-src="#change-password">change password</a></li>
            <li><a href="#" data-fancybox data-src="#popup-account-2">popup account 2</a></li>
            <li><a href="#" data-fancybox data-src="#cash-prizes">$PLY prizes</a></li>
            <li><a href="#" data-fancybox data-src="#cash-prizes-play">$PLY prizes (play)</a></li>
            <li><a href="#" data-fancybox data-src="#live-tournament">live tournament</a></li>
            <li><a href="#" data-fancybox data-src="#current-info">current info</a></li>
            <li><a href="#" data-fancybox data-src="#keep-playing">keep playing</a></li>
            <li><a href="#" data-fancybox data-src="#reward">reward</a></li>
            <li><a href="#" data-fancybox data-src="#live-tournament-again">live tournament(play again)</a></li>
            <li><a href="#" data-fancybox data-src="#tournament-win">TOURNAMENT ENDED(won)</a></li>
            <li><a href="#" data-fancybox data-src="#tournament-bonus">TOURNAMENT ENDED(bonus)</a></li>
            <li><a href="#" data-fancybox data-src="#lucky-coins">GET LUCKY COINS</a></li>
            <li><a href="#" data-fancybox data-src="#current-score">current score</a></li>
            <li><a href="#" data-fancybox data-src="#sweepstake-end-bonus">sweepstake ended (bonus)</a></li>
            <li><a href="#" data-fancybox data-src="#sweepstake-end-win">sweepstake ended (won)</a></li>
          </ul>
        </main>

        {/* <!--popup register--> */}
        <div id="register" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__pt popup-style__w600">
          <div className="popup-box">
            <div className="popup-top">
              <div className="popup-top__img popup-top__img--logo">
                <img src="images/logo.png" alt="" />
              </div>
            </div>
            <div className="popup-content">
              <div className="popup-text">
                <h2>READY TO MAKE MONEY?</h2>
                <p>Play to earn lucky coins and $PLY</p>
              </div>
              <a href="#" data-fancybox data-src="#register-form" className="btn-register btn btn__gradient btn__icon--r">REGISTER NOW <i className="icon-person_add_alt"></i></a>
              <p className="popup-welcome">& claim your welcome bonus <img src="images/icons/coin-no-shadow-icon.png" alt="" /></p>
              <p className="popup-thank">No thanks, I just want to<br /> <a href="#">PLAY AS GUEST</a></p>
            </div>
          </div>
        </div>
        {/* <!--popup oops--> */}
        <div id="oops" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__pt popup-style__w600">
          <div className="popup-box">
            <div className="popup-top">
              <div className="popup-top__img popup-top__img--picture">
                <img src="images/games/game-1.jpg" alt="" />
              </div>
            </div>
            <div className="popup-content">
              <div className="popup-text">
                <h2>OOPS…</h2>
                <h3>YOU FORGOT TO REGISTER:</h3>
                <p>No rewards to collect</p>
              </div>
              <div className="popup-text">
                <p>Earn Lucky Coins and win $PLY in the next game</p>
              </div>
              <a href="#" className="btn-register btn btn__gradient btn__icon--r">
                REGISTER NOW
                <i className="icon-person_add_alt"></i>
              </a>
              <p className="popup-thank"><a href="#" style={{cursor:"pointer"}}>MAYBE NEXT TIME</a></p>
            </div>
          </div>
        </div>
        {/* <!--popup form login--> */}
        <div id="login" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__w600">
          <div className="popup-box">
            <div className="popup-content">
              <h2 className="popup-title popup-title__f45">LOGIN</h2>
              <div className="style-form register-form">
                <form action="">
                  <div className="field field-icon">
                    <i className="icon-mail-envelope-closed"></i>
                    <input type="email" placeholder="Email" />
                  </div>
                  <div className="field field-icon">
                    <i className="icon-lock"></i>
                    <input type="password" placeholder="Password" />
                    <span className="toggle-password icon-eye"></span>
                  </div>
                  <button className="btn btn__blue btn__icon--r" type="submit">
                    LOG IN
                    <i className="icon-lock"></i>
                  </button>
                </form>
              </div>
              <p className="register-text">OR</p>
              <div className="register-soc">
                <a className="register-facebook" href="#"><img src="images/icons/facebook-icon.png" alt="" /></a>
                <a className="register-google" href="#"><img src="images/icons/google-icon.png" alt="" /></a>
              </div>
              <p className="popup-bottom__link"><a href="#" data-fancybox data-src="#forgot-password" >Forgot password?</a></p>
            </div>
          </div>
        </div>
        {/* <!--popup form change password--> */}
        <div id="forgot-password" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__w600">
          <div className="popup-box">
            <div className="popup-content">
              <h2 className="popup-title popup-title__f45">FORGOT PASSWORD</h2>
              <div className="style-form register-form">
                <form action="">
                  <div className="field field-icon">
                    <i className="icon-mail-envelope-closed"></i>
                    <input type="email" placeholder="Email" />
                  </div>
                  <button className="btn btn__blue" type="submit">
                    reset
                  </button>
                </form>
              </div>
              <p className="popup-bottom__link"><a href="#">We are gonna send you a confirmation email be sure
                to check your spam folder.</a></p>
            </div>
          </div>
        </div>
        {/* <!--popup congratulations--> */}
        <div id="congratulations" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__w600">
          <div className="popup-box">
            <div className="popup-content">
              <h2 className="popup-title popup-title__f45">congratulations</h2>
              <p className="congratulations-subtitle">Now you can start to earn Lucky Coins or compete
                In our tournaments to win Real $PLY</p>
              <div className="congratulations-text">
                <h3>GET AN HEAD START!</h3>
                <p>WELCOME BONUS</p>
              </div>
              <div className="congratulations-cash">
                200 <span className="line-effect"><img src="images/icons/coin-big-icon.png" alt="" /></span>
              </div>
              <a href="#" className="btn btn__gradient btn-congratulations">claim</a>
            </div>
          </div>
        </div>
        {/* <!--popup platform in country--> */}
        <div id="valid-country" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__pt popup-style__w600">
          <div className="popup-box">
            <div className="popup-top">
              <div className="popup-top__img popup-top__img--logo">
                <img src="images/logo.png" alt="" />
              </div>
            </div>
            <div className="popup-content">
              <h2 className="popup-title popup-title__f22">Our platform is not currently available in your country</h2>
              <p className="congratulations-subtitle">To know when we will release our platform in your
                country  feel free to subscribe to our newsletter</p>
              <div className="style-form register-form">
                <form action="">
                  <div className="field field-icon">
                    <i className="icon-mail-envelope-closed"></i>
                    <input type="email" placeholder="Email" />
                  </div>
                  <button className="btn btn__blue btn__icon--r" type="submit">
                    submit
                    <i className="icon-arrow-right"></i>
                  </button>
                </form>
              </div>
              <p className="popup-thank">Already registered?<br /> <a href="#">LOG IN HERE</a></p>
            </div>
          </div>
        </div>
        {/* <!--popup popup account--> */}
        <div id="popup-account" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__pt popup-style__w600">
          <div className="popup-box">
            <div className="popup-top">
              <div className="popup-top__img popup-top__img--picture">
                <img src="images/games/game-1.jpg" alt="" />
              </div>
            </div>
            <div className="popup-content">
              <div className="popup-account__nickaname">
                <div className="change-nickname">
                  <span className="btn-change__nickname"><i className="icon-pen-angled"></i></span>
                  <input type="text" value="nickname" disabled />
                </div>
                <p>email@gmail.com</p>
              </div>
              <h3 className="title-balance">BALANCE</h3>
              <div className="wrap-balance">
                <div className="box-balance-col">
                  <div className="box-balance box-balance__coin">
                    <img src="images/icons/coin-no-shadow-icon.png" alt="" />
                    <h4>LUCKY COINS</h4>
                    <p>161827 <i className="icon-plus"></i></p>
                  </div>
                </div>
                <div className="box-balance-col">
                  <div className="box-balance box-balance__money">
                    <img src="images/icons/cash-icon.png" alt="" />
                    <h4>MONEY</h4>
                    <p>$4.85 <i className="icon-plus"></i></p>
                  </div>
                </div>
              </div>
              <div className="popup-account__buttons">
                <a href="#" className="btn btn__gray">HIGH SCORES</a>
                <a href="#" className="btn btn__gradient">WITHDRAW</a>
              </div>
              <div className="popup-account__links">
                <a href="#">WITHDRAW HISTORY</a>
                <a href="#">CHANGE PASSWORD</a>
              </div>
              <p className="link-bottom">
                <a href="#">LOG OUT</a>
              </p>
            </div>
          </div>
        </div>
        {/* <!--popup highscores--> */}
        <div id="you-highscores" style={{ display: "none" }} className="popup-style popup-style__w600">
          <div className="popup-box">
            <div className="popup-content">
              <h3 className="title-results">Highscores</h3>
              <div className="box-highscores box-highscores__scroll">
                <div className="game-result">
                  <div className="game-result__img">
                    <img src="images/games/game-4.jpg" alt="" />
                  </div>
                  <div className="game-result__info">
                    <h3>Space travel</h3>
                    <p>62</p>
                  </div>
                </div>
                <div className="game-result">
                  <div className="game-result__img">
                    <img src="images/games/game-7.jpg" alt="" />
                  </div>
                  <div className="game-result__info">
                    <h3>Space travel</h3>
                    <p>62</p>
                  </div>
                </div>
                <div className="game-result">
                  <div className="game-result__img">
                    <img src="images/games/game-14.jpg" alt="" />
                  </div>
                  <div className="game-result__info">
                    <h3>Space travel</h3>
                    <p>62</p>
                  </div>
                </div>
                <div className="game-result">
                  <div className="game-result__img">
                    <img src="images/games/game-2.jpg" alt="" />
                  </div>
                  <div className="game-result__info">
                    <h3>Space travel</h3>
                    <p>62</p>
                  </div>
                </div>
                <div className="game-result">
                  <div className="game-result__img">
                    <img src="images/games/game-10.jpg" alt="" />
                  </div>
                  <div className="game-result__info">
                    <h3>Space travel</h3>
                    <p>62</p>
                  </div>
                </div>
                <div className="game-result">
                  <div className="game-result__img">
                    <img src="images/games/game-6.jpg" alt="" />
                  </div>
                  <div className="game-result__info">
                    <h3>Space travel</h3>
                    <p>62</p>
                  </div>
                </div>
                <div className="game-result">
                  <div className="game-result__img">
                    <img src="images/games/game-11.jpg" alt="" />
                  </div>
                  <div className="game-result__info">
                    <h3>Space travel</h3>
                    <p>62</p>
                  </div>
                </div>
                <div className="game-result">
                  <div className="game-result__img">
                    <img src="images/games/game-12.jpg" alt="" />
                  </div>
                  <div className="game-result__info">
                    <h3>Space travel</h3>
                    <p>62</p>
                  </div>
                </div>
                <div className="game-result">
                  <div className="game-result__img">
                    <img src="images/games/game-8.jpg" alt="" />
                  </div>
                  <div className="game-result__info">
                    <h3>Space travel</h3>
                    <p>62</p>
                  </div>
                </div>
                <div className="game-result">
                  <div className="game-result__img">
                    <img src="images/games/game-13.jpg" alt="" />
                  </div>
                  <div className="game-result__info">
                    <h3>Space travel</h3>
                    <p>62</p>
                  </div>
                </div>
                <div className="game-result">
                  <div className="game-result__img">
                    <img src="images/games/game-7.jpg" alt="" />
                  </div>
                  <div className="game-result__info">
                    <h3>Space travel</h3>
                    <p>62</p>
                  </div>
                </div>
                <div className="game-result">
                  <div className="game-result__img">
                    <img src="images/games/game-14.jpg" alt="" />
                  </div>
                  <div className="game-result__info">
                    <h3>Space travel</h3>
                    <p>62</p>
                  </div>
                </div>
                <div className="game-result">
                  <div className="game-result__img">
                    <img src="images/games/game-2.jpg" alt="" />
                  </div>
                  <div className="game-result__info">
                    <h3>Space travel</h3>
                    <p>62</p>
                  </div>
                </div>
                <div className="game-result">
                  <div className="game-result__img">
                    <img src="images/games/game-10.jpg" alt="" />
                  </div>
                  <div className="game-result__info">
                    <h3>Space travel</h3>
                    <p>62</p>
                  </div>
                </div>
                <div className="game-result">
                  <div className="game-result__img">
                    <img src="images/games/game-6.jpg" alt="" />
                  </div>
                  <div className="game-result__info">
                    <h3>Space travel</h3>
                    <p>62</p>
                  </div>
                </div>
                <div className="game-result">
                  <div className="game-result__img">
                    <img src="images/games/game-11.jpg" alt="" />
                  </div>
                  <div className="game-result__info">
                    <h3>Space travel</h3>
                    <p>62</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--popup withdraw money--> */}
        <div id="withdraw-money" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__w600">
          <div className="popup-box">
            <div className="popup-content withdraw-money">
              <div className="withdraw-img line-effect">
                <img src="images/icons/dollar-icon.png" alt="" />
              </div>
              <h2>withdraw money</h2>
              <h3>Reach $2 to withdraw money</h3>
              <div className="withdraw-balance">
                <h5>My Balance</h5>
                <p>$4.85 <img src="images/icons/cash-icon.png" alt="" /></p>
              </div>
              <a href="#" className="btn btn__gradient btn-withdraw">WITHDRAW</a>
              <div className="withdraw-pay">
                <img src="images/icons/pay-pal-gray.png" alt="" />
                <img src="images/icons/google-pay-gray.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div id="withdraw-no-money" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__w600">
          <div className="popup-box">
            <div className="popup-content withdraw-money">
              <div className="withdraw-img line-effect">
                <img src="images/icons/dollar-icon.png" alt="" />
              </div>
              <h2>withdraw money</h2>c
              <h3>Reach $2 to withdraw money</h3>
              <div className="withdraw-balance">
                <h5>My Balance</h5>
                <p>$1.14 <img src="images/icons/cash-icon.png" alt="" /></p>
              </div>
              <a href="#" className="btn btn__gray">not enough money</a>
              <div className="withdraw-pay">
                <img src="images/icons/pay-pal-gray.png" alt="" />
                <img src="images/icons/google-pay-gray.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* <!--popup withdraw amount--> */}
        <div id="withdraw-amount" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__w600">
          <div className="popup-box">
            <div className="popup-content">
              <div className="style-form">
                <form action="">
                  <div className="form-pay">
                    <label className="pay">
                      <input type="radio" name="pay" />
                      <span className="pay-img">
                        <img src="images/icons/pay-pal.png" alt="" />
                      </span>
                    </label>
                    <label className="pay">
                      <input type="radio" name="pay" />
                      <span className="pay-img">
                        <img src="images/icons/google-pay.png" alt="" />
                      </span>
                    </label>
                  </div>
                  <h2>withdraw amount</h2>
                  <div className="field field-icon">
                    <input type="text" />
                  </div>
                  <p className="form-balance">Your balance: $4.85</p>
                  <div className="field field-icon">
                    <i className="icon-mail-envelope-closed"></i>
                    <input type="email" placeholder="Payment email" />
                  </div>
                  <button className="btn btn__blue btn__icon--r" type="submit">
                    send request
                    <i className="icon-arrow-right"></i>
                  </button>
                </form>
              </div>
              <p className="popup-bottom__link">*Payment fees might be deducted according to your payment method
                **You will receive the payment in maximum 7 days</p>
            </div>
          </div>
        </div>
        {/* <!--popup withdraw amount--> */}
        <div id="back-profile" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__w600">
          <div className="popup-box">
            <div className="popup-content">
              <div className="popup-text">
                <h2>congratulations</h2>
                <p>YOU’VE SENT A WITHDRAWAL REQUEST</p>
              </div>
              <p className="congratulations-subtitle">Payment fees might be deducted according to your payment
                method You will receive the payment in maximum 7 days
                You will see the amount in “pending” until the delivery
                process will be complete</p>
              <a href="#" className="btn btn__blue btn__icon--r btn-back">BACK TO PROFILE <i className="icon-arrow-left"></i></a>
            </div>
          </div>
        </div>
        {/* <!--popup new nickname--> */}
        <div id="new-nickname" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__w600">
          <div className="popup-box">
            <div className="popup-content">
              <h2 className="popup-title popup-title__f45">new nickname</h2>
              <div className="style-form register-form">
                <form action="">
                  <div className="field">
                    <input type="text" />
                  </div>
                  <button className="btn btn__blue btn__icon--r" type="submit">
                    confirm
                    <i className="icon-arrow-right"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <!--popup withdraw history--> */}
        <div id="withdraw-history" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__w600">
          <div className="popup-box">
            <div className="popup-content">
              <h2 className="popup-title popup-title__f45">withdraw history</h2>
              <div className="withdraw-history">
                <table>
                  <tbody>
                    <tr>
                      <th>Date</th>
                      <th>AMOUNT</th>
                    </tr>
                    <tr>
                      <td>01 Jan 21</td>
                      <td>$3.68</td>
                    </tr>
                    <tr>
                      <td>31 Dec 20</td>
                      <td>$2.01</td>
                    </tr>
                    <tr>
                      <td>21 Nov 20</td>
                      <td>$7.33</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <a href="#" className="btn btn__blue btn__icon--r btn-history">okay <i className="icon-checkmark"></i></a>
            </div>
          </div>
        </div>
        {/* <!--popup change password--> */}
        <div id="change-password" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__w600">
          <div className="popup-box">
            <div className="popup-content">
              <h2 className="popup-title popup-title__f45">CHANGE PASSWORD</h2>
              <div className="style-form register-form">
                <form action="">
                  <div className="field field-icon">
                    <i className="icon-lock"></i>
                    <input type="password" placeholder="Current password" />
                    <span className="toggle-password icon-eye"></span>
                  </div>
                  <div className="field field-icon">
                    <i className="icon-lock"></i>
                    <input type="password" placeholder="New password" />
                    <span className="toggle-password icon-eye"></span>
                  </div>
                  <div className="field field-icon">
                    <i className="icon-lock"></i>
                    <input type="password" placeholder="Confirm password" />
                    <span className="toggle-password icon-eye"></span>
                  </div>
                  <button className="btn btn__blue btn__icon--r" type="submit">
                    confirm
                    <i className="icon-arrow-right"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <!--popup popup account--> */}
        <div id="popup-account-2" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__pt popup-style__w600">
          <div className="popup-box">
            <div className="popup-top">
              <div className="popup-top__img popup-top__img--picture">
                <img src="images/games/game-1.jpg" alt="" />
              </div>
            </div>
            <div className="popup-content">
              <div className="popup-account__nickaname">
                <div className="change-nickname">
                  <span className="btn-change__nickname"><i className="icon-pen-angled"></i></span>
                  <input type="text" value="nickname" disabled />
                </div>
                <p>email@gmail.com</p>
              </div>
              <h3 className="title-balance">BALANCE</h3>
              <div className="wrap-balance">
                <div className="box-balance-col">
                  <div className="box-balance box-balance__coin">
                    <img src="images/icons/coin-no-shadow-icon.png" alt="" />
                    <h4>TICKETS</h4>
                    <p>161827 <i className="icon-plus"></i></p>
                  </div>
                </div>
                <div className="box-balance-col">
                  <div className="box-balance box-balance__money" data-pending="4.85">
                    <img src="images/icons/cash-icon.png" alt="" />
                    <h4>MONEY</h4>
                    <p>$0 <i className="icon-plus"></i></p>
                  </div>
                  <p className="balance-note">( Pending - $4.85 )</p>
                </div>
              </div>
              <div className="popup-account__buttons">
                <a href="#" className="btn btn__gray">HIGH SCORES</a>
                <a href="#" className="btn btn__gradient">WITHDRAW</a>
              </div>
              <div className="popup-account__links">
                <a href="#">WITHDRAW HISTORY</a>
                <a href="#">CHANGE PASSWORD</a>
              </div>
              <p className="link-bottom">
                <a href="#">LOG OUT</a>
              </p>
            </div>
          </div>
        </div>
        {/* <!--popup cash prizes--> */}
        <div id="cash-prizes" style={{ display: "none" }} className="popup-style popup-style__w900">
          <div className="popup-box">
            <div className="popup-content cash-prizes tab-wrap">
              <h2 className="popup-title popup-title__f45">$PLY prizes every day PLAY FOR <span>FREE</span></h2>
              <ul className="tab-menu table-menu">
                <li className="active"><span>SWEEPSTAKES</span></li>
                <li><span>TOURNAMENTS</span></li>
              </ul>
              <div className="tab-content">
                <div className="cash-prizes__row">
                  <div className="cash-prizes__l">
                    <img src="images/other/cash-img-1.png" alt="" />
                  </div>
                  <div className="cash-prizes__r">
                    <div className="cash-prizes__text">
                      <h2>FEELING LUCKY?</h2>
                      <h3>JOIN THE SWEEPSTAKES</h3>
                      <ul>
                        <li>Play games and earn “Lucky Coins”</li>
                        <li>The more Lucky Coin you have, the more chances you have to win our sweepstakes.</li>
                      </ul>
                    </div>
                    <div className="active-prize">
                      <div className="active-prize__text">
                        <h3>ACTIVE PRIZE POOL</h3>
                        <p>Next DRAW in: <i className="icon-timer"></i> 2d 18h 16m</p>
                      </div>
                      <div className="active-prize__bon">
                        $800 <img src="images/icons/cash-icon.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cash-bon">
                  <div className="cash-prizes__box">
                    <div className="cash-prizes__bonus">
                      <p>$250 <img src="images/icons/cash-icon.png" alt="" /></p>
                      <h5>1° Prize</h5>
                    </div>
                  </div>
                  <div className="cash-places">
                    <div className="place">
                      <p>$150 <img src="images/icons/cash-icon.png" alt="" /></p>
                      <h5>2°</h5>
                    </div>
                    <div className="place">
                      <p>$100 <img src="images/icons/cash-icon.png" alt="" /></p>
                      <h5>3°</h5>
                    </div>
                    <div className="place">
                      <p>$20 <img src="images/icons/cash-icon.png" alt="" /></p>
                      <h5>4 - 10°</h5>
                    </div>
                    <div className="place">
                      <p>$5 <img src="images/icons/cash-icon.png" alt="" /></p>
                      <h5>11 - 20°</h5>
                    </div>
                    <div className="place">
                      <p>$1 <img src="images/icons/cash-icon.png" alt="" /></p>
                      <h5>21 - 50°</h5>
                    </div>
                    <div className="place">
                      <p>$0.50 <img src="images/icons/cash-icon.png" alt="" /></p>
                      <h5>51 - 100°</h5>
                    </div>
                    <div className="place">
                      <p>$0.20 <img src="images/icons/cash-icon.png" alt="" /></p>
                      <h5>101 - 200°</h5>
                    </div>
                    <div className="place">
                      <p>$0.10 <img src="images/icons/cash-icon.png" alt="" /></p>
                      <h5>201 - 500°</h5>
                    </div>
                    <div className="place">
                      <p>$0.05 <img src="images/icons/cash-icon.png" alt="" /></p>
                      <h5>501 - 1000°</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-content">
                <div className="cash-prizes__row">
                  <div className="cash-prizes__l">
                    <img src="images/other/cash-img-2.png" alt="" />
                  </div>
                  <div className="cash-prizes__r">
                    <div className="cash-prizes__text">
                      <h2>FEELING STRONG?</h2>
                      <h3>COMPETE IN DAILY TOURNAMENTS</h3>
                      <ul>
                        <li>Win cash by placing yourself high in the score</li>
                        <li>The highest the score the highest the cash reward</li>
                        <li>Multiple positions get paid</li>
                      </ul>
                    </div>
                    <div className="active-prize">
                      <div className="active-prize__text">
                        <h3>WEEKLY JACKPOT</h3>
                        <p>Next JACKPOT in: <i className="icon-timer"></i> 2d 18h 16m</p>
                      </div>
                      <div className="active-prize__bon">
                        $950 <img src="images/icons/cash-icon.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tournament-info">
                  <div className="tournament-info__box">
                    <h3>TOURNAMENTS THIS WEEK</h3>
                    <p>43</p>
                  </div>
                  <div className="tournament-info__box">
                    <h3>AVERAGE JACKPOT</h3>
                    <p>$35 <img src="images/icons/cash-icon.png" alt="" /></p>
                  </div>
                </div>
              </div>
              <div className="cash-prizes__bottom">
                <div className="quick-tip">
                  <h2>QUICK TIP</h2>
                  <p>You can also collect Lucky Coins by playing in tournaments.</p>
                </div>
                <div className="popup-text">
                  <p>WHAT ARE YOU WAITING FOR?</p>
                </div>
                <a href="#" className="btn btn__gradient btn__icon--r">REGISTER NOW <i className="icon-person_add_alt"></i></a>
                <p className="popup-bottom__link">AND GET YOUR WELCOME BONUS</p>
              </div>
            </div>
          </div>
        </div>
        {/* <!--popup cash prizes play--> */}
        <div id="cash-prizes-play" style={{ display: "none" }} className="popup-style popup-style__w900">
          <div className="popup-box">
            <div className="popup-content cash-prizes tab-wrap">
              <h2 className="popup-title popup-title__f45">$PLY prizes every day PLAY FOR <span>FREE</span></h2>
              <ul className="tab-menu table-menu">
                <li className="active"><span>SWEEPSTAKES</span></li>
                <li><span>TOURNAMENTS</span></li>
              </ul>
              <div className="tab-content">
                <div className="cash-prizes__row">
                  <div className="cash-prizes__l">
                    <img src="images/other/cash-img-1.png" alt="" />
                  </div>
                  <div className="cash-prizes__r">
                    <div className="cash-prizes__text">
                      <h2>FEELING LUCKY?</h2>
                      <h3>JOIN THE SWEEPSTAKES</h3>
                      <ul>
                        <li>Play games and earn “Lucky Coins”</li>
                        <li>The more Lucky Coin you have, the more chances you have to win our sweepstakes.</li>
                      </ul>
                    </div>
                    <div className="active-prize">
                      <div className="active-prize__text">
                        <h3>ACTIVE PRIZE POOL</h3>
                        <p>Next DRAW in: <i className="icon-timer"></i> 2d 18h 16m</p>
                      </div>
                      <div className="active-prize__bon">
                        $800 <img src="images/icons/cash-icon.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cash-bon">
                  <div className="cash-prizes__box">
                    <div className="cash-prizes__bonus">
                      <p>$250 <img src="images/icons/cash-icon.png" alt="" /></p>
                      <h5>1° Prize</h5>
                    </div>
                  </div>
                  <div className="cash-places">
                    <div className="place">
                      <p>$150 <img src="images/icons/cash-icon.png" alt="" /></p>
                      <h5>2°</h5>
                    </div>
                    <div className="place">
                      <p>$100 <img src="images/icons/cash-icon.png" alt="" /></p>
                      <h5>3°</h5>
                    </div>
                    <div className="place">
                      <p>$20 <img src="images/icons/cash-icon.png" alt="" /></p>
                      <h5>4 - 10°</h5>
                    </div>
                    <div className="place">
                      <p>$5 <img src="images/icons/cash-icon.png" alt="" /></p>
                      <h5>11 - 20°</h5>
                    </div>
                    <div className="place">
                      <p>$1 <img src="images/icons/cash-icon.png" alt="" /></p>
                      <h5>21 - 50°</h5>
                    </div>
                    <div className="place">
                      <p>$0.50 <img src="images/icons/cash-icon.png" alt="" /></p>
                      <h5>51 - 100°</h5>
                    </div>
                    <div className="place">
                      <p>$0.20 <img src="images/icons/cash-icon.png" alt="" /></p>
                      <h5>101 - 200°</h5>
                    </div>
                    <div className="place">
                      <p>$0.10 <img src="images/icons/cash-icon.png" alt="" /></p>
                      <h5>201 - 500°</h5>
                    </div>
                    <div className="place">
                      <p>$0.05 <img src="images/icons/cash-icon.png" alt="" /></p>
                      <h5>501 - 1000°</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-content">
                <div className="cash-prizes__row">
                  <div className="cash-prizes__l">
                    <img src="images/other/cash-img-2.png" alt="" />
                  </div>
                  <div className="cash-prizes__r">
                    <div className="cash-prizes__text">
                      <h2>FEELING STRONG?</h2>
                      <h3>COMPETE IN DAILY TOURNAMENTS</h3>
                      <ul>
                        <li>Win cash by placing yourself high in the score</li>
                        <li>The highest the score the highest the cash reward</li>
                        <li>Multiple positions get paid</li>
                      </ul>
                    </div>
                    <div className="active-prize">
                      <div className="active-prize__text">
                        <h3>WEEKLY JACKPOT</h3>
                        <p>Next JACKPOT in: <i className="icon-timer"></i> 2d 18h 16m</p>
                      </div>
                      <div className="active-prize__bon">
                        $950 <img src="images/icons/cash-icon.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tournament-info">
                  <div className="tournament-info__box">
                    <h3>TOURNAMENTS THIS WEEK</h3>
                    <p>43</p>
                  </div>
                  <div className="tournament-info__box">
                    <h3>AVERAGE JACKPOT</h3>
                    <p>$35 <img src="images/icons/cash-icon.png" alt="" /></p>
                  </div>
                </div>
              </div>
              <div className="cash-prizes__bottom">
                <div className="quick-tip">
                  <h2>QUICK TIP</h2>
                  <p>You can also collect Lucky Coins by playing in tournaments.</p>
                </div>
                <div className="popup-text">
                  <p>WHAT ARE YOU WAITING FOR?</p>
                </div>
                <a href="#" className="btn btn__gradient btn__icon--r">play NOW <i className="icon-play"></i></a>
              </div>
            </div>
          </div>
        </div>
        {/* <!--popup live tournament--> */}
        <div id="live-tournament" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__pt popup-style__w600">
          <div className="popup-box">
            <div className="popup-top">
              <div className="popup-top__img popup-top__img--picture">
                <img src="images/games/game-15.jpg" alt="" />
              </div>
            </div>
            <div className="popup-content popup-content__pr live-tournament">
              <div className="live-icon">
                <img src="images/icons/live-icon.png" alt="" />
              </div>
              <h2 className="popup-title popup-title__f45">LIVE TOURNAMENT</h2>
              <div className="popup-timer popup-timer__mb25">
                <h5>Ends in:</h5>
                <div className="timer">
                  <div className="timer-box">
                    <div className="timer-num">
                      <span>0</span>
                      <span>2</span>
                    </div>
                    <p>Days</p>
                  </div>
                  <div className="timer-box">
                    <div className="timer-num">
                      <span>2</span>
                      <span>2</span>
                    </div>
                    <p>Hours</p>
                  </div>
                  <div className="timer-box">
                    <div className="timer-num">
                      <span>1</span>
                      <span>2</span>
                    </div>
                    <p>Minutes</p>
                  </div>
                </div>
              </div>
              <h3 className="popup-subtitle">CASH REWARD UP TO 100th PLACE </h3>
              <div className="slider-place style-slider">
                <div className="slider-place__col">
                  <div className="slider-place__item">
                    <div className="slider-place__t">1st</div>
                    <div className="slider-place__img">
                      <img src="images/icons/cash-icon.png" alt="" />
                    </div>
                    <div className="slider-place__money">$<span>3</span></div>
                  </div>
                </div>
                <div className="slider-place__col">
                  <div className="slider-place__item">
                    <div className="slider-place__t">2st</div>
                    <div className="slider-place__img">
                      <img src="images/icons/cash-icon.png" alt="" />
                    </div>
                    <div className="slider-place__money">$<span>2</span></div>
                  </div>
                </div>
                <div className="slider-place__col">
                  <div className="slider-place__item">
                    <div className="slider-place__t">3t</div>
                    <div className="slider-place__img">
                      <img src="images/icons/cash-icon.png" alt="" />
                    </div>
                    <div className="slider-place__money">$<span>1</span></div>
                  </div>
                </div>
                <div className="slider-place__col">
                  <div className="slider-place__item">
                    <div className="slider-place__t">4rt-10th</div>
                    <div className="slider-place__img">
                      <img src="images/icons/cash-icon.png" alt="" />
                    </div>
                    <div className="slider-place__money">$<span>3</span></div>
                  </div>
                </div>
                <div className="slider-place__col">
                  <div className="slider-place__item">
                    <div className="slider-place__t">1st</div>
                    <div className="slider-place__img">
                      <img src="images/icons/cash-icon.png" alt="" />
                    </div>
                    <div className="slider-place__money">$<span>3</span></div>
                  </div>
                </div>
              </div>
              <div className="slider-users style-slider">
                <div className="slider-users__col">
                  <div className="user-item user-item__my">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-9.jpg" alt="" />
                      <div className="user-item__label">35p</div>
                    </div>
                    <h3 className="user-item__name">You</h3>
                    <div className="user-item__points">316pts</div>
                  </div>
                </div>
                <div className="slider-users__col">
                  <div className="user-item">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-1.jpg" alt="" />
                      <div className="user-item__label">1p</div>
                    </div>
                    <h3 className="user-item__name">Marika</h3>
                    <div className="user-item__points">1216pts</div>
                  </div>
                </div>
                <div className="slider-users__col">
                  <div className="user-item">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-2.jpg" alt="" />
                      <div className="user-item__label">2p</div>
                    </div>
                    <h3 className="user-item__name">Lucas Ci..</h3>
                    <div className="user-item__points">1105pts</div>
                  </div>
                </div>
                <div className="slider-users__col">
                  <div className="user-item">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-3.jpg" alt="" />
                      <div className="user-item__label">3p</div>
                    </div>
                    <h3 className="user-item__name">Pablo Ve..</h3>
                    <div className="user-item__points">806pts</div>
                  </div>
                </div>
                <div className="slider-users__col">
                  <div className="user-item">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-4.jpg" alt="" />
                      <div className="user-item__label">4p</div>
                    </div>
                    <h3 className="user-item__name">Pablo Ve..</h3>
                    <div className="user-item__points">806pts</div>
                  </div>
                </div>
              </div>
              <a href="#" className="btn btn__blue btn__icon--r btn-play">Play <i className="icon-play"></i></a>
            </div>
          </div>
        </div>
        {/* <!--popup current info--> */}
        <div id="current-info" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__pt popup-style__w600">
          <div className="popup-box">
            <div className="popup-top">
              <div className="popup-top__img popup-top__img--picture">
                <img src="images/games/game-15.jpg" alt="" />
              </div>
            </div>
            <div className="popup-content current-info">
              <p className="current-score">Current Score: <span>128pt</span></p>
              <p className="current-position">CURRENT POSITION: <span>35th</span></p>
              <a href="#" className="btn btn__blue btn-continue">CONTINUE</a>
            </div>
          </div>
        </div>
        {/* <!--popup keep playing--> */}
        <div id="keep-playing" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__w600">
          <div className="popup-box">
            <div className="popup-content">
              <h2 className="popup-title popup-title__f45">KEEP PLAYING?</h2>
              <a href="#" className="btn btn__icon--r btn__gradient btn-watch">Watch111 <i className="icon-video"></i></a>
              <p className="popup-thank"><a href="#">NO THANKS</a></p>
            </div>
          </div>
        </div>
        {/* <!--popup reward--> */}
        <div id="reward" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__pt popup-style__w600">
          <div className="popup-box">
            <div className="popup-top">
              <div className="popup-top__img popup-top__img--picture">
                <img src="images/games/game-15.jpg" alt="" />
              </div>
            </div>
            <div className="popup-content">
              <div className="current-info">
                <p className="reward-info">REWARD: 492 <img src="images/icons/coin-no-shadow-icon.png" alt="" /></p>
                <a href="#" className="btn btn__gradient btn__icon--r btn-continue">DOUBLE IT <i className="icon-video"></i></a>
              </div>
              <p className="popup-thank mt20"><a href="#">CLAIM</a></p>
            </div>
          </div>
        </div>
        {/* <!--popup live tournament again--> */}
        <div id="live-tournament-again" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__pt popup-style__w600">
          <div className="popup-box">
            <div className="popup-top">
              <div className="popup-top__img popup-top__img--picture">
                <img src="images/games/game-15.jpg" alt="" />
              </div>
            </div>
            <div className="popup-content popup-content__pr live-tournament">
              <div className="live-icon">
                <img src="images/icons/live-icon.png" alt="" />
              </div>
              <h2 className="popup-title popup-title__f45">LIVE TOURNAMENT</h2>
              <div className="popup-timer popup-timer__mb25">
                <h5>Ends in:</h5>
                <div className="timer">
                  <div className="timer-box">
                    <div className="timer-num">
                      <span>0</span>
                      <span>2</span>
                    </div>
                    <p>Days</p>
                  </div>
                  <div className="timer-box">
                    <div className="timer-num">
                      <span>2</span>
                      <span>2</span>
                    </div>
                    <p>Hours</p>
                  </div>
                  <div className="timer-box">
                    <div className="timer-num">
                      <span>1</span>
                      <span>2</span>
                    </div>
                    <p>Minutes</p>
                  </div>
                </div>
              </div>
              <h3 className="popup-subtitle">CASH REWARD UP TO 100th PLACE </h3>
              <div className="slider-place style-slider">
                <div className="slider-place__col">
                  <div className="slider-place__item">
                    <div className="slider-place__t">1st</div>
                    <div className="slider-place__img">
                      <img src="images/icons/cash-icon.png" alt="" />
                    </div>
                    <div className="slider-place__money">$<span>3</span></div>
                  </div>
                </div>
                <div className="slider-place__col">
                  <div className="slider-place__item">
                    <div className="slider-place__t">2st</div>
                    <div className="slider-place__img">
                      <img src="images/icons/cash-icon.png" alt="" />
                    </div>
                    <div className="slider-place__money">$<span>2</span></div>
                  </div>
                </div>
                <div className="slider-place__col">
                  <div className="slider-place__item">
                    <div className="slider-place__t">3t</div>
                    <div className="slider-place__img">
                      <img src="images/icons/cash-icon.png" alt="" />
                    </div>
                    <div className="slider-place__money">$<span>1</span></div>
                  </div>
                </div>
                <div className="slider-place__col">
                  <div className="slider-place__item">
                    <div className="slider-place__t">4rt-10th</div>
                    <div className="slider-place__img">
                      <img src="images/icons/cash-icon.png" alt="" />
                    </div>
                    <div className="slider-place__money">$<span>3</span></div>
                  </div>
                </div>
                <div className="slider-place__col">
                  <div className="slider-place__item">
                    <div className="slider-place__t">1st</div>
                    <div className="slider-place__img">
                      <img src="images/icons/cash-icon.png" alt="" />
                    </div>
                    <div className="slider-place__money">$<span>3</span></div>
                  </div>
                </div>
              </div>
              <p className="tournament-scored">You scored <span className="green">387 pt</span></p>
              <div className="slider-users style-slider">
                <div className="slider-users__col">
                  <div className="user-item user-item__my">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-9.jpg" alt="" />
                      <div className="user-item__label">35p</div>
                    </div>
                    <h3 className="user-item__name">You</h3>
                    <div className="user-item__points">316pts</div>
                  </div>
                </div>
                <div className="slider-users__col">
                  <div className="user-item">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-1.jpg" alt="" />
                      <div className="user-item__label">1p</div>
                    </div>
                    <h3 className="user-item__name">Marika</h3>
                    <div className="user-item__points">1216pts</div>
                  </div>
                </div>
                <div className="slider-users__col">
                  <div className="user-item">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-2.jpg" alt="" />
                      <div className="user-item__label">2p</div>
                    </div>
                    <h3 className="user-item__name">Lucas Ci..</h3>
                    <div className="user-item__points">1105pts</div>
                  </div>
                </div>
                <div className="slider-users__col">
                  <div className="user-item">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-3.jpg" alt="" />
                      <div className="user-item__label">3p</div>
                    </div>
                    <h3 className="user-item__name">Pablo Ve..</h3>
                    <div className="user-item__points">806pts</div>
                  </div>
                </div>
                <div className="slider-users__col">
                  <div className="user-item">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-4.jpg" alt="" />
                      <div className="user-item__label">4p</div>
                    </div>
                    <h3 className="user-item__name">Pablo Ve..</h3>
                    <div className="user-item__points">806pts</div>
                  </div>
                </div>
              </div>
              <a href="#" className="btn btn__blue btn__icon--r btn-play-again">PLAY AGAIN <i className="icon-play"></i></a>
            </div>
          </div>
        </div>
        {/* <!--popup tournament end--> */}
        <div id="tournament-win" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__pt popup-style__w600">
          <div className="popup-box">
            <div className="popup-top">
              <div className="popup-top__img popup-top__img--picture">
                <img src="images/games/game-15.jpg" alt="" />
              </div>
            </div>
            <div className="popup-content end-tournament">
              <h2 className="popup-title popup-title__f45">TOURNAMENT ENDED</h2>
              <div className="slider-users style-slider">
                <div className="slider-users__col">
                  <div className="user-item user-item__my">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-9.jpg" alt="" />
                      <div className="user-item__label">35p</div>
                    </div>
                    <h3 className="user-item__name">You</h3>
                    <div className="user-item__points">316pts</div>
                  </div>
                </div>
                <div className="slider-users__col">
                  <div className="user-item">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-1.jpg" alt="" />
                      <div className="user-item__label">1p</div>
                    </div>
                    <h3 className="user-item__name">Marika</h3>
                    <div className="user-item__points">1216pts</div>
                  </div>
                </div>
                <div className="slider-users__col">
                  <div className="user-item">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-2.jpg" alt="" />
                      <div className="user-item__label">2p</div>
                    </div>
                    <h3 className="user-item__name">Lucas Ci..</h3>
                    <div className="user-item__points">1105pts</div>
                  </div>
                </div>
                <div className="slider-users__col">
                  <div className="user-item">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-3.jpg" alt="" />
                      <div className="user-item__label">3p</div>
                    </div>
                    <h3 className="user-item__name">Pablo Ve..</h3>
                    <div className="user-item__points">806pts</div>
                  </div>
                </div>
                <div className="slider-users__col">
                  <div className="user-item">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-4.jpg" alt="" />
                      <div className="user-item__label">4p</div>
                    </div>
                    <h3 className="user-item__name">Pablo Ve..</h3>
                    <div className="user-item__points">806pts</div>
                  </div>
                </div>
              </div>
              <div className="tournament-win">
                <p className="tournament-scored">You WON: <span className="green">$7</span></p>
                <span className="line-effect"><img src="images/icons/cash-icon.png" alt="" /></span>
              </div>
              <a href="#" className="btn btn__gradient btn-collect">COLLECT</a>
            </div>
          </div>
        </div>
        <div id="tournament-bonus" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__pt popup-style__w600">
          <div className="popup-box">
            <div className="popup-top">
              <div className="popup-top__img popup-top__img--picture">
                <img src="images/games/game-15.jpg" alt="" />
              </div>
            </div>
            <div className="popup-content end-tournament">
              <h2 className="popup-title popup-title__f45">TOURNAMENT ENDED</h2>
              <div className="popup-text">
                <p>TRY HARDER NEXT TIME!</p>
              </div>
              <div className="slider-users style-slider">
                <div className="slider-users__col">
                  <div className="user-item user-item__my">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-9.jpg" alt="" />
                      <div className="user-item__label">35p</div>
                    </div>
                    <h3 className="user-item__name">You</h3>
                    <div className="user-item__points">316pts</div>
                  </div>
                </div>
                <div className="slider-users__col">
                  <div className="user-item">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-1.jpg" alt="" />
                      <div className="user-item__label">1p</div>
                    </div>
                    <h3 className="user-item__name">Marika</h3>
                    <div className="user-item__points">1216pts</div>
                  </div>
                </div>
                <div className="slider-users__col">
                  <div className="user-item">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-2.jpg" alt="" />
                      <div className="user-item__label">2p</div>
                    </div>
                    <h3 className="user-item__name">Lucas Ci..</h3>
                    <div className="user-item__points">1105pts</div>
                  </div>
                </div>
                <div className="slider-users__col">
                  <div className="user-item">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-3.jpg" alt="" />
                      <div className="user-item__label">3p</div>
                    </div>
                    <h3 className="user-item__name">Pablo Ve..</h3>
                    <div className="user-item__points">806pts</div>
                  </div>
                </div>
                <div className="slider-users__col">
                  <div className="user-item">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-4.jpg" alt="" />
                      <div className="user-item__label">4p</div>
                    </div>
                    <h3 className="user-item__name">Pablo Ve..</h3>
                    <div className="user-item__points">806pts</div>
                  </div>
                </div>
              </div>
              <div className="tournament-win">
                <p className="tournament-scored">ENTRY BONUS <span className="yellow">+40</span></p>
                <span className="line-effect"><img src="images/icons/coin-big-icon.png" alt="" /></span>
              </div>
              <a href="#" className="btn btn__gradient btn__icon--r btn-continue">DOUBLE IT <i className="icon-video"></i></a>
              <p className="popup-thank mt20"><a href="#">CLAIM</a></p>
            </div>
          </div>
        </div>
        {/* <!--popup lucky coins--> */}
        <div id="lucky-coins" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__pt popup-style__w600">
          <div className="popup-box">
            <div className="popup-top">
              <div className="popup-top__img popup-top__img--picture">
                <img src="images/games/game-15.jpg" alt="" />
              </div>
            </div>
            <div className="popup-content ">
              <h2 className="lucky-coins__title">GET LUCKY COINS <span className="line-effect"><img src="images/icons/coin-big-icon.png" alt="" /></span></h2>
              <div className="popup-timer popup-timer__mb25">
                <h5>NEXT DRAW:</h5>
                <div className="timer">
                  <div className="timer-box">
                    <div className="timer-num">
                      <span>0</span>
                      <span>2</span>
                    </div>
                    <p>Days</p>
                  </div>
                  <div className="timer-box">
                    <div className="timer-num">
                      <span>2</span>
                      <span>2</span>
                    </div>
                    <p>Hours</p>
                  </div>
                  <div className="timer-box">
                    <div className="timer-num">
                      <span>1</span>
                      <span>2</span>
                    </div>
                    <p>Minutes</p>
                  </div>
                </div>
              </div>
              <a href="#" className="btn btn__blue btn__icon--r btn-play">PLAY <i className="icon-play"></i></a>
              <p className="highscore">Your Highscore: <span>3645pts</span> </p>
            </div>
          </div>
        </div>
        {/* <!--popup current score--> */}
        <div id="current-score" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__pt popup-style__w600">
          <div className="popup-box">
            <div className="popup-top">
              <div className="popup-top__img popup-top__img--picture">
                <img src="images/games/game-15.jpg" alt="" />
              </div>
            </div>
            <div className="popup-content current-info">
              <p className="current-score">Current Score: <span>128pt</span></p>
              <a href="#" className="btn btn__blue btn-continue mt20">CONTINUE</a>
            </div>
          </div>
        </div>
        {/* <!--popup sweepstake ended (bonus)--> */}
        <div id="sweepstake-end-bonus" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__pt popup-style__w600">
          <div className="popup-box">
            <div className="popup-top">
              <img src="images/other/popup-top.png" alt="" />
            </div>
            <div className="popup-content end-tournament">
              <h2 className="popup-title popup-title__f45">sweepstake ended</h2>
              <div className="popup-text">
                <p>start to collect lucky coins for the new sweepstake</p>
              </div>
              <div className="slider-users style-slider">
                <div className="slider-users__col">
                  <div className="user-item user-item__my">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-9.jpg" alt="" />
                      <div className="user-item__label">35p</div>
                    </div>
                    <h3 className="user-item__name">You</h3>
                    <div className="user-item__points">316pts</div>
                  </div>
                </div>
                <div className="slider-users__col">
                  <div className="user-item">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-1.jpg" alt="" />
                      <div className="user-item__label">1p</div>
                    </div>
                    <h3 className="user-item__name">Marika</h3>
                    <div className="user-item__points">1216pts</div>
                  </div>
                </div>
                <div className="slider-users__col">
                  <div className="user-item">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-2.jpg" alt="" />
                      <div className="user-item__label">2p</div>
                    </div>
                    <h3 className="user-item__name">Lucas Ci..</h3>
                    <div className="user-item__points">1105pts</div>
                  </div>
                </div>
                <div className="slider-users__col">
                  <div className="user-item">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-3.jpg" alt="" />
                      <div className="user-item__label">3p</div>
                    </div>
                    <h3 className="user-item__name">Pablo Ve..</h3>
                    <div className="user-item__points">806pts</div>
                  </div>
                </div>
                <div className="slider-users__col">
                  <div className="user-item">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-4.jpg" alt="" />
                      <div className="user-item__label">4p</div>
                    </div>
                    <h3 className="user-item__name">Pablo Ve..</h3>
                    <div className="user-item__points">806pts</div>
                  </div>
                </div>
              </div>
              {/* <div className="popup-text"> */}
              <h3 className="popup-title popup-title__f22">start to collect lucky coins for the new sweepstake</h3>
              {/* </div> */}
              <div className="tournament-win">
                <p className="tournament-scored">partecipation bonus <span className="yellow">+2000</span></p>
                <span className="line-effect"><img src="images/icons/coin-big-icon.png" alt="" /></span>
              </div>
              <a href="#" className="btn btn__gradient btn__icon--r btn-continue shake-anim">DOUBLE IT <i className="icon-video"></i></a>
              <p className="popup-thank mt20"><a href="#">CLAIM</a></p>
            </div>
          </div>
        </div>
        <div id="sweepstake-end-win" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__pt popup-style__w600">
          <div className="popup-box">
            <div className="popup-top">
              <img src="images/other/popup-top.png" alt="" />
            </div>
            <div className="popup-content end-tournament">
              <h2 className="popup-title popup-title__f45">sweepstake ended</h2>
              <div className="popup-text withdraw-money">
                <h2>All lucky coins have been reset</h2>
              </div>
              <div className="slider-users style-slider">
                <div className="slider-users__col">
                  <div className="user-item user-item__my">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-9.jpg" alt="" />
                      <div className="user-item__label">35p</div>
                    </div>
                    <h3 className="user-item__name">You</h3>
                    <div className="user-item__points">316pts</div>
                  </div>
                </div>
                <div className="slider-users__col">
                  <div className="user-item">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-1.jpg" alt="" />
                      <div className="user-item__label">1p</div>
                    </div>
                    <h3 className="user-item__name">Marika</h3>
                    <div className="user-item__points">1216pts</div>
                  </div>
                </div>
                <div className="slider-users__col">
                  <div className="user-item">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-2.jpg" alt="" />
                      <div className="user-item__label">2p</div>
                    </div>
                    <h3 className="user-item__name">Lucas Ci..</h3>
                    <div className="user-item__points">1105pts</div>
                  </div>
                </div>
                <div className="slider-users__col">
                  <div className="user-item">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-3.jpg" alt="" />
                      <div className="user-item__label">3p</div>
                    </div>
                    <h3 className="user-item__name">Pablo Ve..</h3>
                    <div className="user-item__points">806pts</div>
                  </div>
                </div>
                <div className="slider-users__col">
                  <div className="user-item">
                    <div className="user-item__photo">
                      <img src="images/photos/photo-4.jpg" alt="" />
                      <div className="user-item__label">4p</div>
                    </div>
                    <h3 className="user-item__name">Pablo Ve..</h3>
                    <div className="user-item__points">806pts</div>
                  </div>
                </div>
              </div>
              <div className="tournament-win">
                <p className="tournament-scored">You WON: <span className="green">$7</span></p>
                <span className="line-effect"><img src="images/icons/cash-icon.png" alt="" /></span>
              </div>
              <a href="#" className="btn btn__gradient btn-collect shake-anim">COLLECT</a>

            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}
