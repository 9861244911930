import { PAYMENT_REQUEST_SUCCESS,SET_USER_TRANSACTION } from "../actions/types";

const initialState = {
	success: false,
	userTransactions : []
};


export default function (state = initialState, action) {
	switch (action.type) {
		case PAYMENT_REQUEST_SUCCESS:
			return {
                ...state,
				success : action.payload
			};
		case SET_USER_TRANSACTION:
			return {
				...state,
				userTransactions : action.payload
			}
		default:
			return state;
	}
}