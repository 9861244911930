import React, { Component } from "react";
import Header from "../../common/header/header";
import Footer from "../../common/footer/footer";
import { connect } from "react-redux";
import { getCurrentUser, isAuthenticated } from "../../actions/authActions";
import { getTournamentPositions } from "../../actions/tournaments";
import { getHighscores } from "../../actions";
import $ from "jquery";
import HighscoreModal from "../modals/highscoreModal";
class Page3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playerProfile: {},
    };
  }

  componentDidMount() {
    // $(window).on('popstate', function(event) {
    //   window.location.href = '/winner'
    // });
    const params = new URLSearchParams(window.location.search);
    const tournamentId = params.get("t_id");
    const payload = {
      tournamentId,
    };

    this.props.getTournamentPositions(payload);
  }
  setThisUser = (e, el) => {
    // e.preventDefault()

    // debugger
    // console.log("this is the obj", el)
    this.props.getHighscoresDispatch({ userId: el.userId });
    this.setState({ playerProfile: el });


  };

  getMonthName = () => {
    const {
      props: { tournamentPayload },
    } = this;

    const monthNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUNE",
      "JULY",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];

    const d = new Date(tournamentPayload.finishDate);
    const m = monthNames[d.getMonth()];
    return m;
  };

  render() {
    const {
      state: { playerProfile },
      props: { user, positions, you, tournamentPayload, highscores },
    } = this;

    const newPositions = positions.slice(0, 10);

    return (
      <div>
        {/* <Header /> */}
        <HighscoreModal />
        <main className="block-table tab-wrap">
          <section className=" tab-wrap">
            <div className="table-wrap table-wrap__w830">
              <div className="table-info">
                <a className="table-game">
                  <img src={tournamentPayload && tournamentPayload.gameImg ? tournamentPayload.gameImg : ""} alt="" />
                </a>
                <h2>{tournamentPayload.gameName}</h2>
                <p>
                  TOURNAMENT END DATE:{" "}
                  {new Date(tournamentPayload.finishDate).getDate()}{" "}
                  {this.getMonthName()} ‘{" "}
                  {new Date(tournamentPayload.finishDate).getFullYear().toString().substring(2, 4)}
                </p>
              </div>
              <div className="table winner-table">
                <div className="t-head">
                  <div className="t-user">Username</div>
                  <div className="t-prize">Position</div>
                  <div className="t-cash">$PLY Prize</div>
                </div>
                {you &&
                  <div className="t-row my-position" onClick={(e) => this.setThisUser(e, you)}>
                    <div className="t-user">
                      <a className="table-user" href="#">
                        <img
                          src={
                            you.imageUrl
                              ? you.imageUrl
                              : "assets/images/games/defaultProfile.jpg"
                          }

                          // data-fancybox="yourHighscores"
                          // data-src="#tournamentHighscores"
                          alt=""
                        />
                        You
                      </a>
                    </div>
                    <div className="t-prize" style={{display: "flex", justifyContent:"space-between"}}>
                      <span>{you.userPosition ? you.userPosition : '//'}°</span>
                      {you.winning !== 0 && <span className="winner-mark">winner</span> }
                    </div>
                    <div className="t-cash">
                      {you.winning !== 0 ?
                        <div className="my-cash">
                          {`\$PLY ${you.winning}`}
                          <img src="images/icons/cash-icon.png" alt="" />
                        </div>
                        : you.entryBonus !== 0 ?
                          <div className="my-cash">
                            {`${you.entryBonus}`}<img src="images/icons/coin-icon.png" alt="" />
                          </div> :
                          '//'}
                    </div>
                  </div>

                }
              </div>
            </div>
          </section>
          <section className="tab-wrap scroll_sweep_tournaments">
            <div className="table-wrap table-wrap__w830">
              <div className="table winner-table">
                {positions &&
                  positions.map((element, index) => {
                    if (user && element.userId != user._id)
                      return (
                        <>
                          <div key={index} className="t-row" onClick={(e) => this.setThisUser(e, element)}>
                            <div className="t-user">
                              <a className="table-user" href="#">
                                <img src={element.imageUrl ? element.imageUrl : "assets/images/games/defaultProfile.jpg"} alt="" />
                                {element.name}
                              </a>
                            </div>
                            <div className="t-prize">{element.userPosition}°</div>
                            <div className="t-cash color-green">
                              $PLY {element.winning}
                            </div>
                          </div>
                        </>
                      );
                  })}
              </div>
              {/* <!--popup highscores--> */}
              <div
                id="tournamentHighscores"
                style={{ display: "none" }}
                className="popup-style popup-style__pt popup-style__w600"
              >
                <div className="popup-box">
                  <div className="popup-top">
                    <div className="popup-top__img popup-top__img--picture">
                      <img src={playerProfile.imageUrl ? playerProfile.imageUrl : "assets/images/games/defaultProfile.jpg"} alt="" />
                    </div>
                  </div>
                  <div className="popup-content">
                    <h2 className="popup-nickname">{playerProfile.name}</h2>
                    <h3 className="title-results">Highscores</h3>
                    <div className="box-highscores box-highscores__scroll">
                      {highscores &&
                        highscores.map((element, index) => {
                          return (
                            <div key={index} className="game-result">
                              <div className="game-result__img">
                                <img src={element.gameId.icons[0].imageURL} alt="" />
                              </div>
                              <div className="game-result__info">
                                <h3>{element.gameId.name}</h3>
                                <p>{element.gameScore}</p>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* <Footer /> */}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getTournamentPositions: (payload) => {
    dispatch(getTournamentPositions(payload));
  },
  getHighscoresDispatch: (userId) => {
    dispatch(getHighscores(userId));
  },
});

const mapStateToProps = (state) => ({
  positions: state.tournament.userPositions ? state.tournament.userPositions : [],
  you: state.tournament.yourPosition ? state.tournament.yourPosition : null,
  tournamentPayload: state.tournament.tournamentPayload ? state.tournament.tournamentPayload : {},
  user: state.auth ? state.auth.user : null,
  highscores: state.game.userHighscores ? state.game.userHighscores : [],
});

export default connect(mapStateToProps, mapDispatchToProps)(Page3);
