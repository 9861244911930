/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { loginJwt } from '../../actions/authActions'
import { loginWithFacebook, loginWithGoogle } from '../../actions/authActions';
import Forgetpass from './forgotPassword'
import $ from 'jquery';
class Login extends Component {
  constructor(props) {
    super(props);
    this.fbRef = React.createRef()
    this.state = {
      email: '',
      password: '',
      message: '',
      emailValid: false,
      passwordValid: false,

    };
  }

  componentDidMount() {
    setTimeout(() => {
      window.gapi.signin2.render('g-signin1', {
        'scope': 'https://www.googleapis.com/auth/plus.login',
        'onsuccess': this.onGoogleSignIn
      });
    }, 2000);
    $('#login-submit').click(this.onSubmit);
    $('.login-form').change(this.onChange);
    $('#fb-login').click(this.clickHandler)
  }

  onGoogleSignIn = (googleUser) => {
    const profile = googleUser.getBasicProfile();
    console.log('Profile GOOGLE', profile)
    const googlePayload = {
      name: profile.getName(),
      email: profile.getEmail(),
      googleId: profile.getId(),
      imageUrl: profile.getImageUrl(),
    };
    this.props.loginWithGoogle(googlePayload, this.props.history);
  }

  clickHandler = (e) => {
    e.preventDefault()
    const fbRedirectUrl = "https://playtoo-game.herokuapp.com/"
    const fbUrl = `https://www.facebook.com/v3.2/dialog/oauth?client_id=236871461413084&redirect_uri=${fbRedirectUrl}&scope=public_profile,email&response_type=token`
    window.location.href = fbUrl
  }

  responseFacebook = (response) => {
    const {
      picture: {
        data: {
          url
        }
      }
    } = response;
    const data = {
      name: response.name,
      email: response.email,
      facebookId: response.id,
      imageUrl: url,
    };
    this.props.loginWithFacebook(data, this.props.history);
  }

  responseGoogle = (response) => {
    const data = {
      name: response.profileObj.name,
      email: response.profileObj.email,
      googleId: response.googleId,
    };
    this.props.loginWithGoogle(data, this.props.history);
  }

  onSubmit = e => {
    e.preventDefault();
    this.setstate = ({
      email: '',
      password: '',
      message: '',
      emailValid: false,
      passwordValid: false,

    })
    const data = {
      email: this.state.email,
      password: this.state.password
    };
    this.props.loginJwt(data, this.props.history);
  }

  onChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value },
      () => { this.validateField(name, value) })
  }

  validateField(fieldName, value) {
    let emailmatch = '';
    let emailValid = this.state.emailValid;
    let passwordlength = '';
    let passwordValid = this.state.passwordValid;
    switch (fieldName) {
      case 'email':
        emailmatch = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        emailValid = emailmatch ? '' : 'Email is invalid';
        break;
      case 'password':
        passwordlength = value.length >= 6;
        passwordValid = passwordlength ? '' : ' Password is too short. must be 6 characters long';
        break;
      default:
        break;
    }
    this.setState({
      emailValid,
      passwordValid,
    });
  }

  render() {
    return (
      <div>
        <div id="login" style={{ display: "none" }} className="fancy popup-style popup-style__text-center popup-style__w600">
          <p><button type='button' data-fancybox-close className="fancybox-close-small fancybox-button">
            <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24"><path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path></svg>
          </button></p>
          <div className="popup-box">
            <div className="popup-content">
              <h2 className="popup-title popup-title__f45">LOGIN</h2>
              <div className="style-form register-form">
                <form action="" id="myForm">
                  <div className="field field-icon">
                    <i className="icon-mail-envelope-closed"></i>
                    <input type="email" name="email" className="login-form" placeholder="Enter email" value={this.state.email} onChange={this.onChange} />
                    {this.state.emailValid ? <span style={{ color: "red" }}>{this.state.emailValid}</span> : ""
                      || this.props.error.email ? <span style={{ color: "red" }}>{this.props.error.email}</span> : ""}
                  </div>
                  <div className="field field-icon">
                    <i className="icon-lock"></i>
                    <input type="password" name="password" className="login-form" placeholder="Enter password" value={this.state.password} onChange={this.onChange} />
                    {this.props.error.password ? <span style={{ color: "red" }}>{this.props.error.password}</span> : ""}
                    <span className="toggle-password icon-eye"></span>
                  </div>
                  <button className="btn btn__blue btn__icon--r" id="login-submit" type="submit" >
                    LOG IN
                    <i className="icon-lock"></i>
                  </button>
                </form>
                <p className="popup-bottom__link popup-bottom__link_forgotpsw">
                  <a href="#" data-fancybox data-src="#forgot-password" >Forgot password?</a>
                </p>
              </div>
              <p className="popup-bottom__link"><a href="#">By logging in you accept the Contest Rules and Privacy Policy</a></p>
            </div>
          </div>
        </div>
        <Forgetpass />
      </div>
    );
  }
}
Login.propTypes = {
  loginJwt: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  userId: PropTypes.string,
  loginWithFacebook: PropTypes.func.isRequired,
  loginWithGoogle: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
  error: state.error.loginErr ? state.error.loginErr : ""
});

export default connect(mapStateToProps, { loginWithFacebook, loginWithGoogle, loginJwt })(withRouter(Login));
