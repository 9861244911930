/* eslint-disable no-useless-constructor */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Login from "./login";
import $ from 'jquery';
import { loginWithGoogle } from '../../actions';

class LoginModal extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    $('#login-mail').click(this.loginEmail);
    $('#login-wallet').click(this.loginWallet);
    $('#register-btn').click(this.registerBox);

    $('#fb-register-btn').click(this.handleFBSignup);
    setTimeout(() => {
      window.gapi.signin2.render('g-signin2', {
        'scope': 'https://www.googleapis.com/auth/plus.login',
        'onsuccess': this.onGoogleSignUp
      });
    }, 2000);
  }

  handleFBSignup = (e) => {
    e.preventDefault();
    const fbRedirectUrl = "https://playtoo-game.herokuapp.com/";
    const fbUrl = `https://www.facebook.com/v3.2/dialog/oauth?client_id=236871461413084&redirect_uri=${fbRedirectUrl}&scope=public_profile,email&response_type=token`;
    window.location.href = fbUrl;
  };

  onGoogleSignUp = (googleUser) => {
    const profile = googleUser.getBasicProfile();
    const googlePayload = {
      name: profile.getName(),
      email: profile.getEmail(),
      googleId: profile.getId(),
    };
    this.props.loginWithGoogleDispatch(googlePayload, this.props.history);
  };

  loginEmail = () => {
    window.myFunct();
    window.openModale("#login");
  };

  loginWallet = async () => {
    window.myFunct();
    this.props.logWallet();
  };

  registerBox = () => {
    window.myFunct();
    window.openModale("#register-soc");
  };

  render() {
    return (
      <div>
        <Login />
        <div id="login-modal" style={{ display: "none" }} className="popup-style popup-style__text-center popup-style__w600">
          <p>
            <button type='button' data-fancybox-close className="fancybox-close-small fancybox-button">
              <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24">
                <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path>
              </svg>
            </button>
          </p>
          <div className="popup-box">
            <div className="popup-content">
              <h2 className="popup-title popup-title__f45">LOGIN</h2>
              <div className="register-soc">
                <a className="register-mail" id="login-mail" href="#"><img src="images/icons/mail-icon.png" alt="" /></a>
                <a className="register-mail" id="login-wallet" href="#">Wallet</a>
              </div>
              <p className="register-log">Or</p>
              <div className="cash-prizes__bottom">
                <a href="#" className="btn btn__gradient btn__icon--r" id="register-btn">REGISTER NOW <i className="icon-person_add_alt"></i></a>
                <p className="popup-bottom__link"><a href="#">By registering you accept the Contest Rules and Privacy Policy</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  loginWithGoogleDispatch: (payload, history) => {
    dispatch(loginWithGoogle(payload, history));
  },
});

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
