import $ from 'jquery'
import React, { Component } from "react";
import { connect } from "react-redux";
import socket from './../../helpers/socket'
import { coinsAnimation, moneyAnimation } from "../../helpers/animations";
import { doubleUserCoins, getHighscores, getUserData, doubleTournamentCoins, updateUserCoins } from "../../actions";

// aiptag.cmd.player = aiptag.cmd.player || [];
export class sweepstakeEnded extends Component {
  constructor(props) {
    super(props);
    this.state = {
      you: {},
      adComplete: false
    };
  }

  componentDidMount() {
    $('#sweepstake-ended').on('click', '.userHighscoresClick', (e) => { this.setThisSweepUser(e) })
    $("#sweepstake-ended").on("click", "#double-itsweep", this.watchRewardAd);
    let aiptag = window.aiptag || { cmd: [] };
    aiptag.cmd.player = aiptag.cmd.player || [];

    aiptag.cmd.player.push(() => {
      aiptag.adplayer2 = new window.aipPlayer({
        AD_WIDTH: 960,
        AD_HEIGHT: 540,
        AD_DISPLAY: "fullscreen",
        LOADING_TEXT: "loading advertisement",
        PREROLL_ELEM: function () {
          return document.getElementById("preroll");
        },
        AIP_COMPLETE: (evt) => {
          if (this.props.doubeTCoins) {
            this.props.doubleTournamentCoinsDispatch(false)
            const target = document.querySelector('#tour-coin')
            setTimeout(() => {
              const totalCoins = Number(this.props.user?.coins) + 80
              this.props.updateUserCoinsDispatch({ coins: totalCoins })
            }, 4000)
            coinsAnimation(target, this.props.user?.coins, 80, "TOUR_DOUBLE", this.props.router)
          }

          if (this.state.adComplete) {
            const payload = { coins: 1000 };
            this.props.doubleUserCoins(payload);
            this.gameResumeFunct(4000)
          }

        },
        AIP_REMOVE: function () {
          // Here it's save to remove the PREROLL_ELEM from the page if you want. But it's not recommend.
        },
      });
    });
    $('#sweepstake-ended').on('click', '#resumer01', this.gameResumeFunct2)
    $('#sweepstake-ended').on('click', '#resumer02', this.gameResumeFunct)
  }

  componentDidUpdate(prevProps) {
    // debugger
    if (this.props.yourPositions !== prevProps.yourPositions && this.props.yourPositions.length > 0) {
      this.getUserObject(this.props.yourPositions);
    }
  }

  getUserObject = (arr) => {
    if (arr.length === 1) {
      // debugger
      this.setState({ you: arr[0] });
    } else {
      let winnings = arr.reduce((a, b) => ({ winning: a.winning + b.winning }));
      const newObj = {
        ...arr[0],
        totalWinning: winnings.winning,
        multipleWinnings: true,
      };
      // debugger
      this.setState({ you: newObj });
    }
  };

  gameResumeFunct2 = () => {
    const {
      props: { user, sweepPositions },
      state: { you },
    } = this;
    let sweepId = this.props.yourPositions[0] ? this.props.yourPositions[0].sweepStakeId : false
    if (sweepId) {
      socket.emit("CLAIMED", { sweepStakeId: sweepId })
    }
    const target = document.querySelector('#money-img-sweep')
    const winning = you.totalWinning ? you.totalWinning : you.winning
    setTimeout(() => {
      this.props.updateUserCoinsDispatch({ coins: 0, isLuckyCoin: true })
    }, 4000)
    moneyAnimation(target, winning, user.money, "SWEEP_MONEY", this.props.user?.coins)
  }

  gameResumeFunct = (double) => {
    // debugger
    this.props.fetchCurrentUserDispatch()
    let sweepId = this.props.yourPositions[0] ? this.props.yourPositions[0].sweepStakeId : false
    if (sweepId) {
      socket.emit("CLAIMED", { sweepStakeId: sweepId })
    }
    const target = document.querySelector("#sweep-coin")
    setTimeout(() => {
      const totalCoins = 2000
      // const totalCoins = Number(this.props.user?.coins) +2000
      this.props.updateUserCoinsDispatch({ coins: totalCoins, isLuckyCoin: true })
    }, 4000)
    coinsAnimation(target, 0, 2000, "SWEEP_END", this.props.router)
  }

  setThisSweepUser = (e) => {
    // debugger
    const userId = e.target.dataset.id
    this.props.getHighscoresDispatch({ userId: userId })
  }

  watchRewardAd = (e) => {
    // debugger
    if (typeof window.aiptag.adplayer !== undefined) {
      console.log("inside add");
      window.aiptag.cmd.player.push(() => {
        window.aiptag.adplayer2.startPreRoll();
      });
    } else {
      const payload = { coins: 1000 };
      this.props.doubleUserCoins(payload);
    }
    this.setState({ adComplete: true })
  };

  render() {
    const {
      props: { user, sweepPositions },
      state: { you },
    } = this;

    return (
      <div
        id="sweepstake-ended"
        style={{ display: "none" }}
        className="popup-style popup-style__text-center popup-style__pt popup-style__w600"
      >
        <div className="popup-box">
          <div className="popup-top">
            <img src="images/other/popup-top.png" alt="" />
          </div>
          <div className="popup-content end-tournament">
            <h2 className="popup-title popup-title__f45">sweepstake ended</h2>
            <div className="popup-text withdraw-money">
              <h2>All lucky coins have been reset</h2>
            </div>
            <div id="sEnd01" className="slider-users style-slider">
              <div className="slider-users__col">
                <div className="user-item user-item__my">
                  <div className="user-item__photo">
                    <img src={you.userId && you.userId.imageUrl ? you.userId.imageUrl : "assets/images/games/defaultProfile.jpg"} alt="" />
                    {you.userPosition !== 0 ? (<div className="user-item__label">{you.userPosition}p</div>) : ("")}
                  </div>
                  <h3 className="user-item__name">You</h3>
                  {you.winning !== 0 ? (
                    <div className="user-item__points">
                      {you ? `\$PLY ${you.winning}` : "//"}
                    </div>
                  ) : (
                    <p>//</p>
                  )}
                </div>
                {you.totalWinning !== undefined ? (<div className="user-item__points">Multiple Winnings!</div>) : ("")}
              </div>
              {sweepPositions &&
                sweepPositions.map((el, index) => {
                  if (el.userId && el.userId._id !== user._id) {
                    return (
                      <>
                        <div key={index} className="slider-users__col">
                          <div className="user-item">
                            <div className="user-item__photo">
                              <img
                                src={el.userId.imageUrl ? el.userId.imageUrl : "assets/images/games/defaultProfile.jpg"}
                                alt=""
                                className="userHighscoresClick"
                                data-id={el.userId && el.userId._id}
                              />
                              <div className="user-item__label">
                                {el.userPosition}p
                              </div>
                            </div>
                            <h3 className="user-item__name">{el.userName}</h3>
                            <div className="user-item__points">
                              {el ? `\$PLY ${el.winning}` : "$PLY 0"}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  }
                })}
            </div>
            {you === undefined || you.winning === 0 ? (
              <>
                <br />
                <h3 className="popup-title popup-title__f22">
                  start to collect lucky coins for the new sweepstake
                </h3>
                <div className="tournament-win">
                  <p className="tournament-scored">
                    partecipation bonus <span className="yellow">+2000</span>
                  </p>
                  <span className="line-effect">
                    <img id="sweep-coin" src="images/icons/coin-big-icon.png" alt="" />
                  </span>
                </div>
                <p className="popup-thank mt20">
                  <a id="resumer02">CLAIM</a>
                </p>
              </>
            ) : (
              <>
                <br />
                <div className="tournament-win">
                  <p className="tournament-scored">
                    You WON:<span className="green">{you.totalWinning ? `\$PLY ${you.totalWinning}` : `\$PLY ${you.winning}`}</span>
                  </p>
                  <span className="line-effect">
                    <img id="money-img-sweep" src="images/icons/cash-icon.png" alt="" />
                  </span>
                </div>
                <a id="resumer01"
                  className="btn btn__gradient btn-collect shake-anim"
                >
                  COLLECT
                </a>
                <p className="popup-bottom__link mt20">
                  All lucky coins have been reset
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  router: state.router.location ? state.router.location : [],
  user: state.auth ? state.auth.user : null,
  sweepPositions: state.sweepstake.sweepstakeEnded.sweep_winners ? state.sweepstake.sweepstakeEnded.sweep_winners : [],
  yourPositions: state.sweepstake.sweepstakeEnded.userPositions ? state.sweepstake.sweepstakeEnded.userPositions : [],
  doubeTCoins: state.tournament.doubleCoins ? state.tournament.doubleCoins : false
});
const mapDispatchToProps = (dispatch) => ({
  doubleUserCoins: (payload) => {
    dispatch(doubleUserCoins(payload));
  },
  fetchCurrentUserDispatch: () => {
    dispatch(getUserData());
  },
  getHighscoresDispatch: (userId) => {
    dispatch(getHighscores(userId))
  },
  doubleTournamentCoinsDispatch: (bool) => {
    dispatch(doubleTournamentCoins(bool))
  },
  updateUserCoinsDispatch: (payload) => {
    dispatch(updateUserCoins(payload))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(sweepstakeEnded);
